import React, { useState } from "react";
import "./LoginPage.css";
import NewHeader from "../../Components/Header/NewHeader";
import Login from "./Component/Login/Login";
import Signup from "./Component/Signup/Signup";
import DownloadAppScreen from "../../Website/DownloadAppScreen/DownloadAppScreen";

export default function LoginPage() {
  const [isLogin, setIsLogin] = useState(true);
  const [showTogglebtn, setShowTogglebtn] = useState(true);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };
  const registrationImage =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/final+Dog.png";

  return (
    <div>
      <NewHeader />
      <div className="hidden md:block">
        <section className="loginbox ">
          <>
            <img
              className="w-[15vw] object-cover"
              src={registrationImage}
              alt="registration-pet"
            />
          </>
          <div className="whitebox ">
            <div>
              {showTogglebtn && (
                <div className="togglebtn-bg">
                  <div className="togglebtn-outer-div">
                    {/* <div className="togglebtn"> */}
                    <div
                      className={`slider ${
                        isLogin ? "slider-login" : "slider-signup"
                      }`}
                    ></div>
                    <button
                      className={
                        isLogin ? "togglebtn-active" : "togglebtn-non-active"
                      }
                      onClick={handleToggle}
                    >
                      Login
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className={
                        !isLogin ? "togglebtn-active" : "togglebtn-non-active"
                      }
                      onClick={handleToggle}
                    >
                      New User
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              )}

              <div>
                {isLogin ? (
                  <div>
                    <Login setShowTogglebtn={setShowTogglebtn} />
                  </div>
                ) : (
                  <div>
                    <Signup setShowTogglebtn={setShowTogglebtn} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="block md:hidden">
        <DownloadAppScreen />
      </div>
    </div>
  );
}
