import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "../Overview/PetFamily/InvitePetFamily.css";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";

export const PetLicensesModal = ({ isOpen, isClose }) => {
  const [isModalClosing, setIsModalClosing] = useState(false);
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      // setIsModalClosing((prev) => !prev);
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <div
      className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
        <div className="modal-close" onClick={handleClose}>
          <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
        </div>
        <div
          className="modal-description"
          style={{
            margin: "auto",
          }}
        >
          <p
            style={{
              fontSize: "clamp(1rem, 1.5vw, 3.5rem)",
              fontWeight: "500",
            }}
          >
            Coming Soon...
          </p>
        </div>
      </div>
    </div>
  );
};
