import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";
import axios from "./axios";
import UserRoute from "./Routes/UserRoute";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  axios.defaults.withCredentials = true;
  console.log("App is running");
  return (
    <>
      <Router>
        <Routes>
          <Route element={<UserRoute />} path={"/*"} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
