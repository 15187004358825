import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import HomePage from "../Pages/Homepage/HomePage";
import Store from "../Pages/Store/ProductStore";
import PetProfile from "../Pages/Profile/Petprofile/Petprofile";
import Nfc from "../Pages/Nfc/Nfc";
import LoginPage from "../Pages/LoginPage/LoginPage";
import axios from "../axios";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Article } from "../Pages/Article/Article";
import { AcceptInvite } from "../Components/AcceptInvite/AcceptInvite";
import Shop from "../shop/Shop";
import Demoshop from "../shop/Demoshop";
import LandingPage from "../../src/Website/LandingPage/LandingPage";
import { SearchComponent } from "../Website/components/SearchComponent/SearchComponent";
import PrivacyPolicy from "../Website/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../Website/RefundPolicy/RefundPolicy";
import TermsCondition from "../Website/TermsCondition/Termscondition";
import DeleteAcc from "../Website/DeleteAcc/DeleteAcc";
import NotFound from "../Website/Notfound/NotFound";

function UserRoute() {
  const signupToken = Cookies.get("signupToken");
  const loginToken = Cookies.get("loginToken");
  const [inviteList, setInviteList] = useState([]);
  const { login, refresh } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginToken) {
      axios
        .get("/auth/verifyToken", {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((response) => {
          console.log("LOGIN", response.data);
          setInviteList(response.data.message.whoinvitesmelist);
          if (response.data.status == "200") {
            dispatch({
              type: "login",
              payload: { login: true, details: response.data.message },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get("/auth/verifyToken", {
          headers: {
            Authorization: signupToken,
          },
        })
        .then((response) => {
          dispatch({
            type: "login",
            payload: { login: null, details: response.data.message.userInfo },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [refresh, dispatch]);
  console.log(inviteList, "inviteList");
  return (
    <>
      <Routes>
        {!login?.login && (
          <>
            <Route element={<LandingPage />} path="/" />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<Shop />} path="/store" />
            <Route path="/profile" element={<Navigate to={"/login"} />} />
            <Route path="/nfc" element={<Nfc />} />
            <Route path="/article/:id" element={<Article />} />
            <Route element={<Demoshop />} path="/demoshop" />
            <Route element={<SearchComponent />} path="/search" />
            <Route path="/article" element={<HomePage />} />
            <Route path="/home" element={<Navigate to={"/login"} />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/refundpolicy" element={<RefundPolicy />} />
            <Route path="/terms&condition" element={<TermsCondition />} />
            <Route path="/deleteaccount" element={<DeleteAcc />} />
            <Route path={"*"} element={<NotFound />} />
          </>
        )}
        {login?.login && inviteList.length === 0 && (
          <>
            <Route element={<HomePage />} path="/article" />
            <Route path="/login" element={<Navigate to={"/home"} />} />
            <Route path="/article/:id" element={<Article />} />
            <Route element={<Shop />} path="/store" />
            <Route element={<LandingPage />} path="/" />
            <Route element={<PetProfile />} path="/home" />
            <Route element={<Nfc />} path="/nfc" />
            <Route element={<Demoshop />} path="/demoshop" />
            <Route element={<SearchComponent />} path="/search" />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/refundpolicy" element={<RefundPolicy />} />
            <Route path="/terms&condition" element={<TermsCondition />} />
            <Route path="/deleteaccount" element={<DeleteAcc />} />
            <Route path={"*"} element={<NotFound />} />
          </>
        )}
        {login?.login && inviteList.length !== 0 && (
          <>
            <Route element={<AcceptInvite />} path="/accept-invite" />
            <Route element={<Navigate to={"/accept-invite"} />} path={"/*"} />
            <Route path="/login" element={<Navigate to={"/accept-invite"} />} />
            <Route
              path="/article/:id"
              element={<Navigate to={"/accept-invite"} />}
            />
            <Route element={<Navigate to={"/accept-invite"} />} path="/store" />
            <Route
              element={<Navigate to={"/accept-invite"} />}
              path="/profile"
            />
            <Route element={<Navigate to={"/accept-invite"} />} path="/nfc" />
          </>
        )}
      </Routes>
    </>
  );
}

export default UserRoute;
