import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./Store/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BookmarkProvider } from "./context/BookmarkContext";
import ShopContextProvider from "./shop/components/ShopContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ShopContextProvider>
      <BookmarkProvider>
        <Provider store={Store}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <App />
          </GoogleOAuthProvider>
        </Provider>
      </BookmarkProvider>
    </ShopContextProvider>
  </React.StrictMode>
);
