import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./NotFound.css";

const image404 =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp ";

export default function NotFound() {
  return (
    <div>
      <div className="notfound-sec">
        <div className="notfound-sec1">
          <h1>
            OOPS! PAGE <br /> NOT FOUND
          </h1>
          <p>
            {" "}
            You must have picked the wrong door . Can’t able to lay my eye on
            the page you’ve been searching for.
          </p>
          <Link to="/">
            {" "}
            <button>
              <FontAwesomeIcon icon={faHouse} />
              &ensp; Back to Home
            </button>
          </Link>
        </div>
        <div className="notfound-sec2">
          <img src={image404} alt="peteye 404 page" title=" " />
        </div>
      </div>
      <div className="notfound-mobile">
        <div className="notfound-sec1">
          <h1>
            OOPS! PAGE <br /> NOT FOUND
          </h1>
          <img src={image404} alt="peteye 404 page" title=" " />
          <p>
            {" "}
            You must have picked the wrong door . Can’t able to lay my eye on
            the page you’ve been searching for.
          </p>
          <Link to="/">
            {" "}
            <button>
              <FontAwesomeIcon icon={faHouse} />
              &ensp; Back to Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
