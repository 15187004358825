import "../Homepage/HomePageModal/HomePageModal.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./ConnectPetModal.css";
import { useState } from "react";
import { OtpInput } from "./OtpInput";
import DogPettingIcon from "../Homepage/DogPettingIcon";
export const ConnectPetModal = ({
  isClose,
  header,
  selectPetModal,
  setqrCode,
}) => {
  const [otp, setOtp] = useState("");
  const [otpStatus, setOtpStatus] = useState("looking"); // empty, success, error
  const [otpMessage, setOtpMessage] = useState("Looking for code");
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      handleClose();
    }
  };

  const handleOtpChange = (value) => {
    setOtp(`P${value}`);
  };

  setqrCode(otp);

  console.log(`Entered OTP is: ${otp}`);
  const placeholders = ["P", "E", "T", "1", "1", "2", "2"]; // Array of placeholders
  return (
    <>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          {header && (
            <div className={`modal-header ${otpStatus}`}>
              <p>{otpMessage}</p>
            </div>
          )}
          <div className="modal-background-connect">
            <img
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/nfc/pettag.png"
              alt=""
              className={`${otpStatus}`}
            />
            <div
              className="modal-close"
              onClick={handleClose}
              style={{ marginTop: "-20px" }}
            >
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="modal-description">
            <div className="connect-pet-modal-div">
              <div className={`input-box ${otpStatus}`}>
                <OtpInput
                  length={7}
                  onChange={handleOtpChange}
                  placeholders={placeholders}
                  updateOtpStatus={setOtpStatus}
                  otpMessage={setOtpMessage}
                  selectPetModal={selectPetModal}
                />
              </div>
              <div className="connect-pet-modal-box">
                <h3 className="connect-pet-modal-heading">Enter Unique Code</h3>
                <span className="connect-pet-modal-description">
                  Check out the 7-digit secret code hidden on the back of your
                  device!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
