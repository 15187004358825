import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./PetImage.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { LuUpload } from "react-icons/lu";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { IoDocumentText } from "react-icons/io5";
import { IoMdImages } from "react-icons/io";

import { AiFillFilePdf } from "react-icons/ai";
import Success from "../../../Success/Success";

export default function PetImage({ isOpen, onClose, petId }) {
  const [uploadedFiles, setUploadedFiles] = useState([null, null, null, null]);
  const [isClosing, setIsClosing] = useState(false);
  const [viewingImage, setViewingImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(null);
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;

  // console.log(uploadedFiles, "uploadedFiles");

  const placeholderTexts = [
    "Front View",
    "Back View",
    "Left View",
    "Right View",
  ];

  useEffect(() => {
    if (petInfo && petInfo.petImages) {
      console.log("Initial petInfo:", petInfo.petImages);
      setUploadedFiles([
        petInfo.petImages.petFrontImage?.url || null,
        petInfo.petImages.petBackImage?.url || null,
        petInfo.petImages.petLeftSideImage?.url || null,
        petInfo.petImages.petRightSideImage?.url || null,
      ]);
    }
  }, [petInfo]);

  useEffect(() => {
    const fetchPetImageData = async () => {
      try {
        const response = await axios.get(`/petinfo/getPetImage/${petId.id}`, {
          headers: {
            Authorization: loginToken,
          },
        });
        console.log("Full API Response:", response.data);

        // Parse the message if it's a string
        const message =
          typeof response.data.message === "string"
            ? JSON.parse(response.data.message)
            : response.data.message;

        console.log("Message Object:", message);

        // Extract image data, parsing each if it's a string
        const petFrontImage =
          typeof message.petFrontImage === "string"
            ? JSON.parse(message.petFrontImage)
            : message.petFrontImage;
        const petBackImage =
          typeof message.petBackImage === "string"
            ? JSON.parse(message.petBackImage)
            : message.petBackImage;
        const petLeftSideImage =
          typeof message.petLeftSideImage === "string"
            ? JSON.parse(message.petLeftSideImage)
            : message.petLeftSideImage;
        const petRightSideImage =
          typeof message.petRightSideImage === "string"
            ? JSON.parse(message.petRightSideImage)
            : message.petRightSideImage;

        console.log("Front Image Data:", petFrontImage);
        console.log("Back Image Data:", petBackImage);
        console.log("Left Side Image Data:", petLeftSideImage);
        console.log("Right Side Image Data:", petRightSideImage);

        console.log(
          "Front Image URL:",
          petFrontImage ? petFrontImage.url : "No Front Image URL"
        );
        console.log(
          "Back Image URL:",
          petBackImage ? petBackImage.url : "No Back Image URL"
        );
        console.log(
          "Left Side Image URL:",
          petLeftSideImage ? petLeftSideImage.url : "No Left Side Image URL"
        );
        console.log(
          "Right Side Image URL:",
          petRightSideImage ? petRightSideImage.url : "No Right Side Image URL"
        );

        const newFiles = [
          petFrontImage?.url || null,
          petBackImage?.url || null,
          petLeftSideImage?.url || null,
          petRightSideImage?.url || null,
        ];

        console.log("New Files Array:", newFiles);
        setUploadedFiles(newFiles);
      } catch (error) {
        console.error(
          "Error fetching pet image data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchPetImageData();
  }, [isOpen, petId, loginToken]);

  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      return response.data.Message
        ? response.data.Message.getPeteye.replace(/"/g, "")
        : "";
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const updatePetImage = async (updatedFiles) => {
    console.log("Updated files:", updatedFiles);
    try {
      const response = await axios.post(
        `/petinfo/updatePetImage/${petId.id}`,
        {
          petFrontImage: {
            url: updatedFiles[0],
            type: "image/png",
            date: new Date().toISOString(),
          },
          petBackImage: {
            url: updatedFiles[1],
            type: "image/png",
            date: new Date().toISOString(),
          },
          petLeftImage: {
            url: updatedFiles[2],
            type: "image/png",
            date: new Date().toISOString(),
          },
          petRightImage: {
            url: updatedFiles[3],
            type: "image/png",
            date: new Date().toISOString(),
          },
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log("Pet image updated:", response.data);
    } catch (error) {
      console.error("Error updating pet image:", error);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000);
  };

  if (!isOpen) return null;

  const handleContainerClick = (index) => {
    if (uploadedFiles[index]) {
      setViewingImage(index);
    } else {
      handleFileSelect(index);
    }
  };

  const handleFileSelect = (index) => {
    setCurrentUploadIndex(index);
    setIsUploadModalOpen(true);
  };
  const handleUploadModalClose = () => {
    setIsUploadModalOpen(false);
    setUploadStatus("");
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      setSelectedFile(file);
    } else {
      alert("File size exceeds 3MB");
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      const url = await uploadFileToS3(selectedFile);
      setIsUploading(false);

      if (url) {
        const newFiles = [...uploadedFiles];
        newFiles[currentUploadIndex] = url;
        setUploadedFiles(newFiles);
        await updatePetImage(newFiles);
        setUploadStatus("success");
      } else {
        setUploadStatus("failed");
      }
    }

    setTimeout(() => {
      setUploadStatus("");
      setIsUploadModalOpen(false);
    }, 3000);
  };

  const handleViewModalClose = () => {
    setViewingImage(null);
  };

  const handleUpdateImage = () => {
    handleFileSelect(viewingImage);
    setViewingImage(null);
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="petimage-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="petimage-title">
            <DogPettingIcon name={"camera"} />
            <h2>Pet Image</h2>
          </div>
          <div className="petimage-input-group">
            {uploadedFiles.map((file, index) => (
              <div
                key={index}
                className="petimage-uploaded-file-container"
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => handleContainerClick(index)}
              >
                {file ? (
                  <img
                    src={file}
                    alt={`Uploaded ${index}`}
                    className="uploaded-image-preview"
                  />
                ) : (
                  <div className="petimage-upload-placeholder">
                    {isUploading ? (
                      <p>Uploading...</p>
                    ) : (
                      <>
                        <LuUpload
                          style={{ fontSize: "clamp(1.3rem, 1.3vw, 3rem)" }}
                        />
                        &ensp;
                        <p>{placeholderTexts[index]}</p>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {viewingImage !== null && uploadedFiles[viewingImage] && (
        <div className="modal-overlay" onClick={handleViewModalClose}>
          <div className="modal-container">
            <div className="image-view-modal">
              <div className="modal-close" onClick={handleViewModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              <img
                src={uploadedFiles[viewingImage]}
                alt={`Viewing ${viewingImage}`}
                className="full-size-image-preview"
              />
              <button className="petimage-button" onClick={handleUpdateImage}>
                <LuUpload style={{ fontSize: "clamp(1.3rem, 1.3vw, 3rem)" }} />{" "}
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      {isUploadModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-container">
            <div className="add-new-documents">
              <div className="modal-close" onClick={handleUploadModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              {uploadStatus === "success" ? (
                <Success
                  message="Upload successfully!"
                  header="Success"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                />
              ) : // <div className="uploaded-success">
              //   <img
              //     src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
              //     alt="Success"
              //     title="Success"
              //   />
              //   <p>Upload successfully!</p>
              // </div>
              uploadStatus === "failed" ? (
                <Success
                  message="Upload failed."
                  header="Failed"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                />
              ) : (
                // <div className="uploaded-failed">
                //   <img
                //     src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                //     alt="Failed"
                //     title="Failed"
                //   />
                //   <p>Oops! Upload failed.</p>
                // </div>
                <div className="add-new-documents-content">
                  <div className="records-Upload-title">
                    <p>
                      <IoDocumentText
                        style={{ fontSize: "clamp(1.3rem, 1.3vw, 4rem)" }}
                      />
                    </p>
                    <h2>Upload Image</h2>
                  </div>
                  <div className="modal-close" onClick={handleUploadModalClose}>
                    <DogPettingIcon
                      name={"closemodal"}
                      className="modal-close-icon"
                    />
                  </div>

                  <div className="upload-doc-files">
                    <label htmlFor="fileInputUpload">
                      <p>
                        <DogPettingIcon name="upload-doc" />
                      </p>
                      <div className="upload-doc-files-title">
                        <h5>
                          <LuUpload /> <h6>Upload</h6>
                        </h5>
                        <p>Maximum Size: 3MB</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInputUpload"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  {selectedFile && (
                    <div className="uploaded-preview-list">
                      <div className="uploaded-file-preview">
                        <div className="uploaded-file-preview-title">
                          <h4>
                            <IoMdImages
                              style={{ fontSize: "clamp(2rem , 1.5vw, 4rem" }}
                            />
                          </h4>
                          <div className="uploaded-file-preview-name">
                            <h5
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              {selectedFile.name}
                            </h5>
                            <p>
                              {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedFile && (
                    <div className="upload-file-btn" onClick={handleFileUpload}>
                      <p>
                        <LuUpload
                          style={{ fontSize: "clamp(1.3rem, 1.3vw, 3rem)" }}
                        />
                      </p>
                      <p>Upload</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
