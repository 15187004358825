import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import { Switch } from "@mui/material";
import axios from "../../../../axios";
import Cookies from "js-cookie";

export const Notification = ({ isOpen, isClose }) => {
  const [appNotification, setAppNotification] = useState(false);
  const [whatsappNotification, setWhatsappNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);

  const loginToken = Cookies.get("loginToken");

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  const fetchNotificationSettings = async () => {
    try {
      const response = await axios.get("/user/notificationlist", {
        headers: {
          Authorization: loginToken,
        },
      });

      if (response.data.status === 200) {
        const settings = response.data.message;
        setAppNotification(settings.App_Notification === "Yes");
        setWhatsappNotification(settings.Whatspp_Notification === "Yes");
        setEmailNotification(settings.Email_Notification === "Yes");
        setSmsNotification(settings.Sms_Notification === "Yes");
      } else {
        console.error("Failed to fetch notification settings");
      }
    } catch (error) {
      console.error("Error fetching notification settings:", error);
    }
  };

  const handleNotification = async () => {
    try {
      const response = await axios.post(
        "/user/appnotification",
        {
          App_Notification: appNotification ? "Yes" : "No",
          Whatspp_Notification: whatsappNotification ? "Yes" : "No",
          Email_Notification: emailNotification ? "Yes" : "No",
          Sms_Notification: smsNotification ? "Yes" : "No",
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      if (response.data.status === 200) {
        console.log(response.data.message); // Notification updated
        isClose();
      } else {
        console.error("Failed to update notifications");
      }
    } catch (error) {
      console.error("Error updating notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <div
      className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"privacy"} /> Notification
            </p>
          </div>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="setting-modal-description">
          <div className="privacy-settings-lists">
            <div className="privacy-settings-list">
              <p>App Notification</p>
              <span>
                <Switch
                  checked={appNotification}
                  onChange={(e) => setAppNotification(e.target.checked)}
                />
              </span>
            </div>
            <div className="privacy-settings-list">
              <p>WhatsApp Notification</p>
              <span>
                <Switch
                  checked={whatsappNotification}
                  onChange={(e) => setWhatsappNotification(e.target.checked)}
                />
              </span>
            </div>
            <div className="privacy-settings-list">
              <p>Email Notification</p>
              <span>
                <Switch
                  checked={emailNotification}
                  onChange={(e) => setEmailNotification(e.target.checked)}
                />
              </span>
            </div>
            <div className="privacy-settings-list">
              <p>SMS Notification</p>
              <span>
                <Switch
                  checked={smsNotification}
                  onChange={(e) => setSmsNotification(e.target.checked)}
                />
              </span>
            </div>
          </div>
          <div className="modal-control">
            <button onClick={handleNotification}>
              <DogPettingIcon name={"accept"} />
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
