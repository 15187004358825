import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import { LogoutConfirmation } from "./LogoutConfirmation";
import { DeleteConfirmation } from "./DeleteConfirmation";

export const LogoutModal = ({ isOpen, isClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleLogoutModal = () => {
    setLogoutConfirmation((prev) => !prev);
  };

  const handleDeleteModal = () => {
    setDeleteConfirmation((prev) => !prev);
  };

  if (!isOpen) return null;
  //
  return (
    <>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"owner-profile"} /> Logout
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="logout-modal">
              <div className="logout-container">
                <div className="logout-container-about">
                  <h3>Logout from this device </h3>
                  <p>
                    Logging out will end your current session, and you will need
                    to enter your credentials again the next time you log in. If
                    you didn't mean to log out, you can cancel this action.
                    Otherwise, please confirm to proceed with logging out.
                  </p>
                </div>
                <div className="logout-container-control">
                  <button className="logout-btn" onClick={handleLogoutModal}>
                    <DogPettingIcon name={"setting-logout"} />
                    Logout
                  </button>
                </div>
              </div>

              {/* <div className="delete-account-container">
                <div className="delete-account-about">
                  <h3>Delete Account</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. 
                  </p>
                </div>
                <div className="delete-account-control">
                  <button className="delete-btn" onClick={handleDeleteModal}>
                    <DogPettingIcon name={"delete-btn"} />
                    Delete Account
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {logoutConfirmation && (
        <LogoutConfirmation
          isClose={handleLogoutModal}
          isOpen={logoutConfirmation}
        />
      )}

      {deleteConfirmation && (
        <DeleteConfirmation
          isClose={handleDeleteModal}
          isOpen={deleteConfirmation}
        />
      )}
    </>
  );
};
