import Icons from "../../../context/Icons";
import searchDog from "../../../assets/search-dog.png";
import generateRandomID from "quickidgen";
import { Link } from "react-router-dom";
import "./SearchComponent.css"; // Adding an external CSS file for animations
import { useEffect, useRef, useState } from "react";
import Toast from "../../../Components/Toast/Toast";

const ROUTES = [
  {
    id: generateRandomID({
      forWeb: true,
      prefix: "PetEye-",
      suffix: "-SearchId",
      useSpecialChars: false,
    }),
    name: "Registration",
    path: "/",
    icon: <Icons name={"search-registration"} />,
  },
  {
    id: generateRandomID({
      forWeb: true,
      prefix: "PetEye-",
      suffix: "-SearchId",
      useSpecialChars: false,
      isReadable: true,
    }),
    name: "Shop",
    path: "/",
    icon: <Icons name={"search-shop"} />,
  },
  {
    id: generateRandomID({
      forWeb: true,
      prefix: "PetEye-",
      suffix: "-SearchId",
      useSpecialChars: false,
    }),
    name: "Customer Care",
    path: "/",
    icon: <Icons name={"search-customer"} />,
  },
  {
    id: generateRandomID({
      forWeb: true,
      prefix: "PetEye-",
      suffix: "-SearchId",
      useSpecialChars: false,
    }),
    name: "About Us",
    path: "/",
    icon: <Icons name={"search-aboutus"} />,
  },
  {
    id: generateRandomID({
      forWeb: true,
      prefix: "PetEye-",
      suffix: "-SearchId",
      useSpecialChars: false,
    }),
    name: "Our Services",
    path: "/",
    icon: <Icons name={"search-services"} />,
  },
];

export const SearchComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isToastVisible, setIsToastVisible] = useState(false);
  const recognitionInstanceRef = useRef(null); // Reference to recognition instance
  const speechTimeoutRef = useRef(null); // Reference to the timeout for speech inactivity

  const startListening = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("SpeechRecognition API not supported by this browser.");
      return;
    }

    const recognition = new SpeechRecognition();
    recognitionInstanceRef.current = recognition;

    recognition.lang = "en-UK"; // Set language

    recognition.onstart = () => {
      setIsListening(true);
      console.log("Started listening");
    };

    recognition.onend = () => {
      setIsListening(false);
      console.log("Stopped listening. You can try again.");
      setToastMessage(
        "Listening has stopped. Click the microphone to start again."
      );
      setIsToastVisible(true);
    };

    recognition.onresult = (event) => {
      // Clear the speech timeout when user speaks again
      if (speechTimeoutRef.current) {
        clearTimeout(speechTimeoutRef.current);
        speechTimeoutRef.current = null;
      }

      const transcript = event.results[0][0].transcript;
      console.log("Transcript:", transcript);
      setSearchQuery(transcript);
      handleSearch(transcript);
    };

    recognition.onspeechend = () => {
      console.log("User stopped speaking, waiting 3s before ending session...");

      // Start a 3-second timeout after speech ends
      const timeout = setTimeout(() => {
        stopListening();
      }, 3000);

      speechTimeoutRef.current = timeout; // Store the timeout reference
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      let message = "";

      if (event.error === "no-speech") {
        message = "No speech detected. Please try speaking again.";
      } else {
        message = `Speech Recognition error: ${event.error}`;
      }

      // Show the toast message for errors
      setToastMessage(message);
      setIsToastVisible(true);
    };

    recognition.start();
  };

  const stopListening = () => {
    const recognition = recognitionInstanceRef.current;

    if (recognition) {
      recognition.stop();
      setIsListening(false);

      // Clear the speech timeout if stopListening is called directly
      if (speechTimeoutRef.current) {
        clearTimeout(speechTimeoutRef.current);
        speechTimeoutRef.current = null;
      }
    }
  };

  // Auto-hide toast after 3 seconds
  useEffect(() => {
    if (isToastVisible) {
      const timer = setTimeout(() => {
        setIsToastVisible(false);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup
    }
  }, [isToastVisible]);

  const handleSearch = (query) => {
    // Logic to trigger the search based on the input value
    console.log("Searching for:", query);
    // You can navigate to a search page or trigger a search function here
  };

  const handleCloseToast = () => {
    setIsToastVisible(false);
    setToastMessage("");
  };
  return (
    <div className="modal-overlay">
      <section className=" rounded-xl w-[98vw] bg-white  m-4 flex flex-col items-center justify-between min-h-screen p-4">
        <div className="search-top flex justify-between items-start self-start w-full">
          <p className="cursor-pointer">
            <Icons name={"search-customer-care"} />
          </p>
          <Link to="/">
            <p className="cursor-pointer">
              <Icons name={"search-close"} />
            </p>
          </Link>
        </div>

        {/* Image Section with fade-in animation */}

        {/* Input box and links container with slide-in animation */}
        <div className="search-bottom flex  items-center justify-center flex-col relative gap-[1vw]">
          <div className="search-center flex flex-col gap-3 items-center justify-center  text-center mb-[1vw]">
            <img
              src={searchDog}
              alt="PEARL AI Image"
              className="w-[40vw] sm:w-[15vw]  fade-in-image object-contain"
            />
            <p className="typing-text-1 text-lg">Hello I'm PEARL!!</p>
            <p className="typing-text-2 text-sm sm:text-lg">
              How May I assist you? <br /> Please type in the box
            </p>
          </div>
          <div className="flex flex-col items-center slide-up-input gap-[1.2vw]">
            <div className="search_input relative ">
              <input
                type="text"
                className={`px-[1vw] w-[80vw] sm:w-[70vw] py-[1vw]  rounded-lg border border-[#eaeaea] focus:outline-none focus:ring-2 focus:ring-[#cdcdcd] focus:border-[#6E6E6E] focus:shadow-lg transition-all duration-300 ease-in-out  placeholder:text-gray-300 focus:placeholder:text-gray-400 placeholder:text-[clamp(2rem, 2vw, 4rem)] text-[clamp(4rem, 2vw, 4rem)]`}
                placeholder={
                  isListening
                    ? "I'm listening..."
                    : "Hello I'm PEARL, How may I assist you?"
                }
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <span
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={isListening ? stopListening : startListening}
              >
                <Icons name="search-microphone" active={isListening} />
              </span>
            </div>

            {/* Animated links coming with input */}
            <div className="suggestions items-center flex gap-2 sm:gap-4">
              {ROUTES.map((route) => {
                return (
                  <Link
                    to={route.path}
                    key={route.id}
                    id={route.id}
                    className="flex px-[4vw] sm:px-[1vw] py-[.5vw] bg-white rounded-md text-md gap-3 hover:bg-gray-100 transition-colors duration-300 active:scale-90 shadow-lg  tracking-tighter"
                  >
                    {route.icon} <span>{route.name}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        <div className="search-footer flex items-center justify-center">
          <p className="text-[#6E6E6E] w-full sm:w-[50vw] text-center text-[clamp(1rem,1.2vw,6rem)]">
            See our privacy statement to find out how we collect and use your
            data, to contact us with privacy questions, or to exercise your
            personal data rights.
          </p>
        </div>

        {isToastVisible && (
          <Toast message={toastMessage} onClose={handleCloseToast} />
        )}
      </section>
    </div>
  );
};
