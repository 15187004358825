import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DataManagementCard } from "./DataManagementCard";
export const DataManagementCarousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + items.length) % items.length
    );
  };

  return (
    <div className="relative w-full md:block flex flex-col justify-center items-center">
      <div
        className="overflow-hidden 
      //  md:-mt-[10vh] xl:-mt-0
       "
      >
        <div
          className="flex transition-transform duration-300 ease-in-out "
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {items.map((item) => (
            <div key={item.id} className="w-full flex-shrink-0">
              <DataManagementCard detail={item} />
            </div>
          ))}
        </div>
      </div>
      <div className="absolute left-[0vw] right-0 xl:left-[30vw] xl:right-auto md:-bottom-10   flex items-center justify-between md:justify-around w-[90vw] md:w-[70vw] m-auto ">
        <button
          onClick={prevSlide}
          className="text-black px-3 py-1 rounded-full flex items-center text-lg"
        >
          <ChevronLeft className="md:w-[3vw] md:h-[3vh] mr-1" />
        </button>
        <div className="hidden md:flex space-x-2 ">
          {items.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-[1vw] h-[1vh] rounded-full ${
                index === currentIndex ? "bg-black" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
        <button
          onClick={nextSlide}
          className="text-black px-3 py-1 rounded-full flex items-center text-lg"
        >
          <ChevronRight className="md:w-[3vw] md:h-[3vh] ml-1 text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default DataManagementCarousel;
