import React, { useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import "./ProductActions.css";
import Checkout from "./Checkout/Checkout";

export default function Prescription({ isClose, isOpen }) {
  const [isClosing, setIsClosing] = useState(false);
  const [checkedOption, setCheckedOption] = useState(null); // Track which option is selected
  const [checkoutOpen, setCheckoutOpen] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };
  const warningImg =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/Warning.png";

  const handleClose = () => {
    isClose();
    // setIsClosing(true);
    // setTimeout(() => {
    //   setIsClosing(false);
    //   isClose();
    // }, 1000);
  };
  const handleCheckout = () => {
    setCheckoutOpen((prev) => !prev);
  };

  return (
    <div>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"prescription-title"} /> Prescription
              </p>
            </div>

            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="px-[1.5vw] flex flex-col items-center gap-[1vw]">
            <div className="flex flex-col items-center justify-center ">
              <img
                src={warningImg}
                alt="warning"
                className="warning-img w-[12vw]"
              />
              <p className="text-[clamp(1.2rem,1.2vw,3.5rem)] text-[#DC574B] font-bold">
                Prescription
              </p>
            </div>
            <div className=" flex flex-col items-center gap-[1.5vw]">
              <p className="text-[clamp(.8rem,1vw,3rem)] text-center font-[Manrope]">
                For prescription medications, we kindly request that you upload
                a veterinarian-approved prescription at checkout. However,
                please note that uploading the prescription is not mandatory. By
                providing this information, our team can ensure the proper care
                and service for your pet, and your data will be securely
                protected in accordance with our privacy policy
              </p>
              <div className="flex justify-center items-center gap-[1.5vw]">
                <button
                  className="bg-[#fff] text-[#000] px-[1.5vw] py-[.5vw] w-[12vw] h-[7vh] rounded-[1vw] text-[clamp(.7rem,.7vw,3rem)] font-[Manrope] shadow-[0px_.2vw_.9vw_0px_rgba(0,0,0,0.25)] border-none outline-none font-bold"
                  onClick={handleClose}
                >
                  Go Back to Cart
                </button>
                <button
                  className="bg-[#000] text-[#fff] px-[1.5vw] py-[.5vw] h-[7vh]  w-[12vw] rounded-[1vw] text-[clamp(.7rem,.7vw,3rem)] font-[Manrope] shadow-[0px_.2vw_.9vw_0px_rgba(0,0,0,0.25)] border-none outline-none font-bold"
                  onClick={handleCheckout}
                >
                  Continue without Prescription
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {checkoutOpen && (
        <Checkout isClose={handleCheckout} isOpen={handleCheckout} />
      )}
    </div>
  );
}
