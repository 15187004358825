import React, { useState, useRef, useEffect } from "react";
import "./Weight.css";
import Cookies from "js-cookie";
import axios from "../../../../../axios";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { useDispatch } from "react-redux";
import Success from "../../../Success/Success";

export default function Weight({ isOpen, onClose, petId, initialWeight }) {
  const loginToken = Cookies.get("loginToken");
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("Kg");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [weight, setWeight] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const selectorRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen && initialWeight) {
      const weightNumber = parseInt(initialWeight.match(/\d+/)[0], 10);
      setWeight(weightNumber);
      setActiveIndex(weightNumber - 1);
      if (selectorRef.current) {
        const itemWidth = 100;
        selectorRef.current.scrollLeft = (weightNumber - 1) * itemWidth;
      }
    }
  }, [isOpen, initialWeight]);

  // Add effect to automatically close success message
  useEffect(() => {
    let timeoutId;
    if (showConfirmation) {
      // Auto close after 2 seconds
      timeoutId = setTimeout(() => {
        handleCloseConfirmation();
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showConfirmation]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setShowConfirmation(false);
      onClose();
    }, 1000); // Reduced animation time for better UX
  };

  const handleCloseConfirmation = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowConfirmation(false);
      setIsClosing(false);
      onClose();
    }, 300); // Reduced animation time for better UX
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleScroll = () => {
    if (selectorRef.current) {
      const container = selectorRef.current;
      const scrollLeft = container.scrollLeft + container.offsetWidth / 2;
      const itemWidth = 100;
      const currentIndex = Math.floor(scrollLeft / itemWidth);
      setActiveIndex(currentIndex);
      setWeight(1 + currentIndex);
    }
  };

  const handleClick = (index) => {
    setActiveIndex(index);
    setWeight(1 + index);
  };

  const saveWeight = async () => {
    const formattedWeight = `${weight} ${activeTab.toLowerCase()}`;

    try {
      const response = await axios.post(
        "/petinfo/updateWeight",
        {
          petId: petId.id,
          petWeight: formattedWeight,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      if (response.status === 200) {
        setShowConfirmation(true);
        dispatch({ type: "refresh" });
      } else {
        console.error("Error updating weight:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating weight:", error);
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="weight-picker">
          {!showConfirmation ? (
            <>
              <div className="weight-picker-title">
                <DogPettingIcon name={"weight"} />
                <h2>Weight</h2>
                <div className="modal-close" onClick={handleClose}>
                  <DogPettingIcon
                    name={"closemodal"}
                    className="modal-close-icon"
                  />
                </div>
              </div>
              <div className="units-kg">
                <div
                  className={`pet-family-toggle weight-toggle ${
                    activeTab === "Kg"
                      ? "family-tab-active"
                      : "community-tab-active"
                  }`}
                >
                  <button
                    className={activeTab === "Kg" ? "active" : ""}
                    onClick={() => handleTabClick("Kg")}
                  >
                    Kg
                  </button>
                  <button
                    className={activeTab === "Lbs" ? "active" : ""}
                    onClick={() => handleTabClick("Lbs")}
                  >
                    Lbs
                  </button>
                </div>
                <div
                  className="weight-selector"
                  onScroll={handleScroll}
                  ref={selectorRef}
                >
                  {Array.from({ length: 201 }, (_, i) => (
                    <div
                      key={i}
                      className={`weight-indicator ${
                        activeIndex === i ? "active" : ""
                      }`}
                      onClick={() => handleClick(i)}
                    >
                      {1 + i} {activeTab === "Kg" ? "Kg" : "Lbs"}
                    </div>
                  ))}
                </div>
              </div>
              <button className="save-button" onClick={saveWeight}>
                <DogPettingIcon name={"save"} />
                <p>Save</p>
              </button>
            </>
          ) : (
            <Success
              onClose={handleCloseConfirmation}
              gif={
                "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
              }
              message={"Successfully Saved"}
            />
          )}
        </div>
      </div>
    </div>
  );
}
