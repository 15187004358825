import { createStore } from "redux";

const initialState = {
  socialData: {},
  userInfo: {},
  petInfo: {},
  petProof: {},
  // medRecord:{},
  login: { login: null },
  cart_details: {},
  paravetdata: {},
  refresh: true,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "socialData":
      return { ...state, socialData: action.payload };
    case "userInfo":
      return { ...state, userInfo: action.payload };
    case "petInfo":
      return { ...state, petInfo: action.payload };
    case "petProof":
      return { ...state, petProof: action.payload };
    case "medRecord":
      return { ...state, medRecord: action.payload };
    case "login":
      return { ...state, login: action.payload };
    case "cart_details":
      return { ...state, cart_details: action.payload };
    case "refresh":
      return { ...state, refresh: !state.refresh };
    case "paravetdata":
      return { ...state, paravetdata: action.payload };

    default:
      return state;
  }
}

export default createStore(reducer);
