import React, { useState } from "react";
import "./ConfirmVerification.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import VerificationOTP from "../VerificationOTP/VerificationOTP";
import { useSelector } from "react-redux";
import AlertBox from "../../../../Components/Alert/Alert";
import axios from "../../../../axios";
import Cookies from "js-cookie";

function ConfirmVerification() {
  const { login } = useSelector((state) => state);
  console.log(login?.details);
  const [openVerifnOTP, setopenVerifnOTP] = useState(false);
  const [value, setValue] = useState("");

  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const signupToken = Cookies.get("signupToken");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleSubmit = (e) => {
    console.log(value, "value");
    e.preventDefault();
    if (value.trim() == "") {
      setopenAlert(!openAlert);
      setErrMessage("choose one");
      return;
    }
    if (value.includes("@")) {
      console.log(value);
      axios
        .get("/auth/sendOtpEmail", {
          headers: {
            Authorization: signupToken,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "200") {
            setopenVerifnOTP(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get("/auth/sendOtpMobile", {
          headers: {
            Authorization: signupToken,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == "200") {
            setopenVerifnOTP(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {openVerifnOTP ? (
        <VerificationOTP status={"confirmVf"} value={value} />
      ) : (
        <>
          <div className="confm-vf-div">
            <h3>Verification Details</h3>
            <p>
              To help keep your account safe, We wants to make sure it’s really
              you trying to sign in
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="confm-vf-radio-div">
              <FormControl className="confm-form-cl">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  className="confm-form-cl-radio"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    className="confm-form-clform"
                    value={login?.details?.userInfo?.mobile}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "clamp(1.5rem, 1.5vw, 4rem)", // Adjust size of the radio button
                          },
                        }}
                      />
                    }
                    label={login?.details?.userInfo?.mobile}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "clamp(1rem, 1.2vw, 4rem)", // Adjust font size of the label
                      },
                    }}
                  />
                  <FormControlLabel
                    className="confm-form-clform"
                    value={login?.details?.userInfo?.email}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "clamp(1.5rem, 1.5vw, 4rem)", // Adjust size of the radio button
                          },
                        }}
                      />
                    }
                    label={login?.details?.userInfo?.email}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "clamp(1rem, 1.2vw, 4rem)", // Adjust font size of the label
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="confm-code-btn">
              <button type="submit">Get Code</button>
            </div>
          </form>
        </>
      )}
      {openAlert ? (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ConfirmVerification;
