import React, { useState } from "react";
import Modal from "../Modal/Modal";

export default function TrackOrder({ isOpen, isClose }) {
  const [isClosing, setIsClosing] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Reduced animation time for better UX
  };
  return (
    <div>
      <Modal
        modaltitle={"Track Order"}
        handleClose={handleClose}
        handleOverlayClick={handleOverlayClick}
        isClosing={isClosing}
        iconname={"weight"}
      >
        {" "}
      </Modal>
    </div>
  );
}
