import React, { useState, useEffect } from "react";
import "./Gallery.css";
import {
  AiFillPlusCircle,
  AiOutlineCloseCircle,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { CiSaveDown1 } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoShareSocialOutline } from "react-icons/io5";
import {
  FaLinkedin,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { IoMdImages } from "react-icons/io";

import { FaXTwitter } from "react-icons/fa6";

import { IoDocumentText } from "react-icons/io5";
import { LuUpload } from "react-icons/lu";
import { AiFillFilePdf } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import Delete from "../../../../Components/Delete/Delete";
import Failedgif from "../../../Failed/Failedgif";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import Success from "../../Success/Success";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isSmallClosing, setIsSmallClosing] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [showShareArea, setShowShareArea] = useState(false);
  const [isShareAreaClosing, setIsShareAreaClosing] = useState(false);
  const [isClosing1, setIsClosing1] = useState(false);

  const loginToken = Cookies.get("loginToken");

  const handleUploadClick = () => {
    setIsUploadModalOpen(true);
  };
  const uploadImageToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      console.log("S3 Response:", response.data);
      if (response.status == 200) {
        return response.data.Message?.getPeteye;
      }
    } catch (error) {
      console.error("Error uploading image to S3:", {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
        headers: error.response?.headers,
      });
      return null;
    }
  };

  const uploadImagesToGallery = async (imageUrls) => {
    try {
      console.log("Uploading URLs to gallery:", imageUrls);

      // Ensure imageUrls is an array
      if (!Array.isArray(imageUrls) || imageUrls.length === 0) {
        throw new Error("Image URLs must be a non-empty array");
      }
      const response = await axios.post(
        "/petinfo/addgallery",
        {
          urls: imageUrls,
        },
        {
          headers: {
            Authorization: loginToken,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Gallery Update Response:", response.data);
      // Handle successful response if needed
    } catch (error) {
      console.error(
        "Error uploading images to gallery:",
        error.response ? error.response.data : error.message
      );
      // Handle error if needed
    }
  };

  const handleFileClick = (file) => {
    const url = URL.createObjectURL(file);
    window.open(url, "_blank");
  };

  const handleFileEdit = () => {
    setUploadedFile(null);
    document.getElementById("fileInputUpload").click();
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);

    const convertToWebP = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            canvas.toBlob(
              (blob) => {
                resolve({
                  blob,
                  originalFile: file,
                });
              },
              "image/webp",
              1
            );
          };
        };
        reader.onerror = (error) => reject(error);
      });

    const convertedImages = await Promise.all(
      files.map(async (file) => {
        const { blob } = await convertToWebP(file);
        const url = URL.createObjectURL(blob);
        return {
          id: galleryData.length + 1,
          img: url,
          originalFile: file,
          name: file.name,
          size: file.size,
        };
      })
    );

    setSelectedImages(convertedImages);
  };

  const openModal = (index) => {
    if (index >= 0 && index < galleryData.length) {
      setCurrentIndex(index);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setIsShareDialogOpen(false);
      setIsClosing(false);
    }, 1000); // Match the duration of the fadeOut animation
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryData.length);
  };

  const showPrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryData.length - 1 : prevIndex - 1
    );
  };

  const handleSave = () => {
    const link = document.createElement("a");
    link.href = galleryData[currentIndex].img;
    link.download = `image-${currentIndex + 1}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const confirmDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  useEffect(() => {
    async function fetchGalleryList() {
      try {
        const response = await axios.get("/petinfo/getListGallery", {
          headers: {
            Authorization: loginToken,
            "Content-Type": "application/json",
          },
        });
        console.log("Response received:", response);
        // Ensure we handle response data correctly
        if (response.data && Array.isArray(response.data.message)) {
          console.log("Gallery data received:", response.data.message);
          setGalleryData(response.data.message);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching gallery images:", error);
      }
    }

    // Fetch gallery list when the component mounts
    fetchGalleryList();
  }, [loginToken, refresh]);

  const handleUpload = async () => {
    if (selectedImages.length > 0) {
      try {
        // Upload each image to S3 and get the URLs
        const uploadedUrls = await Promise.all(
          selectedImages.map(async (image) => {
            const file = image.originalFile;
            const url = await uploadImageToS3(file);
            console.log("url", url);
            return url;
          })
        );

        // Save URLs to the user's gallery
        await uploadImagesToGallery(uploadedUrls);

        setGalleryData([
          ...galleryData,
          ...uploadedUrls.map((url) => ({ img: url })),
        ]);

        // Update upload status
        setUploadStatus("success");
        setrefresh(!refresh);
        setSelectedImages([]);
      } catch (error) {
        console.error("Error uploading images:", error);
        setUploadStatus("failed");
      }
    } else {
      setUploadStatus("failed");
    }
  };

  const handleDelete = async () => {
    try {
      const imageId = galleryData[currentIndex]?.id;
      const imageUrl = galleryData[currentIndex]?.url;

      if (!imageId || !imageUrl) {
        console.error("No valid image data for deletion");
        return;
      }

      // Call the delete API
      const response = await axios.delete(`/petinfo/deleteGallery/${imageId}`, {
        headers: {
          Authorization: loginToken,
          "Content-Type": "application/json",
        },
      });

      console.log("Delete Response:", response.data);

      if (response.status === 200) {
        // Successfully deleted, now remove from gallery data
        setGalleryData((prevGalleryData) => {
          // Create new gallery data without the current index
          const newGalleryData = prevGalleryData.filter(
            (_, index) => index !== currentIndex
          );

          // Update currentIndex to ensure it is within bounds
          if (currentIndex >= newGalleryData.length) {
            setCurrentIndex(newGalleryData.length - 1);
          }

          // If the gallery becomes empty, close the modal
          if (newGalleryData.length === 0) {
            closeModal();
          }

          return newGalleryData;
        });

        setIsDeleteDialogOpen(false);
      } else {
        console.error("Failed to delete image from gallery");
      }
    } catch (error) {
      console.error(
        "Error deleting image from gallery:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const rejectDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleShare = () => {
    setIsShareDialogOpen(true);
    setShowShareArea(true);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsUploadModalOpen(false);
      closeModal();
      setShowShareArea(false);
    }
  };

  const handleUploadModalClose = () => {
    setIsSmallClosing(true);
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setIsSmallClosing(false);
      setIsUploadModalOpen(false);
      setSelectedImages([]); // Clear selected images when modal is closed
      setUploadStatus(null);
    }, 1000);
  };
  const handleCloseShareArea = () => {
    setIsShareAreaClosing(true);
    setTimeout(() => {
      setShowShareArea(false);
      setIsShareAreaClosing(false);
      setIsDeleteDialogOpen(false);
      setIsShareDialogOpen(false);
      setIsModalOpen(true);
    }, 300);
  };

  const getShareUrl = (platform) => {
    const imageUrl = encodeURIComponent(galleryData[currentIndex].url);
    const text = encodeURIComponent(
      "Check out this image from my pet gallery!"
    );
    const title = encodeURIComponent("My Pet Gallery Image");

    switch (platform) {
      case "twitter":
        return `https://twitter.com/intent/tweet?url=${imageUrl}&text=${text}`;
      case "linkedin":
        return `https://www.linkedin.com/sharing/share-offsite/?url=${imageUrl}&title=${title}&summary=${text}`;
      case "whatsapp":
        return `https://api.whatsapp.com/send?text=${text}%20${imageUrl}`;
      case "facebook":
        return `https://www.facebook.com/sharer/sharer.php?u=${imageUrl}&quote=${text}`;
      case "instagram":
        // Instagram doesn't have a direct sharing API, so we'll return the image URL
        return galleryData[currentIndex].url;
      default:
        return null;
    }
  };
  const handleShareimage = (platform) => {
    const url = getShareUrl(platform);
    if (url) {
      if (platform === "instagram") {
        // For Instagram, copy the image URL to clipboard
        navigator.clipboard
          .writeText(url)
          .then(() => alert("Instagram doesn't support direct sharing"))
          .catch((err) => console.error("Failed to copy: ", err));
      } else {
        // For other platforms, open in a new window
        window.open(url, "_blank", "noopener,noreferrer");
      }
    }
  };

  useEffect(() => {
    let timer;
    if (uploadStatus) {
      timer = setTimeout(() => {
        setUploadStatus(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [uploadStatus]);

  return (
    <div className="profile-gallery-sec">
      <div className="grid-container-gallery">
        {galleryData.length === 0 ? (
          <span
            style={{
              margin: " auto",
            }}
          >
            {" "}
            <Failedgif message="No images found" />
          </span>
        ) : (
          galleryData.map((item, index) => (
            <div
              key={item.id}
              className="grid-item1"
              onClick={() => openModal(index)}
            >
              <img src={item.url} alt="gallery item" />
            </div>
          ))
        )}
        <div className="gallery-upload-btn" onClick={handleUploadClick}>
          <AiFillPlusCircle className="gallery-upload-icon" />
        </div>
      </div>
      <input
        id="fileInputUpload"
        type="file"
        style={{ display: "none" }}
        multiple
        accept="image/*"
        onChange={handleFileChange}
      />
      {isModalOpen && galleryData[currentIndex] && (
        <div
          className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className={`modal-container ${isClosing ? "close" : ""}`}>
            <div className="gallery-modal-close" onClick={closeModal}>
              <DogPettingIcon
                name={"closemodal"}
                className="gallery-modal-close-icon"
              />
            </div>
            <div className="modal-content-gallery">
              <img src={galleryData[currentIndex].url} alt="modal item" />
              {!isShareDialogOpen && !isDeleteDialogOpen && (
                <div className="modal-controls">
                  <button onClick={showPrevImage}>&lt;</button>
                  <div className="modal-actions">
                    <p>
                      <CiSaveDown1
                        onClick={handleSave}
                        className="modal-action-icon"
                      />
                    </p>
                    <p>
                      <RiDeleteBin5Line
                        onClick={confirmDelete}
                        className="modal-action-icon"
                      />
                    </p>
                    <p>
                      <IoShareSocialOutline
                        onClick={handleShare}
                        className="modal-action-icon"
                      />
                    </p>
                  </div>
                  <button onClick={showNextImage}>&gt;</button>
                </div>
              )}
              {showShareArea && (
                <div
                  className={`share-area-gallery ${
                    isShareAreaClosing ? "closing" : ""
                  }`}
                >
                  <div className="share-area-gallery-content">
                    <AiOutlineCloseCircle
                      className="close-share-area-gallery-icon"
                      onClick={handleCloseShareArea}
                    />
                    <FaXTwitter
                      className="social-icon-gallery"
                      onClick={() => handleShareimage("twitter")}
                    />
                    <FaLinkedin
                      className="social-icon-gallery"
                      onClick={() => handleShareimage("linkedin")}
                    />
                    <FaWhatsapp
                      className="social-icon-gallery"
                      onClick={() => handleShareimage("whatsapp")}
                    />
                    <FaFacebook
                      className="social-icon-gallery"
                      onClick={() => handleShareimage("facebook")}
                    />
                    <FaInstagram
                      className="social-icon-gallery"
                      onClick={() => handleShareimage("instagram")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isUploadModalOpen && (
        <div
          className={`modal-overlay ${isClosing1 ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className={`modal-container ${isClosing1 ? "close" : ""}`}>
            <div className="add-new-documents">
              <div className="modal-close" onClick={handleUploadModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>
              {uploadStatus === "success" ? (
                <Success
                  message="Upload successfully!"
                  header="Success"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                  onClose={() => {
                    setUploadStatus(null);
                    handleUploadModalClose();
                  }}
                />
              ) : uploadStatus === "failed" ? (
                <Success
                  message="Oops! No files found."
                  header="Failed"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                  onClose={() => {
                    setUploadStatus(null);
                    handleUploadModalClose();
                  }}
                />
              ) : (
                <div className="add-new-documents-content">
                  <div className="records-Upload-title">
                    <p>
                      <IoDocumentText
                        style={{
                          fontSize: "clamp(1.5rem, 1.5vw, 4.5rem",
                        }}
                      />
                    </p>
                    <h2>Upload Documents</h2>
                  </div>
                  <div className="modal-close" onClick={handleUploadModalClose}>
                    <DogPettingIcon
                      name={"closemodal"}
                      className="modal-close-icon"
                    />
                  </div>
                  <div className="upload-doc-files" onClick={handleFileEdit}>
                    <label htmlFor="fileInputUpload">
                      <p>
                        <DogPettingIcon name="upload-doc" />
                      </p>
                      <div className="upload-doc-files-title">
                        <h5>
                          <LuUpload /> <h6>Upload</h6>
                        </h5>
                        <p>Maximum Size: 3MB</p>
                      </div>
                    </label>
                  </div>
                  {selectedImages.length > 0 && (
                    <div className="uploaded-preview-list">
                      {selectedImages.map((image) => (
                        <div key={image.id} className="uploaded-file-preview">
                          <div className="uploaded-file-preview-title">
                            <h4>
                              <IoMdImages
                                style={{ fontSize: "clamp(2rem , 1.5vw, 4rem" }}
                              />
                            </h4>
                            <div className="uploaded-file-preview-name">
                              <h5
                                onClick={() => handleFileClick(image)}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                                {image.name}
                              </h5>
                              <p>{(image.size / 1024 / 1024).toFixed(2)} MB</p>
                            </div>
                          </div>
                          <div
                            className="uploaded-file-preview-edit"
                            onClick={() => handleFileEdit()}
                            style={{ cursor: "pointer" }}
                          >
                            <DogPettingIcon name="edit" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedImages.length > 0 && (
                    <div className="upload-file-btn" onClick={handleUpload}>
                      <p>
                        <LuUpload
                          style={{
                            fontSize: "clamp(1.3rem, 1.5vw,4rem)",
                          }}
                        />
                      </p>
                      <p>Upload</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isDeleteDialogOpen && (
        <Delete handleDelete={handleDelete} rejectDelete={rejectDelete} />
      )}
    </div>
  );
};

export default Gallery;
