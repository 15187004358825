import React, { useEffect, useState } from 'react';
import './Cart.css';
import { FaIndianRupeeSign, FaMinus, FaPlus } from 'react-icons/fa6';
import { RiCloseCircleFill } from 'react-icons/ri';
import Checkout from './Checkout/Checkout';
import axios from '../../../../axios';
import Cookies from 'js-cookie';
import AlertBox from '../../../../Components/Alert/Alert';
import OrderSuccess from './OrderSuccess/OrderSuccess';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import AOS from 'aos';
import Auth from '../Auth/Auth';
const loginToken = Cookies.get('loginToken');
const cartEmpty = 'https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/cartempty.gif';

function Cart({ backButton, setbackButton, setcartStatus, cartStatus }) {
    useEffect(() => {
    AOS.init({ duration: 500, delay: 50 });
  }, [])


  const [orders, setorders] = useState([]);
  const [openCheckout, setopenCheckout] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [subtotal, setsubtotal] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [GST, setGST] = useState(0);
  const [shipping, setshipping] = useState(0);
  const [total, setTotal] = useState(0);
  const [addressId, setAddressId] = useState('');
  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState('');
  const [openOrderSuccess, setopenOrderSuccess] = useState(false);
  const [loading, setLoading] = useState(true); 
  const [openAuth, setopenAuth] =useState(false);


  //FETCHING CART DATA'S FROM BACKEND

  useEffect(() => {
    if(!loginToken){
      setopenAuth(true)
    }
    if (backButton == true) {
      setopenCheckout(false);
      setbackButton(false);
    }
    axios.get('/shop/gettocart', {
      headers: {
        Authorization: loginToken,
      },
    })
    .then((response) => {
      console.log(response.data);
      if (response.data.status == '200') {
        setorders(response.data.message);
        setCartItems(response.data.message);
        // Subtotal Calculation
        const pdtTotal = response?.data?.message?.reduce((acc, currentItem) => {
          return acc + parseInt(currentItem.amount);
        }, 0);
        setsubtotal(pdtTotal);
        const grandTotal = parseInt(pdtTotal) + parseFloat(discount) + parseInt(GST) + parseInt(shipping);
        setTotal(grandTotal);
      }
      setTimeout(() => {
        setLoading(false); // Data is loaded
      }, 1000);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false); // Data loading failed
    });
  }, [refresh, backButton]);

  // QUANTITY INCREMENT

  const quantityIncrement = (id) => {
    const updatedCart = cartItems.map((item) =>
      item.id === id
        ? { ...item, quantity: String(parseInt(item.quantity) + 1) }
        : item
    );
    setCartItems(updatedCart);
    const newSubtotal = updatedCart.reduce((acc, currentItem) => {
      return acc + parseInt(currentItem.amount) * parseInt(currentItem.quantity);
    }, 0);
    setsubtotal(newSubtotal);
    const grandTotal = parseInt(newSubtotal) + parseFloat(discount) + parseInt(GST) + parseInt(shipping);
    setTotal(grandTotal);
  };

  // QUANTITY DECREMENT

  const quantityDecrement = (id) => {
    const updatedCart = cartItems.map((item) =>
      item.id === id && parseInt(item.quantity) > 1
        ? { ...item, quantity: String(parseInt(item.quantity) - 1) }
        : item
    );
    setCartItems(updatedCart);
    const newSubtotal = updatedCart.reduce((acc, currentItem) => {
      return acc + parseInt(currentItem.amount) * parseInt(currentItem.quantity);
    }, 0);
    setsubtotal(newSubtotal);
    const grandTotal = parseInt(newSubtotal) + parseFloat(discount) + parseInt(GST) + parseInt(shipping);
    setTotal(grandTotal);
  };


  // DELETE CART ITEM

  const deleteCartItem = (Id) => {
    axios.put(`/shop/deletecart/${Id}`, null, {
      headers: {
        Authorization: loginToken,
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status == '200') {
        setcartStatus(!cartStatus);
        setrefresh(!refresh);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  // FORM SUBMISSION

  const handleSubmit = (e) => {
    e.preventDefault();
    setopenCheckout(true);
  };


  // LOADING RAZORPAY SCRIPT
  useEffect(() => {
    loadRazorpayScript();
  }, []);

  const loadRazorpayScript = () => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = handleRazorpayScriptLoad;
    document.body.appendChild(script);
  };

  const handleRazorpayScriptLoad = () => {
  };

  // ORDER CREATION FOR PAYMENT

  const handlePayment = () => {
    if (addressId) {
      axios.post('/razerpay/ordercreate', { amount: total }, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((response) => {
        console.log(response.data.message.getresponse.id);
        if (response?.data?.status == '200') {
          const orderId = response.data?.message?.getresponse.id;
          const key = response.data?.message?.rzp_key;
          initiateRazorpayPayment(orderId, key);
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      setopenAlert(!openAlert);
      setErrMessage('Choose address for shipment');
    }
  };

  // INITIALIZATION OF RAZORPAY

  const initiateRazorpayPayment = (orderId, key) => {
    const options = {
      key: key,
      amount: total * 100,
      currency: 'INR',
      name: 'petEYE',
      description: 'Online Transaction',
      image: 'https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/image+16407.png',
      order_id: orderId,
      handler: async (response) => {
        try {
          await verifyPayment(response, orderId);
        } catch (error) {
          console.error(error);
          setopenAlert(!openAlert);
          setErrMessage('Payment verification failed');
        }
      },
      prefill: {
        name: addressId?.firstname,
        email: '',
        contact: addressId?.mobilenumber,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#262427',
      },
    };

    if (window.Razorpay) {
      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      console.error('Razorpay script is not loaded');
    }
  };

  // PAYMENT VERIFICATION

  const verifyPayment = async (payment) => {
    try {
      const response = await axios.post('/razerpay/verifyorder', { razorpay_order_id: payment?.razorpay_order_id, razorpay_payment_id: payment?.razorpay_payment_id }, {
        headers: {
          Authorization: loginToken,
          razorpaysignature: payment?.razorpay_signature,
        },
      });
      console.log(response);
      if (response.data.success == '200') {
        purchaseAdd(payment);
      } else {
        setopenAlert(!openAlert);
        setErrMessage('Payment failed');
      }
    } catch (error) {
      console.error(error);
      setopenAlert(!openAlert);
      setErrMessage('Payment verification failed');
    }
  };

  // ADDING VERIFIED ORDER TO DATABASE

  const purchaseAdd = (payment) => {
    let tax_amount = 0;
    let over_all_amount = 0;
    let over_all_item = 0;
    let total_amount = 0;
    let array = [], i = 0;
    cartItems.map((t) => {
      let amount_for_tax = 0;
      total_amount += parseInt(t.amount) * parseInt(t.quantity);
      amount_for_tax = parseInt(t.amount) * parseInt(t.quantity);
      tax_amount += (parseInt(amount_for_tax) * parseInt(t.Products.Tax)) / 100;
      over_all_amount = parseInt(total_amount) + parseInt(tax_amount);
      over_all_item += parseInt(t.quantity);
      array.push({
        productid: cartItems[i].Products.id,
        Productamount: cartItems[i].amount,
        quantity: cartItems[i].quantity,
        Tax: cartItems[i].Products.Tax,
        overAllAmount: parseInt(cartItems[i].quantity) * parseInt(cartItems[i].amount),
      });
      i++;
    });

    let formData = {
      Productamount: total_amount,
      Tax: tax_amount,
      quantity: over_all_item,
      productinfo: array,
      overAllAmount: total,
      razorpay_order_id: payment.razorpay_order_id,
      Signature: payment.razorpay_signature,
      razorpay_id: payment.razorpay_payment_id,
      ShippingAddress: addressId.firstname + ' ' + addressId.area + ' ' + addressId.city + ' ' + addressId.state + ' ' + addressId.pincode + ' ' + addressId.mobilenumber,
    };
    axios.post("/shop/purchaseadd", { ...formData }, {
      headers: {
        Authorization: loginToken,
      },
    }).then((response) => {
      if (response.data.status == '200') {
        setopenOrderSuccess(true);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  // RENDERING FOR LOADING SKELETON

 //CART PRODUCTS SECTION

  const renderCartItems = () => (
    cartItems.map((item, index) => (
      <div key={item.id} style={{ display: 'flex', }}>
        <div className='cart'>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span>{index + 1}</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={item?.Products?.productImagemodel?.[0]?.url} alt="" />
          </div>
          <div className='cart-item-section'>
            <span>{item.Products.Category}</span>
            <p>{item?.Products?.ProductName.split(" ").slice(0, 3).join(" ")} ...</p>
            {item.ProductCategory == 'devices' ? "" :
              <p style={{ fontSize: 'x-small' }}>{item?.unit} {item?.Products?.Category == 'medicine' ? 'ml' : 'KG'}</p>
            }
          </div>
          <div className='qty'>
            <FaMinus onClick={() => quantityDecrement(item.id)} style={{ fontSize: '13px', cursor: 'pointer' }} />
            <input className='qty-input' value={item?.quantity} type="text" readOnly />
            <FaPlus onClick={() => quantityIncrement(item.id)} style={{ fontSize: '13px', cursor: 'pointer' }} />
          </div>
          <div className='cart-total'>
            <span>Total</span>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
              <FaIndianRupeeSign style={{ fontSize: '12px' }} />
              <p>{item?.amount}</p>
            </div>
          </div>
          <div>
            <RiCloseCircleFill onClick={() => deleteCartItem(item.id)} style={{ fontSize: '25px', cursor: 'pointer' }} />
          </div>
        </div>
      </div>
    ))
  );

  // ORDER SUMMARY SECTION

  const renderOrderSummary=()=>{
    return(
      <div className='total'>
                          <p style={{ fontWeight: '600', fontSize: '15px' }}>Order Summary</p>
                          {
                            openCheckout ? '' :
                              <input type="text" placeholder='Apply Coupon' />
                          }
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ margin: '0' }}>Subtotal</p>
                            <p style={{ margin: '0' }}><FaIndianRupeeSign style={{ paddingTop: '5px' }} />{subtotal}</p>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ margin: '0' }}>Discount</p>
                            <p style={{ margin: '0' }}><FaIndianRupeeSign style={{ paddingTop: '5px' }} />{discount}</p>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ margin: '0' }}>GST</p>
                            <p style={{ margin: '0' }}><FaIndianRupeeSign style={{ paddingTop: '5px' }} />{GST}</p>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ margin: '0' }}>Shipping</p>
                            <p style={{ margin: '0' }}><FaIndianRupeeSign style={{ paddingTop: '5px' }} />{shipping}</p>
                          </div>
                          <hr style={{ width: '100%', borderColor: '#d9d9d952' }} />
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ margin: '0' }}>Total</p>
                            <p style={{ margin: '0' }}><FaIndianRupeeSign style={{ paddingTop: '5px' }} />{total}</p>
                          </div>
                        </div>
    )
  }

  // PAYMENT BUTTON

  const renderButton=()=>{
    return(
      <>
      {
            openCheckout ?
            <button onClick={() => handlePayment()}>Pay Now</button>
            :
           <button type='submit'>Checkout</button>
         }
      </>
    )
  }

  // SKELETONS 

  const renderLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={650} height={80} />
    </div>
  );
  const renderSummaryLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={250} height={300} />
    </div>
  );
  const renderButtonLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={250} height={50} />
    </div>
  );
  return (
    <>
      {
        openOrderSuccess ?
          <OrderSuccess /> :
          <>
            <form onSubmit={handleSubmit}>
              <div className='cart-main'>
                {
                  cartItems?.length != 0 ?
                    <>
                      <div className='cart-sub1'>
                        {
                          openCheckout ?
                            <Checkout cartItems={cartItems} setAddressId={setAddressId} />
                            :
                            <>
                              {loading ?
                              cartItems.map(()=>{
                                return(
                                  renderLoadingSkeleton() 
                                  )
                                  
                              })
                               : renderCartItems()}
                            </>
                        }
                      </div>

                      {/* ORDER SUMMARY  */}

                      <div className='cart-sub2'>
                      <>
                              {loading ?
                               renderSummaryLoadingSkeleton()
                               : renderOrderSummary()}
                            </>
                        <div className='cart-btn'>
                        {loading ?
                               renderButtonLoadingSkeleton()
                               : renderButton()}
                        </div>
                      </div>
                    </>
                    :
                    // CART EMPTY
                    <div className='empty-cart'>
                      <img src={cartEmpty} alt="" />
                      <p>Oops, your cart is empty.</p>
                    </div>
                }
              </div>
            </form>
             {/* ERROR ALERT */}

            {
              openAlert ? <AlertBox open={true} setOpen={setOpen} setopenAlert={setopenAlert} errMessage={errMessage} /> : ''
            }
          </>
      }
         {
      open ?
      <Auth open={openAuth} handleClose={setopenAuth} /> 
      :''
    }
    </>
  );
}

export default Cart;
