import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
 

import Axios from 'axios';
import axios from '../../../../../axios';
import { useState } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import { Autocomplete, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect } from 'react';
import SimpleBackdrop from '../../../../../Components/Backdrop/Backdrop';
import { ModalDialog } from '@mui/joy';
import { AddressValidation } from './AddressValidation';
import AlertBox from '../../../../../Components/Alert/Alert';
import Cookies from 'js-cookie';
import { RiCloseCircleFill } from 'react-icons/ri';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:2 ,
  p: 4,
  zIndex: -1,
  gap:0
};
const loginToken = Cookies.get('loginToken');

export default function AddAddress({open,handleClose,editData,update}) {
  console.log(update,editData)
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState({Name :editData?.country ||  '' }) 
  const [openAlert, setopenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [formData, setFormData] = useState({
        firstName: editData?.firstname || '',
        Mobilenumber:editData?.mobilenumber || '',
        lastName:editData?.lastname ||'',
        SecondaryMobilenumber:editData?.secondaryMobileNumber || '',
        State:editData?.state|| '',
        City:editData?.city || '',
        Pincode:editData?.pincode ||'',
        District:editData?.district|| '',
        HouseFloorNo:editData?.area || '',
      });
 const resetForm=()=>{
    

 }

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));

  if (name === 'Pincode' && value.length === 6 && nationality?.Name=='India' ) {
    fetchPostalCode(value);
    setopenBackdrop(true);
  }

};
useEffect(() => {
  fetchCountries();
}, []);

const fetchCountries = () => {
  axios
    .get('/user/country')
    .then((response) => {
      const countriesData = response.data.message;
      // Find the index of India in the countries data
      const indiaIndex = countriesData.findIndex(country => country.Name === 'India');
      // If India is found, move it to the beginning of the array
      if (indiaIndex !== -1) {
        const updatedCountries = [
          countriesData[indiaIndex],
          ...countriesData.slice(0, indiaIndex),
          ...countriesData.slice(indiaIndex + 1)
        ];
        setCountries(updatedCountries);
      } else {
        setCountries(countriesData); // Set the countries as they are
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const fetchPostalCode = (code) => {
  Axios.get(`https://api.postalpincode.in/pincode/${code}`)
    .then((response) => {
      const postalData = response?.data[0]?.PostOffice[0];
      setFormData((prevData) => ({
        ...prevData,
        City: postalData?.District || '',
        State: postalData?.State || '',
        District: postalData?.District || '',
        Pincode: code,  
      }));
      setopenBackdrop(false);
    })
    .catch((err) => {
      console.log(err);
    });
};
    

    const handleSubmit = (e) => {
        e.preventDefault();
        let Nationality=nationality?.Name
        let userInfo={...formData,Nationality,Id:editData?.id};
        let formValidation=AddressValidation(userInfo)
        if(formValidation==true){
          if(update==true){
            axios.patch('/shop/updateaddress',{...userInfo},{
              headers: {
                Authorization:loginToken 
              }
            }).then((response)=>{
              console.log(response)
              if(response.data.status=='200'){
                handleClose(!open)
              }
            }).catch((error)=>{
              console.log(error)
            })
          }else{
            axios.post('/shop/addnewaddress',{...userInfo},{
              headers: {
                Authorization:loginToken 
              }
            }).then((response)=>{
              console.log(response.data)
              if(response.data.status=='200'){
                handleClose(!open)
              }
            }).catch((error)=>{
              console.log(error)
            })
          }

        }else{
          setopenAlert(!openAlert)
          setErrMessage(formValidation);
          return;
        }
         

      };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
     <form onSubmit={handleSubmit}>
        <Box sx={style}  className='petInfo-modal'>
        <div style={{ display:'flex', width:'100%' , justifyContent:'flex-end' }}><RiCloseCircleFill onClick={()=>handleClose(!open)} style={{ marginTop:'-5px', fontSize:'25px', cursor:'pointer',marginRight:'-10px' }} /></div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Address
          </Typography>
          <div style={{gap:'10px'}} className='reg-body'>
       <div className='reg-body-sub1'>
       <TextField className='textfield-size-reg' id="" type='text' label="First name" variant="outlined"
      InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
      InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }} 
      name="firstName"
      value={formData.firstName}
      onChange={handleInputChange}
      />
       <TextField className='textfield-size-reg' id="" type='text' label="Mobile number" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }}
        name="Mobilenumber"
        value={formData.Mobilenumber}
        onChange={handleInputChange}
        />

       </div>
       <div className='reg-body-sub2'>
       <TextField className='textfield-size-reg' id="" type='text' label="Last name" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }}
        name="lastName"
        value={formData.lastName}
        onChange={handleInputChange}
        />
       <TextField className='textfield-size-reg' id="" type='text' label="2nd Mobile number" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }} 
        name="SecondaryMobilenumber"
        value={formData.SecondaryMobilenumber}
        onChange={handleInputChange}
        />
       </div>
      </div>
          <div style={{gap:'10px'}} className='reg-body-2'>
       <div className='reg-body-sub1'>
      
       <Autocomplete
  id="country-select-demo"
  sx={{ width: 200 }}
  options={countries}
  autoHighlight
  getOptionLabel={(option) => option.Name}
  renderOption={(props, option) => (
    <Box sx={{fontSize:'12px' }} component="li" {...props}>
      {option.Name}
    </Box>
  )}
  value={nationality}
  onChange={(e, newValue) => setNationality(newValue)}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Choose a country"
      name="nationality"
      // value={nationality}
      // onChange={(e) => setNationality(e.target.value)}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
        style: { height: '5px', fontSize: '14px' },
      }}
      InputLabelProps={{ style: { fontSize: '14px', marginTop: '-6px' } }}
    />
  )}
/>

       <TextField className='textfield-size-reg' id="" type='text' label="State" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }}
        name="State"
        value={formData.State}
        onChange={handleInputChange}
        />
       <TextField className='textfield-size-reg' id="" type='text' label="City" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }}
        name="City"
        value={formData.City}
        onChange={handleInputChange}
        />
      

       </div>
       <div className='reg-body-sub2'>
       <TextField className='textfield-size-reg' id="" type='text' label="Pincode" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }}
        name="Pincode"
        value={formData.Pincode}
        onChange={handleInputChange}
        />
       <TextField className='textfield-size-reg' id="" type='text' label="District" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }}
        name="District"
        value={formData.District}
        onChange={handleInputChange}
        />
        <TextField className='textfield-size-reg' id="" type='text' label="House / Floor no" variant="outlined"
        InputProps={{ style: { height: '40px' , fontSize:'14px'  } }}
        InputLabelProps={{ style: { fontSize: '14px',marginTop:'-4px' } }} 
        name="HouseFloorNo"
        value={formData.HouseFloorNo}
        onChange={handleInputChange}
        />

    
       </div>
      </div>


    
      <div className='petInfo-modal-btn'>
     <button type='submit' >Save</button>
      </div>
        </Box>
      </form>
      </Modal>
      {
        openBackdrop ? 
        <>
         <Modal  open={openBackdrop}  >
        <ModalDialog sx={{display:'flex',alignItems:'center', fontFamily:'monospace'}} size={'sm'}>
          {/* <ModalClose /> */}
          <DialogTitle sx={{fontFamily:'monospace'}} >Fetching Data</DialogTitle>
          <CircularProgress variant={'solid'} color="success" />
        </ModalDialog>
      </Modal>
        </>
        :''
      }
       {
        openAlert ?<AlertBox open={openAlert} setOpen={setopenAlert} setopenAlert={setopenAlert} errMessage={errMessage}/> :''
      }
    </div>
  );
}