import React, { useEffect, useState } from "react";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import { GiStopSign } from "react-icons/gi";
import Cookies from "js-cookie";
import axios from "../../axios";
import { useSelector } from "react-redux";

export const Sessions = () => {
  const loginToken = Cookies.get("loginToken");

  const [livePastSessions, setLivePastSessions] = useState(false);
  const [liveSessions, setLiveSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { login } = useSelector((state) => state);

  const fetchSessions = async () => {
    setLoading(true);
    setError("");
    try {
      const [liveResponse, pastResponse] = await Promise.all([
        axios.get("/nfc/activesessions", {
          headers: {
            Authorization: loginToken,
          },
        }),
        axios.get("/nfc/scannedhistory", {
          headers: {
            Authorization: loginToken,
          },
        }),
      ]);
      console.log("liveResponse", liveResponse, "pastResponse", pastResponse);
      setLiveSessions(liveResponse.data.message || []);
      setPastSessions(pastResponse.data.message || []);
    } catch (error) {
      setError("Failed to fetch sessions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, [loginToken]);

  const handleDisconnect = async (qrcode) => {
    try {
      await axios.post(
        "/nfc/disconnectsession",
        { qrcode },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log("successfully disconnected", qrcode);
      fetchSessions();
    } catch (error) {
      setError("Failed to disconnect session. Please try again later.");
    }
  };

  const renderSessions = (sessions, type) => {
    if (loading) {
      return (
        <p
          className="rule-valid"
          style={{
            width: "max-content",
          }}
        >
          Loading {type} sessions...
        </p>
      );
    }

    if (error) {
      return <p className="error-message">{error}</p>;
    }

    if (sessions.length === 0) {
      return (
        <div className="live-past-sessions-box">
          <div className="live-past-sessions-img">
            <img
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+3(4).png"
              alt="Pet"
            />
            <div className="about-pet-nfc">
              <h3> Pearl</h3>
              <p className="live">
                {type === "Live"
                  ? " No Pet for Live Location"
                  : "No Pet for Past Location"}
              </p>
            </div>
          </div>
          <>
            {type === "Live" ? (
              <div className="live-past-sessions-control cursor-pointer">
                <DogPettingIcon
                  name="taglink-liveicon"
                  className="live-past-sessions-icon"
                />
              </div>
            ) : (
              <div className="live-past-sessions-control cursor-pointer">
                <DogPettingIcon
                  name="taglink-pasticon"
                  className="live-past-sessions-icon"
                />
              </div>
            )}
          </>
        </div>
      );
    }

    return sessions.map((session) => (
      <div className="live-past-sessions-box" key={session.id}>
        <div className="live-past-sessions-img">
          <img
            src={
              session.petinfo.petProfileImage ||
              "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+3(4).png"
            }
            alt={session.name || "Pet"}
          />
          <div className="about-pet-nfc">
            <h3>{session.petinfo.petName || "Unknown"}</h3>
            <p className={type === "Live" ? "live" : "live"}>{type} Session</p>
          </div>
        </div>
        <>
          {type === "Live" ? (
            <div
              className="live-past-sessions-control cursor-pointer"
              onClick={() => handleDisconnect(session.qrcode)}
            >
              <DogPettingIcon
                name="taglink-liveicon"
                className="live-past-sessions-icon"
              />
            </div>
          ) : (
            <div className="live-past-sessions-control cursor-pointer">
              <DogPettingIcon
                name="taglink-pasticon"
                className="live-past-sessions-icon"
              />
            </div>
          )}
        </>
      </div>
    ));
  };

  return (
    <div className="live-past-sessions">
      {login?.login && (
        <div
          className={`live-past-sessions-toggle ${
            livePastSessions ? "past-tab-active" : ""
          }`}
        >
          <>
            <button
              className={!livePastSessions ? "active" : ""}
              onClick={() => setLivePastSessions(false)}
            >
              <DogPettingIcon name={"Lost"} active={livePastSessions} />
              Live Session
            </button>
            <button
              className={livePastSessions ? "active" : ""}
              onClick={() => setLivePastSessions(true)}
            >
              <DogPettingIcon name={"Found"} active={!livePastSessions} />
              Past Session
            </button>
          </>
        </div>
      )}

      {login?.login && (
        <div className="live-past-sessions-container">
          {livePastSessions
            ? renderSessions(pastSessions, "Past")
            : renderSessions(liveSessions, "Live")}
        </div>
      )}
    </div>
  );
};
