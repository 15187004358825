import React, { useState } from "react";
import "./Overview.css";
import Companion from "./Companion/Companion";
import Reminder from "./Reminder/Reminder";
import Weight from "./Weight/Weight";
import PetFamily from "./PetFamily/PetFamily";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { AcceptInvite } from "../../../../Components/AcceptInvite/AcceptInvite";

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
};

const Overview = ({ petInfo, login, currentPetId }) => {
  const [isCompanionOpen, setIsCompanionOpen] = useState(false);
  const [isWeightOpen, setIsWeightOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);
  const [isRedVisible, setIsRedVisible] = useState(false);
  const [modalContent, setModalContent] = useState("lost");
  const [isRedVisible2, setIsRedVisible2] = useState(false);
  const [isPetFamilyOpen, setIsPetFamilyOpen] = useState(false);

  console.log("Info:", petInfo);

  const handleOpenWeight = () => {
    setIsWeightOpen(true);
  };
  const handleCloseWeight = () => {
    setIsWeightOpen(false);
  };

  const handleOpenCompanion = () => {
    setIsCompanionOpen(true);
    setIsRedVisible(false);
  };

  const handleOpenReminder = () => {
    setIsReminderOpen(true);
    setIsRedVisible2(false);
  };

  const handleCloseCompanion = () => {
    setIsCompanionOpen(false);
  };

  const handleCloseReminder = () => {
    setIsReminderOpen(false);
  };
  const handleCompanionSubmit = () => {
    setIsRedVisible(true);
    handleCloseCompanion();
    setIsCompanionOpen(false);
    setModalContent("found");
  };

  const handleOpenPetFamily = () => {
    setIsPetFamilyOpen((prev) => !prev);
  };

  // Assuming petInfo has a property called birthDate
  const birthDate = petInfo?.birthDate
    ? formatDate(petInfo.birthDate)
    : "Unknown Date";

  const getWeightNumber = (weight) => {
    // Extract the number from the weight string using a regular expression
    const match = weight.match(/(\d+)\s*(\w+)/);
    return match
      ? { number: match[1], unit: match[2] }
      : { number: "0", unit: "" };
  };
  const weightDetails = getWeightNumber(petInfo?.petWeight);
  console.log("Weight:", weightDetails.number, weightDetails.unit);
  const overviewData = [
    {
      id: 1,
      img: <DogPettingIcon name={"birthday-cake"} />,
      title: "Date of Birth",
      data: birthDate,
    },
    {
      id: 2,
      img: <DogPettingIcon name={"gender"} />,
      title: "Gender",
      data: petInfo?.sex || "Unknown",
    },
    {
      id: 3,
      img: <DogPettingIcon name={"petfam"} />,
      data: "Pet Family",
      // data: "Yes",
    },
    {
      id: 4,
      img: <DogPettingIcon name={"weight-icon"} />,
      title: "Weight",
      data: `${weightDetails.number} ${weightDetails.unit}` || "Unknown",
    },
    {
      id: 5,
      img: <DogPettingIcon name={"companion"} />,
      title: " Report to our wider pet community",
      data: "Lost Companion",
    },
    {
      id: 6,
      img: <DogPettingIcon name={"reminders-icon"} />,
      title: "Set Reminders",
      data: " Reminders",
    },
  ];

  return (
    <div className="grid-container">
      {overviewData.map((item, index) => (
        <div
          key={item.id}
          className="grid-item"
          onClick={
            index === 2
              ? handleOpenPetFamily
              : index === 3
              ? handleOpenWeight
              : index === 4
              ? handleOpenCompanion
              : index === 5
              ? handleOpenReminder
              : null
          }
        >
          {((index === 4 && isRedVisible) ||
            (index === 5 && isRedVisible2)) && (
            <div className="small-red-div"></div>
          )}
          <div className="svgicon">{item.img}</div>
          <h3>{item.data}</h3>
          <p>{item.title}</p>
        </div>
      ))}
      <Weight
        isOpen={isWeightOpen}
        onClose={handleCloseWeight}
        petId={petInfo}
        initialWeight={petInfo?.petWeight}
      />
      <Companion
        isOpen={isCompanionOpen}
        onClose={handleCloseCompanion}
        onSubmit={handleCompanionSubmit}
        content={modalContent}
        setcontent={setModalContent}
        redBubble={setIsRedVisible}
        userInfo={login?.details?.userInfo}
        currentPetId={currentPetId}
        petInfo={petInfo}
      />
      <Reminder
        isOpen={isReminderOpen}
        onClose={handleCloseReminder}
        redBubble2={setIsRedVisible2}
        petId={petInfo}
      />
      <PetFamily
        isOpen={isPetFamilyOpen}
        onClose={handleOpenPetFamily}
        pet={petInfo}
      />
    </div>
  );
};

export default Overview;
