import React, { useState } from 'react'
import '../FoodMedicine/FoodMedicine.css'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { BsCurrencyRupee } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import { useEffect } from 'react';
import axios from '../../../../axios';
import Cookies from 'js-cookie';
import { Modal } from '@mui/material';
import { ModalDialog } from '@mui/joy';
import { IoIosHeart, IoIosHeartEmpty } from 'react-icons/io';
import { FaCartShopping } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Auth from '../Auth/Auth';
const loginToken = Cookies.get('loginToken');


function Devices({setcartStatus,cartStatus,searchResults,Refresh,wlrefresh,setwlrefresh}) {


    const [viewProduct, setviewProduct] = useState('')
    const [reviewLine, setreviewLine] = useState(false)
    const [value, setvalue] = useState('')
    const [openAddtocart, setopenAddtocart] = useState(false)
    const [loading, setLoading] = useState(true); // Loading state
    const [open, setOpen] =useState(false);

    // const [refresh, setrefresh] = useState(false)
    const share='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/share.png'
    const successGif='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/AddtoCart.gif'
  const exclamation='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/AlreadyExist.gif'
    const offerTag='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+34624469.png'
    const dogImg='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/dogCategory.png'

    const category=[{name:'nfc'},{name:'devices'}]
    const [products, setproducts] = useState([])
    const [tokenVerified, settokenVerified] = useState(true);


    let pdt=searchResults;

    useEffect(() => {
      axios.get('/auth/verifyToken',{
        headers: {
          Authorization: loginToken 
        }
      }).then((response)=>{
        // console.log("RESPONSE :",response)
      }).catch((error)=>{
        console.log(error.response.status)
        if(error.response.status == 401){
          settokenVerified(false)
        }
      })
      setTimeout(() => {
        
        setLoading(false); // Data is loaded
      }, 800);
      setproducts(pdt);
      setviewProduct(pdt?.[0]);
  }, [Refresh]);
        
        
  const handleWlProduct = (id, status) => {
    console.log(id,status)
    const data= products.map((item) => {
       if (item.id == id) {
         return { ...item, wlstatus: status };
       }
       return item;
     })
    setproducts(data)
  
 };
    const addtocart=(quantity,ProductId,pdtAmount)=>{
      if(!tokenVerified){
        setOpen(true)
      }
      const amount=((pdtAmount) - (Math.floor((pdtAmount) * ((viewProduct?.OfferPercentage) / 100))) - 2)
      axios.post('/shop/addtocart',{quantity,ProductId,unit:null,amount,ProductCategory:'devices'},{
        headers: {
          Authorization:loginToken 
        }
      }).then((response)=>{
       
        if(response.data.status=='200'){
          setopenAddtocart(true)
          setcartStatus(!cartStatus)
          setvalue('Added to cart')
          setTimeout(() => {
            setopenAddtocart(false)
          }, 2000);
        }
        if(response.data.status=='500'){
          setopenAddtocart(true)
          setvalue('Product already in cart')
          setTimeout(() => {
            setopenAddtocart(false)
          }, 2000);
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
    const addtowishlist=(ProductId)=>{
      if(!tokenVerified){
        setOpen(true)
      }
      axios.post('/shop/addtowishlist',{ProductId,ProductCategory:'devices'},{
        headers: {
          Authorization:loginToken 
        }
      }).then((response)=>{
        console.log(response.data)
        if(response.data.status=='200'){
          // setrefresh(!refresh)
        }
      }).catch((error)=>{
        console.log(error)
      })
       
    }
    const removefromwishlist=(id)=>{
      if(!tokenVerified){
        setOpen(true)
      }
        axios.delete(`/shop/removewishlistpdt/${id}`,{
        headers: {
          Authorization:loginToken 
        }
      }).then((response)=>{
        console.log(response.data)
       
          // setrefresh(!refresh)
        
      }).catch((error)=>{
        console.log(error)
      })
    }


    const renderCategory=()=>{
      return(
        <>
        {
            category.map((item)=>{
              return(
                <div className='category-div'>
                  <img src={dogImg} alt="category" />
                  <p>{item?.name}</p>
                </div>
              )
            })
          }
        </>
      )
    }

    const renderProductCards=()=>{
      return(
           <>
 {
            products?.map((item)=>{
              return(
                <div onClick={()=>setviewProduct(item)}>
                  <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>

                  <div className='offer-tag'>
                    <img src={offerTag} alt="offerTag" />
                    <div className='centered'>{item?.OfferPercentage}%<br/>Off</div>
                  </div>
                  {/* {
                    item?.wlstatus ? <IoIosHeart className="heart-icon" onClick={()=>removefromwishlist(item.id)} style={{color:'#FF0000',fontSize:'25px', cursor:'pointer'}} />
                    :

                  <IoIosHeartEmpty className="heart-icon" onClick={()=>addtowishlist(item.id)} style={{color:'rgb(255 0 0 / 57%)',fontSize:'25px', cursor:'pointer'}} />
                  } */}
                  </div>
                  <img  src={item?.productImagemodel?.[0]?.url} alt="pdtImg" />
                  <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'flex-start',paddingLeft:'5px'}}>
                  <p>{item?.ProductName.split(" ").slice(0, 6).join(" ")} <b>...</b></p>
                 
                   
                  </div>
              
              <div className='addtocart'>
                <div className='view-details'>
                
                <BsCurrencyRupee style={{fontSize:'14px'}} />
                <p style={{color:'black'}}>{((item?.Productamount)-(Math.floor((item?.Productamount) * ((item?.OfferPercentage)/100 )))-2)} </p>
                </div>
                <button onClick={()=>setviewProduct(item)}>View Details</button>
              </div>
                </div>
              )
            })
          }
           </>
      )
    }

    const renderViewProduct=()=>{
      return(
        <>
            <div key={viewProduct?.ProductName}  data-aos="zoom-in" className='food-med-sub2'>
      <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>

<div  className='offer-tag-view-pdt'>
  <img src={offerTag} alt="offerTag" />
  <div className='centered-view-pdt'>{viewProduct?.OfferPercentage}%<br/>Off</div>
</div>
 <img className='share-img' src={share} alt="share" />
</div>
        <div className='viewImg'>
          {/* {
            viewProduct?.productImagemodel?.map((item)=>{
              return(
                <img src={item.url} alt="" />
              )
            })
          } */}
          <img  src={viewProduct?.productImagemodel?.[0]?.url} alt="pdtImg" />

        </div>
        
        <p>{viewProduct?.ProductName}</p>


        <div>
{/*           
          <select className='product-unit' name="unit" id="unit">
    <option className="product-unit-option" value="3">3 kilograms</option>
    <option className="product-unit-option" value="5">5 kilograms</option>
    <option className="product-unit-option" value="10">10 kilograms</option>
  </select> */}
         

        <div style={{ display:'flex', justifyContent:'space-around',marginTop:'-20px' }}>
          <div className='pdt-details'>
            <p style={{paddingTop:'10px'}} onClick={()=>setreviewLine(false)}>Details</p>
          </div>
          {/* <div className='pdt-review'>
            <p onClick={()=>setreviewLine(true)}>Reviews</p>
          </div> */}
        </div>
        <div style={{ display:'flex',justifyContent:'center' }}>
        <div class={`${reviewLine ? 'half-colored-line-review' : 'half-colored-line-details'}`}></div>      
        </div>
        <div className='pdt-description'>
          <p>{viewProduct?.Description}</p>
        </div>
         
        <div className='viewPdt-cart'>
        <div className='view-details'>
          <strike style={{color:'rgb(149 144 144)'}}>
          <div style={{display:'flex',alignItems:'center'}}>
          <BsCurrencyRupee style={{fontSize:'14px'}} />
           <p style={{color:'rgb(149 144 144)'}}> {viewProduct?.Productamount} </p>
            </div>
          </strike>
          &nbsp;
         <div style={{display:'flex',alignItems:'center'}}>
           <BsCurrencyRupee style={{fontSize:'14px'}} />
           <p style={{color:'black'}}>{((viewProduct?.Productamount)-(Math.floor((viewProduct?.Productamount) * ((viewProduct?.OfferPercentage)/100 )))-2)} </p>
         </div>
        </div>
        <div style={{display:'flex',gap:'5px'}}>
        <div className='wishlist'>
        {
                    viewProduct?.wlstatus ? <IoIosHeart className="heart-icon" onClick={()=>{removefromwishlist(viewProduct.id); setviewProduct({ ...viewProduct, wlstatus: false }); 
                    handleWlProduct(viewProduct.id,false)
                  }} style={{color:'#FF0000',fontSize:'25px', cursor:'pointer'}} />
                    :

                  <IoIosHeartEmpty className="heart-icon" onClick={()=>{addtowishlist(viewProduct?.id); setviewProduct({ ...viewProduct, wlstatus: true }); 
                  handleWlProduct(viewProduct.id,true)
                }} style={{color:'rgb(255 0 0 / 57%)',fontSize:'25px', cursor:'pointer'}} />
                  }
          </div>
        <button onClick={()=>addtocart(viewProduct?.quantity,viewProduct?.id,viewProduct?.Productamount)} ><FaCartShopping /><p>Add</p></button>
        </div>

        </div>
        </div>

        </div>
        </>
      )
    }
    const renderCategoryLoadingSkeleton = () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={100} height={30} />
      </div>
    );
    const renderProductLoadingSkeleton = () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton style={{margin:'5px',borderRadius:'15px'}} width={220} height={250} />
      </div>
    );
    const renderViewPdtLoadingSkeleton = () => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Skeleton style={{margin:'5px',borderRadius:'15px'}} width={300} height={450} />
      </div>
    );

  return (
    <>
    {
      products.length!=0 ?
      <>
      {/* PRODUCT CATEGORY */}

<div className='food-med-main'>
      <div className='food-med-sub1' >
        <div className='food-category'   >
        {
  <>
  {loading ?
  category.map(()=>{
    return(
      renderCategoryLoadingSkeleton() 
      )
      
  })
   : renderCategory()}
</>
  }
  </div>
        {/* PRODUCT CARDS */}

        <div className='pdt-card' >
          
        {loading ?
       products.map(()=>{
        return(
         renderProductLoadingSkeleton() 
         )
        })
        : renderProductCards()}
        </div>


      </div>

   
     
      {loading ?
      renderViewPdtLoadingSkeleton() 
      : renderViewProduct()}
        
    </div>
    <Modal  open={openAddtocart}  >
          <ModalDialog sx={{display:'flex' ,flexDirection:'column',alignItems:'center', fontFamily:'monospace',borderRadius:'15px',padding:"35px"}} size={'sm'}>
            {/* <ModalClose /> */}
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', gap:'10px'}}>
            {
              value=='Added to cart' ?
              <img style={{height:'20vh',objectFit:'contain'}} src={successGif} alt="successGif" />
              :
              <img style={{height:'20vh',objectFit:'contain'}} src={exclamation} alt="exclamation" />
            }
            <p style={{textAlign:'center',fontWeight:'600',fontFamily:'Poppins',margin:'0',fontSize:'large'}}>{value}</p>
            </div>
          </ModalDialog>
        </Modal>
      </>
      : 
      <div className='empty-cart'>

          <p>Oops, No product found.</p>
          </div>
    }
       {
      open ?
      <Auth open={open} handleClose={setOpen} /> 
      :''
    }
    </>
  )
}

export default Devices
