import React, { useState } from "react";
import "./Login.css";
import TextField from "@mui/material/TextField";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "../../../../axios";
import AlertBox from "../../../../Components/Alert/Alert";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";

function Login({ setShowTogglebtn }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openForgotPass, setopenForgotPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [googleAuthStatus, setgoogleAuthStatus] = useState(false);

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 6 * 60 * 60 * 1000);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);
  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          window.FB.api(
            "/me",
            { fields: "id,name,email" },
            async (fbResponse) => {
              console.log(fbResponse);
              try {
                let data = {
                  email: fbResponse?.email,
                  password: fbResponse?.id,
                };
                const response = await axios.post("/auth/login", { ...data });
                console.log(response);
                if (response.data.status == "200") {
                  Cookies.set(
                    "loginToken",
                    response.data.message.userInfo.token,
                    { expires: expirationDate }
                  );

                  dispatch({
                    type: "login",
                    login: { ...response.data.message },
                  });
                  dispatch({
                    type: "cart_details",
                    cart_details: response.data.message.cartdetails,
                  });
                  dispatch({ type: "refresh" });
                  window.location.reload();
                }
                if (response.data.status == "404") {
                  setopenAlert(!openAlert);
                  setErrMessage(response.data.message);
                  return;
                }
                if (response.data.status == "401") {
                  setopenAlert(!openAlert);
                  setErrMessage(response.data.message);
                  return;
                }
              } catch (error) {
                console.log(error);
              }
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email,public_profile" }
    );
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await Axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        console.log(res, "google response");
        if (res.status == "200") {
          let data = {
            email: res?.data?.email,
            password: res?.data?.sub,
          };
          axios
            .post("/auth/login", { ...data })
            .then((response) => {
              console.log(response);
              if (response.data.status == "200") {
                Cookies.set(
                  "loginToken",
                  response.data.message.userInfo.token,
                  { expires: expirationDate }
                );

                dispatch({
                  type: "login",
                  login: { ...response.data.message },
                });
                dispatch({
                  type: "cart_details",
                  cart_details: response.data.message.cartdetails,
                });
                dispatch({ type: "refresh" });
                // window.location.reload();
                navigate("/home");
              }
              if (response.data.status == "404") {
                setopenAlert(!openAlert);
                setErrMessage(response.data.message);
                return;
              }
              if (response.data.status == "401") {
                setopenAlert(!openAlert);
                setErrMessage(response.data.message);
                return;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() != "" && password.trim() != "") {
      let data = {
        email: email,
        password: password,
      };
      axios
        .post("/auth/login", { ...data })
        .then((response) => {
          console.log(response);
          if (response.data.status == 200) {
            Cookies.set("loginToken", response.data.message.userInfo.token, {
              expires: expirationDate,
            });
            dispatch({ type: "login", login: { ...response.data.message } });
            dispatch({
              type: "cart_details",
              cart_details: response.data.message.cartdetails,
            });
            dispatch({ type: "refresh" });
            navigate("/home");
          }
          if (response.data.status == "404") {
            setopenAlert(!openAlert);
            setErrMessage(response.data.message);
            return;
          }
          if (response.data.status == "401") {
            setopenAlert(!openAlert);
            setErrMessage(response.data.message);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setopenAlert(!openAlert);
      setErrMessage("Invalid Input");
      return;
    }
  };
  return (
    <>
      {openForgotPass ? (
        <ForgotPassword />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="login-form">
              <div className="relative inline-block">
                <input
                  placeholder="Email / Phone Number"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{ className: "textfield-inp" }}
                  InputLabelProps={{ className: "textfield-label" }}
                />
                <span className="absolute right-[.5vw] top-[38%] translateY[-50%] pointer-events-none ">
                  <DogPettingIcon name="login-mail" />
                </span>{" "}
              </div>

              <div className="textfield-size">
                <div className="textfield-inp-wrapper">
                  <input
                    id="password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    className="textfield-inp"
                  />
                  <span
                    className="password-icon"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FaEye style={{ cursor: "pointer" }} />
                    ) : (
                      <FaEyeSlash style={{ cursor: "pointer" }} />
                    )}
                  </span>
                </div>
              </div>

              <button type="submit">Login</button>
            </div>
          </form>

          <p
            onClick={() => {
              setopenForgotPass(!openForgotPass);
              setShowTogglebtn(false);
            }}
            className="forgot-pass"
          >
            Forgot Password?
          </p>

          <div className="loginsocial">
            <hr className="line" /> &nbsp;
            <p>continue with</p> &ensp;
            <hr className="line" />
          </div>
          <div className="socialinks">
            <img
              style={{ cursor: "pointer" }}
              onClick={handleFacebookLogin}
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/Frame.png"
              alt="facebook"
            />
            <img
              style={{ cursor: "pointer" }}
              onClick={() => login()}
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/Frame(1).png"
              alt="google"
            />
            <img
              style={{ cursor: "pointer" }}
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/Vector.png"
              alt="apple"
            />
          </div>
          {openAlert ? (
            <AlertBox
              open={true}
              setOpen={setOpen}
              setopenAlert={setopenAlert}
              errMessage={errMessage}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

export default Login;
