import React, { useEffect, useState } from "react";
import "../PrivacyPolicy/PrivacyPolicy";
import NewHeader from "../../Components/Header/NewHeader";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

export default function Termsconditions() {
  const { section } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section>
        <NewHeader />
      </section>
      <div className="policy ">
        <section>
          <div
            className="policysec1  bg-black text-white h-[70vh] flex  justify-center items-center"
            id="terms"
          >
            <h1 className="text-[clamp(1.5rem,5vw,12rem)] font-[Prata]">
              Terms and Conditions{" "}
            </h1>
          </div>
        </section>
        <div className="index ">
          <div class="sections-container w-[80vw] pt-[8vh] m-auto flex flex-col justify-start gap-[3vh] text-justify">
            <div>
              <h2 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 1: Introduction
              </h2>
              <p>a. Overview of the app and its services.</p>
              <p>
                b. Explanation of the purpose and scope of the terms and
                conditions.
              </p>
              <p>c. User acceptance of the terms and conditions.</p>
            </div>
            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 2: Definitions
              </h4>
              <p>a. Define key terms used throughout the document.</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 3: User Obligations
              </h4>
              <p>a. Age and eligibility requirements.</p>
              <p>b. Accurate and truthful information provided by the user.</p>
              <p>
                c. Prohibited activities, such as misuse, unauthorized access,
                or hacking.
              </p>
              <p>d. Compliance with applicable laws and regulations.</p>
              <p>e. Responsibility for user-generated content and behaviour.</p>
              <p>f. Registration; Your Account Security</p>
              <p>g. Change of address</p>
              <p>h. Your conduct on peteye</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 4: Services
              </h4>
              <p>a. Description of the pet services offered by the app.</p>
              <p>b. Scope of the services and any limitations.</p>
              <p>c. Availability and maintenance of the app.</p>
              <p>d. Changes to the services and their implications.</p>
              <p>e. Products or services (if applicable)</p>
            </div>
            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 5: Location Tracking
              </h4>
              <p>a. Explanation of the app's location tracking feature.</p>
              <p>b. Consent and authorization for location tracking.</p>
              <p>c. Purpose and use of location data.</p>
              <p>d. User's ability to enable/disable location tracking.</p>
              <p>e. Data Security and Retention:</p>
              <p>f. Disclaimer of liability for accuracy of location data.</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 6: Pet Data Management
              </h4>
              <p>a. Collection and storage of pet data.</p>
              <p>
                b. Consent and authorization for the collection and use of pet
                data.
              </p>
              <p>
                c. Purpose and use of pet data, including customization of
                services.
              </p>
              <p>d. Protection of user's personal information and privacy.</p>
              <p>e. User's ability to access, modify, or delete pet data.</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 7: Data Sharing with Regulatory Bodies
              </h4>
              <p>
                a. Explanation of the app's data sharing with regulatory bodies.
              </p>
              <p>b. Types of data shared and purpose of sharing.</p>
              <p>c. Compliance with applicable laws and regulations.</p>
              <p>
                d. Measures taken to protect user privacy and data security.
              </p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 8: Research and Marketing
              </h4>
              <p>
                a. Use of data for research purposes, including academic or
                industry studies.
              </p>
              <p>
                b. Use of data for marketing purposes, such as promotional
                offers or targeted advertising.
              </p>
              <p>
                c. Opt-in and opt-out mechanisms for research and marketing
                activities.
              </p>
              <p>
                d. Protection of user privacy and data security in research and
                marketing.
              </p>
              <p>e. CI/CD tools</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 9: Intellectual Property
              </h4>
              <p>
                a. Ownership of app content and intellectual property rights.
              </p>
              <p>b. Restrictions on user's use of app content.</p>
              <p>c. Reporting of intellectual property infringement.</p>
              <p>d. Intellectual Properties Related to the Services</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 10: Limitation of Liability
              </h4>
              <p>
                a. Disclaimer of warranties and limitations of liability for app
                services.
              </p>
              <p>b. Indemnification by the user for any damages caused.</p>
              <p>
                c. Limitations on liability for third-party services or content.
              </p>
              <p>d. Limitation on Optional Tools</p>
              <p>e. Accuracy, Completeness and Timeliness OF Information</p>
              <p>f. No Liability for non-Peteye Actions</p>
              <p>g. Taxes</p>
              <p>h. Limitation</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 11: Termination and Suspension
              </h4>
              <p>
                a. Conditions for termination or suspension of app services.
              </p>
              <p>b. User's obligations upon termination or suspension.</p>
              <p>c. Prohibited Uses</p>
              <p>d. Right to Remove or Screen Your Content</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 12: Governing Law and Dispute Resolution
              </h4>
              <p>
                a. Applicable laws and jurisdiction governing the terms and
                conditions.
              </p>
              <p>
                b. Procedures for dispute resolution, such as arbitration or
                mediation.
              </p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 13: Modifications to Terms and Conditions
              </h4>
              <p>a. Right to modify or update the terms and conditions.</p>
              <p>
                b. Notification of changes and user's acceptance of updated
                terms.
              </p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 14: Consideration and Payment Processing
              </h4>
              <p>a. Modification of the services and prices</p>
              <p>b. Payment Processing</p>
              <p>c. Accuracy of billing and account information</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 15: Insurance
              </h4>
              <p>
                a. Intermediary: We are an insurance intermediary who does not
                provide personal recommendations about the insurance products we
                offer.
              </p>
              <p>
                b. Commission: When we sell you a policy the insurer pays us a
                percentage commission from the total premium.
              </p>
              <p>c. Handling Client and Insurance Money</p>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 16: Registration / Subscription Plan Contract
              </h4>
            </div>

            <div>
              <h4 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
                SECTION 17: Miscellaneous
              </h4>
              <p>a. Severability clause.</p>
              <p>b. Entire agreement clause.</p>
              <p>c. Contact information for inquiries or support.</p>
              <p>d. User Comments, Feedback And Other Submissions</p>
              <p>e. Errors, Inaccuracies And Omissions</p>
              <p>f. Force Majure clause:</p>
            </div>
          </div>
        </div>
        <div className="description1 w-[80vw] py-[8vh] m-auto flex flex-col gap-[3vh] text-justify">
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 1: Introduction
            </h3>
            <p>
              Welcome to Peteye! We are excited to offer you a comprehensive
              mobile app that provides a wide range of pet services to cater to
              all your furry friends' needs. Before you begin using our app, we
              kindly ask you to read and agree to the following terms and
              conditions, which govern your use of our services. By accepting
              these terms and conditions or by using our app, you acknowledge
              that you have read, understood, and agreed to be bound by them.{" "}
            </p>
            <h4>a. Overview of the app and its services.</h4>
            <p>
              Peteye is a mobile application designed to provide convenient and
              reliable pet services. Our app offers a variety of features if you
              need assistance with pet care, locating nearby pet-related
              businesses, or managing your pet's well-being, our app is here to
              make your life as a pet owner easier and more enjoyable
            </p>
            <h4>
              b. Explanation of the purpose and scope of the terms and
              conditions.
            </h4>
            <p>
              The purpose of these terms and conditions is to establish a legal
              agreement between the user, and Peteye. They outline your rights
              and responsibilities when using our app and govern the use of our
              services. It is important that you carefully review these terms as
              they contain important information regarding your use of the app,
              including location tracking, pet data management, data sharing,
              research and marketing activities, intellectual property rights,
              limitations of liability, and dispute resolution.
            </p>
            <p>
              Peteye operates this app. Throughout the site, the terms “we”,
              “us” and “our” refer to Peteye. Peteye offers this app, including
              all information, tools and services available from this app to
              you, the user, conditioned upon your acceptance of all terms,
              conditions, policies and notices stated here.
            </p>
            <h4>c. User acceptance of the terms and conditions.</h4>
            <p>
              By accessing or using our app, you agree to be bound by these
              terms and conditions. If you do not agree with any part of these
              terms, please refrain from using our services. Your continued use
              of the app constitutes your acceptance of these terms and any
              future updates or modifications.
            </p>
            <p>
              Any new features or tools that are added to the current app shall
              also be subject to the Terms of Service. You can review the most
              current version of the Terms of Service at any time on this page.
              We reserve the right to update, change or replace any part of
              these Terms of Service by posting updates and/or changes to our
              app. It is your responsibility to check this page periodically for
              changes. Your continued use of or access to the website following
              the posting of any changes constitutes acceptance of those
              changes.
            </p>
            <p>
              We value your trust and privacy, and we are committed to providing
              a safe and secure experience while using our app. If you have any
              questions or concerns regarding these terms and conditions or any
              aspect of our app, please don't hesitate to contact our support
              team. Your satisfaction and the well-being of your beloved pets
              are our top priorities.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 2: Definitions
            </h3>
            <h4>a. Define key terms used throughout the document.</h4>
            <p>
              <strong>App:</strong> Refers to the Peteye mobile application,
              including its associated software, interfaces, and services.
            </p>
            <p>
              <strong> User:</strong> Any individual who downloads, installs,
              and uses the app, whether as a pet owner seeking services or as a
              service provider offering pet-related services.
            </p>
            <p>
              <strong> Pet Services:</strong> The various pet-related services
              provided through the app, including but not limited to related
              activities.
            </p>
            <p>
              <strong>Location Tracking:</strong>The feature that allows the app
              to track the geographical location of the user and their pet in
              real-time or periodically, using GPS or other location
              technologies.
            </p>
            <p>
              <strong> Pet Data:</strong> Refers to the information provided by
              the user about their pet, which may include but is not limited to
              the pet's name, breed, age, gender, weight, medical history,
              vaccination records, dietary preferences, behaviour traits, and
              any other relevant details.
            </p>
            <p>
              <strong>Regulatory Bodies:</strong>Refers to authorized
              organizations, agencies, or entities responsible for governing and
              overseeing pet-related regulations, standards, and compliance,
              such as government departments, veterinary boards, animal welfare
              organizations, or industry-specific regulatory bodies.
            </p>
            <p>
              <strong> Research:</strong> Refers to the systematic
              investigation, study, or analysis conducted on aggregated or
              anonymized pet data collected through the app, aiming to gain
              insights, develop knowledge, or contribute to academic or industry
              research.
            </p>
            <p>
              <strong> Marketing:</strong> Refers to activities conducted to
              promote the app, its services, or third-party products or
              services, including but not limited to sending promotional offers,
              newsletters, targeted advertisements, or conducting market
              research or surveys.
            </p>
            <p>
              <strong>Intellectual Property:</strong> Refers to any copyrights,
              trademarks, patents, trade secrets, or other proprietary rights
              associated with the app, its content, design, branding, logos, or
              any other intellectual property owned by the app provider.
            </p>
            <p>
              <strong>Third-Party Services:</strong>Refers to services,
              products, or content provided by third-party individuals,
              companies, or organizations that may be accessed through the app,
              but are not directly controlled or owned by the app provider.
            </p>
            <p>
              <strong> Account:</strong> Refers to the user's account created
              within the app, which allows them to access and utilize the app's
              services, store pet data, and manage their preferences and
              settings.
            </p>
            <p>
              <strong>Infringement:</strong> Refers to the unauthorized use,
              reproduction, distribution, or modification of the app's
              intellectual property rights, in violation of applicable laws or
              these terms and conditions.
            </p>
            <p>
              <strong>Pet Health Data:</strong>Refers to any information related
              to the physical or mental health of a pet, including but not
              limited to medical records, diagnoses, treatments, medications,
              allergies, and any other sensitive health-related information.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 3: User Obligations
            </h3>
            <h4>a. Age and eligibility requirements.</h4>
            <p>
              By agreeing to these Terms of Service, you represent that you are
              at least the age of majority in your state or province of
              residence, or that you are the age of majority in your state or
              province of residence and you have given us your consent to allow
              any of your minor dependents to use this site.
            </p>
            <h4>b. Accurate and truthful information provided by the user.</h4>
            <p>
              Users are responsible for providing accurate, current, and
              complete information during the registration or account setup
              process. It is important to ensure that all information, including
              personal details and pet data, is truthful and up to date.
            </p>
            <h4>
              c. Prohibited activities, such as misuse, unauthorized access, or
              hacking.
            </h4>
            <p>
              Users are prohibited from engaging in any activities that may
              disrupt or misuse the app or its services. This includes but is
              not limited to, copyright laws or illegal or unauthorized access
              to other users' accounts, attempts to breach the app's security
              measures, hacking, introducing viruses or malicious software, or
              any other actions that may compromise the integrity or
              functionality of the app nor may you, in the use of the Service,
              violate any laws in your jurisdiction
            </p>
            <h4>d. Compliance with applicable laws and regulations.</h4>
            <p>
              Users must comply with all applicable laws, regulations, and
              guidelines related to pet ownership, pet care, data protection,
              privacy, and any other relevant legal requirements. Users are
              solely responsible for ensuring their actions and use of the app
              comply with these laws and regulations.
            </p>
            <h4>e. Responsibility for user-generated content and behaviour.</h4>
            <p>
              Users are solely responsible for any content they generate,
              upload, or share through the app, including reviews, comments,
              images, or any other form of user-generated content. Users agree
              not to upload or share any content that is unlawful, defamatory,
              infringing, or otherwise violates the rights of others. Users are
              also responsible for their behaviour while using the app and must
              adhere to respectful and lawful conduct when interacting with
              other users or service providers.
            </p>
            <p>
              These user obligations are designed to ensure a safe and reliable
              environment for all users of the app. By accepting the terms and
              conditions and using the app, users agree to abide by these
              obligations and understand that any violation may result in
              consequences, including account suspension or termination.{" "}
            </p>
            <h4>f. Registration; Your Account Security </h4>
            <p>
              To use some aspects of the Peteye Service, you will be required to
              create a username, password, and user profile. If you elect to use
              the Peteye Service, you agree to provide accurate information
              about yourself and your pet/s and keep this information up to
              date. You agree not to impersonate anyone else and not to maintain
              more than one account (or, if Peteye suspends or terminates your
              account, not to create additional accounts). You are responsible
              for maintaining the confidentiality of your username and password
              for the Peteye Service. You are responsible for all activity under
              your account. You agree to notify us promptly of any unauthorised
              use of your account. {" "}
            </p>
            <h4>g. Change of Address </h4>
            <p>
              The contracting party is responsible for notifying Tractive about
              any changes to its shipping or billing address, as long as the
              contractual transaction is not mutually fulfilled. If no notice is
              given, declarations shall be deemed to have been received even if
              they are sent to the last known address{" "}
            </p>
            <h4>h. Your Conduct on the Peteye Service </h4>
            <p>When you use the Peteye Service, you agree:  </p>
            <p>
              1.To use the Peteye Service only in a lawful manner and only for
              its intended purposes. {" "}
            </p>
            <p>2.Not to use the Peteye Service to arrange for the care of: </p>
            <p>
              (a) exotic or inherently dangerous pets such as venomous snakes or
              constrictors, primates, wolves or wolf hybrids, non-domesticated
              cats, reptiles, horses or other livestock;{" "}
            </p>
            <p>
              (b) any animal whose Pet Parentship or third-party care is
              prohibited under applicable law; or
            </p>
            <p>
              (c) any animal that has a history of, or which has been trained
              for, aggression towards or attacks on pets or people. {" "}
            </p>
            <p>
              3.Not to submit viruses or other malicious code to or through the
              Peteye Service.
            </p>
            <p>
              4. Not to use the Peteye Service or engage with other users of the
              Peteye Service, for purposes that violate the law.
            </p>
            <p>
              5. Not to use the Peteye Service to arrange for the provision and
              purchase of services with another user, and then complete
              transactions for those services outside of the Peteye Service.
            </p>
            <p>
              6. Not to use the Peteye Service for purposes of competing with
              Peteye or to promote other products or services.
            </p>
            <p>
              7. Not to post reviews about Pet Professionals that aren’t based
              on your personal experience, that are intentionally inaccurate or
              misleading, or that violate these Terms.
            </p>
            <p>
              8. Not to post content or materials that are pornographic,
              threatening, harassing, abusive, or defamatory, or that contain
              nudity or graphic violence, incite violence, violate intellectual
              property rights, or violate the law or the legal rights (for
              example, privacy rights) of others.
            </p>
            <p>
              9. Not to post “spam” or other unauthorised commercial
              communications.
            </p>
            <p>
              10. To use the Peteye Service only for your own purposes, and not
              to impersonate any other person.
            </p>
            <p>
              11. Not to transfer or authorise the use of your account for the
              Peteye Service by any other person, or to engage in fraudulent
              transactions.
            </p>
            <p>
              12. Not to provide false information in your profile on, or
              registration for, the Peteye Service, or to create multiple or
              duplicate accounts.
            </p>
            <p>
              13. Not to interfere with our provision of or any other user’s use
              of, the Peteye Life Service.
            </p>
            <p>
              14. Not to solicit another user’s username and password for the
              Peteye Service or any other sensitive personal information,
              including bank details.
            </p>
          </div>
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              SECTION 4: Services
            </h3>
            <h4>a. Description of the pet services offered by the app.</h4>
            <p>
              Peteye offers a simplified yet essential set of pet services in
              its Minimum Viable Product (MVP) stage. The MVP focuses on
              providing basic functionalities to address the key needs of pet
              owners. The services included in the MVP are:
            </p>

            <p>
              Unique ID Tag: With Peteye, pet owners can order and customize
              unique identification tags for their pets. These tags are engraved
              with a unique code and QR scan functionality, allowing anyone who
              finds a lost pet to quickly access the pet's profile and contact
              the owner. This service helps reunite lost pets with their owners
              promptly, ensuring their well-being and safety.
            </p>
            <p>
              GPS Services: Our app provides GPS tracking services that enable
              pet owners to monitor their pets' location in real-time. By
              attaching a small GPS device to the pet's collar, owners can
              easily track their pet's movements through the app. This feature
              provides an extra layer of security, allowing pet owners to
              quickly locate their pets if they wander off or get lost.
            </p>
            <p>
              Insurance: We offer comprehensive pet insurance coverage through
              our app. Pet owners can choose from a range of insurance plans
              that cover veterinary expenses, accidents, illnesses, and other
              unforeseen circumstances. Our insurance services provide financial
              protection and peace of mind, ensuring that pet owners can provide
              the best possible care for their beloved companions without
              worrying about exorbitant medical costs.
            </p>
            <p>
              Consultation Services: Our company facilitates veterinary
              consultation for pets through the Consultants, listed on the
              Platform. The Consultants may be listed on the Platform, either
              directly or through a third party. The platform enables the Users
              to connect with the Consultants through the Platform by various
              modes including video, or telephonic service, as may be available
              on the Platform.
            </p>
            <p>
              Sale of Veterinary Products: The Company lists various Products
              for sale on the Platform. Such listing of Products on the Platform
              is merely an ‘invitation to offer’ and not an ‘offer for sale’.
              The Products include over-the-counter medicines as well as
              prescription medicines. The Platform has duly obtained all
              licenses and registrations as may be required to undertake its
              business and abides by all the applicable laws including relating
              to the dispensing of prescription medicines.
            </p>
            <p>
              All prescription medicines shall be sold and dispensed by a
              registered pharmacist at the Company.
            </p>
            <p>
              In the event You order prescription medicines, You shall share a
              scanned copy of the valid prescription for the concerned animal,
              through such means as may be required by the Platform and such
              prescription shall be subject to the scrutiny and approval of Our
              registered pharmacist. As a standard practice, You will be able to
              purchase a substitute medicine from the Platform, only if Your
              prescription contains the phrase “Allow substitution”, or mentions
              the name of the molecule.
            </p>
            <p>
              You hereby agree and acknowledge that the Platform is an
              intermediary that facilitates the provision of veterinary
              teleconsultation services on the Platform and is in no manner
              involved in providing such teleconsultation services to You. The
              Consultants are independent professionals and such services are
              being provided solely by them to the relevant pet. Therefore, they
              are responsible for the teleconsultation services rendered to the
              patient and the Company or the Platform is not in any manner
              liable for the consultations provided by such Consultants through
              the Platform including diagnosis, prescription, medication, and
              treatment prescribed to the patient and/or for their compliance
              with applicable laws.
            </p>
            <p>
              The Company shall not be liable for breach of confidentiality and
              privacy of Users by the Consultants and claims of any nature
              whatsoever arising out of the wilful misconduct and negligence
              (including gross negligence) of such Consultants.
            </p>
            <p>
              The credentials of the Consultant and any description of their
              registrations/certifications, location, contact details, etc.
              displayed on the Platform, are as provided by the Consultant to
              the Company and the Company shall not in any manner, be
              responsible or liable for such information.
            </p>
            <p>
              While We do check and authenticate the veterinarians listed on the
              Platform, You should independently also verify the credential of
              the Consultants and We are not liable for the same. Further, the
              Platform may have veterinarians from countries other than India,
              listed on the Platform, who may not be registered under the Indian
              laws to provide the veterinarian services. However, if You choose
              to procure the Services from such veterinarians, You hereby agree
              and acknowledge that consultation provided by such foreign
              Consultants is advisory in nature and should not be considered as
              a medical opinion.
            </p>
            <p>
              {" "}
              You agree that by using the Platform, the Consultant will not be
              conducting physical examination of the patient, hence, they may
              not have or be able to derive important information that is
              usually obtained through a physical examination. You hereby
              acknowledge and agree that You are aware of these limitations and
              agree to assume the complete risk of these limitations.
            </p>
            <p>
              You agree that all details provided by You during the course of
              availing Services on the Platform will be honest, accurate and
              complete. You further acknowledge and understand that
              misrepresentation of information or not providing all or complete
              details in relation to or for availing the Services may lead to
              inaccurate diagnosis and treatment and consequently inaccurate
              prescription. You agree to indemnify and hold harmless the Company
              and its directors, officers, and agents from and against any and
              all claims, proceedings, losses, penalties, liabilities, and
              damages arising due to or in relation to Your breach of this
              Clause.
            </p>

            <h4>b. Scope of the services and any limitations.</h4>
            <p>
              The Unique ID Tag service enables pet owners to order and
              customize identification tags with limited options available in
              terms of material, shape, and engraving. The focus is on providing
              a functional ID tag for easy identification rather than extensive
              customization.
            </p>
            <p>
              The GPS Services provide basic real-time location tracking, but it
              may have limitations in terms of accuracy due to signal strength
              and environmental factors. Users should be aware that the GPS
              functionality may not be as precise as dedicated GPS tracking
              devices.
            </p>
            <p>
              The Insurance service in the MVP covers basic veterinary expenses,
              accidents, and illnesses as outlined in the selected plan.
              However, it may have limitations in terms of coverage amounts and
              exclusions. Users should carefully review the plan details within
              the app to understand the scope of coverage.
            </p>
            <h4>c. Availability and maintenance of the app.</h4>
            <p>
              Peteye aims to maintain the availability and functionality of the
              app and its services during the MVP stage. However, occasional
              maintenance, updates, and bug fixes may be required, which could
              result in temporary unavailability. Users will be informed of any
              planned maintenance or downtime in advance whenever possible.
            </p>
            <h4>d. Changes to the services and their implications.</h4>
            <p>
              As the app progresses beyond the MVP stage, Peteye may introduce
              additional features, expand the range of services, and refine
              existing functionalities based on user feedback and market
              demands. Users should anticipate changes and updates to the
              services as the app evolves. Significant changes and their
              implications will be communicated to users, and their feedback
              will be considered during the development process.
            </p>
            <p>
              lease note that as an MVP, the services provided are limited in
              functionality and scope compared to the future versions of the
              app. Peteye appreciates users' understanding and feedback to
              improve and enhance the app's services.
            </p>
            <h4>e. Products Or Services (if applicable)</h4>
            <p>
              We reserve the right but are not obligated, to limit the sales of
              our products or Services to any person, geographic region or
              jurisdiction. We may exercise this right on a case-by-case basis.
              We reserve the right to limit the quantities of any products or
              services that we offer. All descriptions of products or product
              pricing are subject to change at any time without notice, at our
              sole discretion. We reserve the right to discontinue any product
              at any time. Any offer for any product or service made on this
              site is void where prohibited.
            </p>
            <p>
              We do not warrant that the quality of any products, services,
              information, or other material purchased or obtained by you will
              meet your expectations, or that any errors in the Service will be
              corrected.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              SECTION 5: Location Tracking
            </h3>
            <h4>a. Explanation of the app's location tracking feature.</h4>
            <p>
              Peteye incorporates a location tracking feature that allows pet
              owners to monitor the real-time location of their pets. Through
              the app, pet owners can view the approximate location of their
              pets on a map, providing them with peace of mind and an added
              layer of security.
            </p>
            <h4>b. Consent and authorization for location tracking.</h4>
            <p>
              Before enabling the location tracking feature, users will be
              prompted to provide consent and authorize the app to access and
              track their pet's location. By granting consent, users acknowledge
              their understanding of the purpose and implications of location
              tracking.The consent request will be separate from any other terms
              and conditions and will require an affirmative action from the
              user to grant permission.
            </p>
            <h4>c. Purpose and use of location data.</h4>
            <p>
              The location data obtained through the app's tracking feature
              serves the following purposes:
            </p>
            <p>
              <strong>Real-time Pet Tracking:</strong>
              To provide pet owners with the ability to monitor their pets'
              whereabouts in real-time, enhancing their safety and security.
            </p>
            <p>
              {" "}
              <strong>Location History:</strong> To maintain a historical record
              of the pet's movements, allowing owners to review past locations
              and identify patterns or trends.
            </p>
            <p>
              <strong> Geofencing and Alerts:</strong> To enable the creation of
              virtual boundaries or safe zones, triggering notifications or
              alerts if the pet leaves the designated area.
            </p>
            <p>
              <strong> Location-Based Services:</strong> To offer
              location-specific features and recommendations, such as nearby
              pet-friendly parks, veterinarians, or pet stores.
            </p>
            <p>
              <strong> Service Improvement:</strong> To analyze aggregated and
              anonymized location data to improve the app's functionality,
              enhance user experience, and develop new features.
            </p>
            <h4>d. User's ability to enable/disable location tracking.</h4>
            <p>
              Peteye offers users the flexibility to enable or disable the
              location tracking feature based on their preferences. Users can
              access the app's settings or privacy options to manage their
              location tracking preferences. Disabling location tracking will
              prevent the app from accessing and displaying the pet's location.
            </p>
            <h4>e. Data Security and Retention:</h4>
            <p>
              Peteye employs industry-standard security measures to protect the
              confidentiality and integrity of location data. This includes
              encryption of data in transit and at rest, access controls, and
              regular security audits. Location data is retained only for as
              long as necessary to provide the location tracking services and as
              permitted by applicable law. Users have the right to request the
              deletion of their pet's location history at any time.
            </p>
            <h4>f. Disclaimer of liability for accuracy of location data.</h4>
            <p>
              While Peteye strives to provide accurate location data, it is
              important to note that the accuracy of the location information
              may be subject to limitations and external factors beyond the
              app's control. The accuracy can be influenced by various factors
              such as GPS signal strength, environmental conditions, and the
              pet's surroundings. Therefore, Peteye disclaims liability for any
              inaccuracies in the location data provided by the app and advises
              users to use the information as a general reference rather than
              relying solely on it for precise location tracking.
            </p>
            <p>
              Users should exercise caution and employ other means of ensuring
              their pets' safety, such as secure fencing, proper supervision,
              and microchipping, in addition to using the app's location
              tracking feature.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 6: Pet Data Management
            </h3>
            <h4>a. Collection and storage of pet data.</h4>
            <p>
              Peteye collects and securely stores pet-related data provided by
              users. This data may include information such as the pet's name,
              breed, age, medical history, vaccination records, dietary
              preferences, and other relevant details. The app ensures the
              confidentiality and integrity of this data through appropriate
              security measures.
            </p>
            <h4>
              b. Consent and authorization for the collection and use of pet
              data.
            </h4>
            <p>
              By using Peteye, users provide their explicit consent and
              authorization for the collection, storage, and use of their pet's
              data within the app. Users acknowledge that the provision of
              accurate and up-to-date information is their responsibility, and
              they have the necessary rights and permissions to share this data.
            </p>
            <h4>
              c. Purpose and Use of Pet Data, Including Customization of
              Services.
            </h4>
            <p>
              The pet data collected is primarily used to provide personalized
              and customized services within the app. This includes tailoring
              recommendations, providing relevant pet care information,
              suggesting compatible services, and enhancing the overall user
              experience. The app utilizes the collected data to offer insights,
              tips, and reminders based on the specific needs and
              characteristics of each user's pet.
            </p>
            <h4>d. Protection of User's Personal Information And Privacy.</h4>
            <p>
              Peteye places utmost importance on protecting user privacy and
              personal information. The app employs industry-standard security
              measures and safeguards to prevent unauthorized access, loss, or
              misuse of pet data. User data, including pet-related information,
              is treated in accordance with applicable data protection laws and
              regulations.
            </p>
            <h4>e. User's Ability to Access, Modify, or Delete Pet Data.</h4>
            <p>
              Peteye acknowledges the importance of user control over their
              pet's data. Users have the right to access, review, modify, and
              update their pet's information within the app. Additionally, users
              can delete their pet's data and associated records if they choose
              to discontinue using the app or no longer wish to store their
              pet's information.
            </p>
            <p>
              Peteye provides user-friendly tools and functionalities to
              facilitate these actions, allowing users to manage their pet's
              data as per their preferences. Users can access the app's settings
              or account management features to exercise these rights.
            </p>
            <p>
              It is essential to note that while Peteye takes all reasonable
              measures to protect user data, no method of data transmission or
              storage can be guaranteed to be 100% secure. Users should also
              ensure that they take appropriate precautions to maintain the
              security of their login credentials and prevent unauthorized
              access to their account and pet data.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 7:Data Sharing and Regulatory Bodies
            </h3>
            <h5>
              a. Explanation of the App’s Data Sharing with Regulatory Bodies:
            </h5>
            <p>
              {" "}
              Our app is dedicated to ensuring the confidentiality and security
              of our users’ and their pets’ information. We share user and pet
              information exclusively for licensing purposes with regulatory
              bodies. Any further sharing of information will be strictly
              limited to what is required by regulatory bodies or mandated by
              law, ensuring compliance while prioritizing data privacy.
            </p>
            <h5>b. Types of Data Shared and Purpose of Sharing:</h5>
            <p>
              The data shared primarily includes user information and pet
              information necessary for obtaining licenses and fulfilling
              regulatory requirements. This may encompass contact details, pet
              health records, and other relevant data. The purpose of such
              sharing is to comply with legal and regulatory obligations,
              facilitate the licensing process, and ensure the welfare of pets
              under our users’ care.{" "}
            </p>
            <h5>c.Compliance with Applicable Laws and Regulations:</h5>
            <p>
              We are fully committed to complying with all applicable laws and
              regulations concerning data sharing and privacy. Our data sharing
              practices are designed to meet legal standards and regulatory
              demands while minimizing the impact on our users’ privacy. Users
              will be informed of any significant changes in our data sharing
              practices through updates to our terms and conditions or privacy
              policy.{" "}
            </p>
            <h5>
              d. Measures taken to protect user privacy and data security:
            </h5>
            <p>
              We take the security of your data seriously. We have internal
              policies and controls in place to try to ensure that your data is
              not lost, accidentally destroyed, misused or disclosed, and is not
              accessed by unauthorised individuals.
            </p>
            <p>How we will use your data:</p>

            <p>
              To provide insurance services (quotation, policy and claims
              handling)
            </p>
            <p>To comply with our legal obligations</p>
            <p>To deal with complaints</p>
            <p>
              To help prevent and detect crime (including, for example, the
              prevention or detection of fraud); and
            </p>
            <p>
              to comply with a legal or regulatory obligation and/or assist our
              regulators (e.g. Financial Conduct Authority)
            </p>
            <p>To prevent fraud</p>
            <p>
              To help develop our products, services and systems to deliver a
              better experience in the future; we may also process your personal
              data to better understand you as a customer, including to
              determine how best to retain your custom, and to ask you to
              provide feedback on the service we provide to you.
            </p>

            <p>How long do we keep the data?</p>
            <p>
              We will only keep your personal data for as long as is necessary
              for providing our products and services to you in accordance with
              the jurisdiction law unless we are required to retain the data for
              a longer period due to legal or regulatory requirements or to
              defend a claim.
            </p>
            <p>
              {" "}
              We will also retain data in an anonymous form for statistical and
              analytical purposes. Following the end of the retention periods,
              your personal data will be securely destroyed.
            </p>
            <p>
              In addition, you have a right to complain to the Information
              Commissioner’s Office about the way in which we process your
              personal data.{" "}
            </p>
            <p>
              Please note that there are times when we will not be able to
              delete your information. This may be because of the need to fulfil
              our legal and regulatory obligations or where there is a
              statutory, period for which we must retain your information. If we
              are unable to fulfil a request we will always let you know why.
            </p>
          </div>
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 8:Research and Marketing
            </h3>
            <h4>
              a. Use of data for research purposes, including academic or
              industry studies:
            </h4>
            <p>
              Peteye may utilize anonymized and aggregated data collected from
              users' pet profiles for research purposes. This may involve
              conducting academic or industry studies to gain insights into pet
              behaviour, health trends, and overall pet care practices. The
              research aims to improve the app's services, develop new features,
              and contribute to the advancement of knowledge in the field of pet
              care.
            </p>
            <h4>
              b. Use of data for marketing purposes, such as promotional offers
              or targeted advertising:
            </h4>
            <p>
              Advertising: With users' consent, Peteye may use non-sensitive,
              aggregated data to provide personalized marketing experiences
              within the app. This may include offering promotional offers,
              discounts, or recommendations for pet-related products and
              services that align with users' preferences and their pets' needs.
              Additionally, Peteye may engage in targeted advertising campaigns
              to deliver relevant advertisements to users, based on their pet
              profiles and interests.
            </p>
            <h4>
              c. Opt-in and opt-out mechanisms for research and marketing
              activities:
            </h4>
            <p>
              Peteye respects users' preferences regarding the use of their data
              for research and marketing activities. The app provides clear
              opt-in and opt-out mechanisms, allowing users to choose whether
              they want to participate in research studies or receive marketing
              communications. Users can adjust their preferences at any time
              within the app's settings or through specific opt-in/out options
              presented within relevant research or marketing communications.
            </p>
            <h4>
              d. Protection of user privacy and data security in research and
              marketing:
            </h4>
            <p>
              Peteye maintains strict measures to protect user privacy and data
              security in all research and marketing activities. Personal
              information and sensitive data are anonymized and aggregated
              whenever possible to maintain individual privacy. Data is stored
              securely and accessed only by authorized personnel for legitimate
              research and marketing purposes.
            </p>
            <p>
              Peteye follows applicable laws and regulations regarding data
              protection and privacy. The app does not sell or share personal
              data with third parties for their direct marketing purposes
              without explicit user consent. Peteye takes reasonable steps to
              ensure that any third parties involved in research or marketing
              activities adhere to the same high standards of data protection
              and privacy.
            </p>
            <p>
              Users' trust and confidence in the app's commitment to privacy and
              data security are paramount. Peteye maintains transparent
              communication with users, providing clear information on how their
              data is used and offering control over their preferences. Users
              can reach out to Peteye's support team for any concerns or
              inquiries regarding the app's research and marketing practices.
            </p>
            <h4>e. CI/CD tools:</h4>
            <p>
              We may use third-party Service Providers to automate the
              development process of our Service.
            </p>
          </div>
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 9 :Intellectual Property
            </h3>
            <h4>
              a. Ownership of app content and intellectual property rights:
            </h4>
            <p>
              Peteye acknowledges and retains ownership of all intellectual
              property rights associated with the app, including but not limited
              to its design, layout, graphics, logos, icons, and any other
              original content created by Peteye or its affiliates. These
              intellectual property rights are protected by applicable
              copyright, trademark, and other intellectual property laws.
            </p>
            <h4>b. Restrictions on user's use of app content:</h4>
            <p>
              Users are granted a limited, non-exclusive, and non-transferable
              license to use the app and its content solely for personal and
              non-commercial purposes. Users must not copy, modify, distribute,
              transmit, display, perform, or create derivative works of the
              app's content without prior written consent from Peteye. Any
              unauthorised use of the app's content may infringe upon
              intellectual property rights and is strictly prohibited.
            </p>
            <h4>c. Reporting of intellectual property infringement:</h4>
            <p>
              Peteye respects the intellectual property rights of others and
              expects users to do the same. If users believe that their
              intellectual property rights have been infringed upon through the
              use of the app, they are encouraged to notify Peteye promptly.
              Users should provide detailed information regarding the alleged
              infringement, including identification of the infringing material
              and supporting evidence of ownership or authorized use.
            </p>
            <p>
              Peteye will investigate reported intellectual property
              infringement claims promptly and take appropriate actions, which
              may include removing or disabling access to the infringing
              material. Peteye may also terminate the accounts of users who
              repeatedly infringe upon intellectual property rights.
            </p>
            <p>
              Peteye recognizes the importance of protecting intellectual
              property and encourages users to respect the rights of others when
              using the app and its content. Users are also reminded that any
              content they submit or upload to the app should not infringe upon
              the intellectual property rights of third parties.
            </p>
            <h4>d. Intellectual Properties Related to the Services:</h4>
            <p>
              Peteye shall own all intellectual property rights developed or
              discovered through research and development, in the course of
              providing Services, or derived from the provision of the Services.
              Such intellectual property rights shall include patents,
              trademarks, trade names, copyrights, patent application rights,
              copyright and trademark application rights, research and technical
              documents and materials, and other related intellectual property
              rights including the right to license or transfer such
              intellectual properties. Peteye agrees to grant an appropriate
              license to the user on terms and conditions to be outlined in the
              agreement.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              {" "}
              Section 10: Limitation of Liability
            </h3>
            <h4>
              a. Disclaimer of warranties and limitations of liability for app
              services:
            </h4>
            <p>
              Peteye provides the app and its services on an "as is" and "as
              available" basis. While Peteye strives to ensure the accuracy and
              reliability of the app, it does not make any warranties or
              representations regarding the app's functionality, reliability, or
              suitability for specific purposes. Users acknowledge that the use
              of the app is at their own risk, and Peteye disclaims all
              warranties, whether expressed or implied, including but not
              limited to warranties of merchantability, fitness for a particular
              purpose, and non-infringement.
            </p>
            <p>
              Peteye shall not be liable for any direct, indirect, incidental,
              consequential, or special damages arising out of or in connection
              with the use of the app, including but not limited to damages for
              loss of profits, data, or other intangible losses. Users agree
              that Peteye's liability, if any, arising from the use of the app
              shall be limited to the extent permitted by applicable laws.
            </p>
            <h4>b. Indemnification by the user for any damages caused:</h4>
            <p>
              Users agree to indemnify, defend, and hold Peteye harmless from
              any claims, liabilities, damages, losses, or expenses arising out
              of or in connection with their use of the app, violation of the
              terms and conditions, or infringement of any rights of third
              parties.
            </p>
            <p>
              Users acknowledge and accept that they are solely responsible for
              their actions and the consequences thereof while using the app.
              Peteye shall not be held liable for any damages or losses
              resulting from the user's interactions, transactions, or
              engagements with other users or third-party service providers
              through the app.
            </p>
            <h4>
              c. Limitations on liability for third-party services or content:
            </h4>
            <p>
              Peteye may provide access to third-party services, content, or
              websites through the app. Users understand and agree that Peteye
              does not endorse, control, or assume any responsibility for the
              availability, accuracy, legality, or quality of such third-party
              services or content. Peteye shall not be liable for any damages or
              losses incurred as a result of the user's reliance on or use of
              such third-party services or content.
            </p>
            <p>
              Users are advised to review the terms and conditions and privacy
              policies of any third-party services or websites they access
              through the app. Any interactions, transactions, or engagements
              with third parties are solely between the user and the respective
              third party, and Peteye shall not be held liable for any disputes,
              damages, or losses arising from such interactions.
            </p>
            <p>
              Users need to understand that the limitations of liability
              mentioned in these terms and conditions apply to the maximum
              extent permitted by applicable laws, and some jurisdictions may
              not allow the exclusion or limitation of certain types of damages.
              In such cases, the liability of Peteye shall be limited to the
              fullest extent permitted by law.
            </p>
            <h4>d. Limitation on Optional Tools:</h4>
            <p>
              {" "}
              We may provide you with access to third-party tools over which we
              neither monitor nor have any control or input.
            </p>
            <p>
              You acknowledge and agree that we provide access to such tools "as
              is" and "as available" without any warranties, representations or
              conditions of any kind and any endorsement. We shall have no
              liability whatsoever arising from or relating to your use of
              optional third-party tools.
            </p>
            <p>
              Any use by you of optional tools offered through the site is
              entirely at your own risk and discretion and you should ensure
              that you are familiar with and approve of the terms on which tools
              are provided by the relevant third-party provider(s).
            </p>
            <p>
              We may also, in the future, offer new services and/or features
              through the website (including, the release of new tools and
              resources). Such new features and/or services shall also be
              subject to these Terms of Service.
            </p>
            <h4>e. Accuracy, Completeness and Timeliness OF Information:</h4>
            <p>
              We are not responsible if the information made available on this
              app is not accurate, complete or current. The material on this
              site is provided for general information only and should not be
              relied upon or used as the sole basis for making decisions without
              consulting primary, more accurate, more complete or more timely
              sources of information. Any reliance on the material on this app
              is at your own risk.
            </p>
            <p>
              This site may contain certain historical information. Historical
              information, necessarily, is not current and is provided for your
              reference only. We reserve the right to modify the contents of
              this site at any time, but we have no obligation to update any
              information on our site. You agree that it is your responsibility
              to monitor changes to our site.
            </p>
            <h4>f. No Liability for non-Peteye Actions:</h4>
            <p>
              To the maximum extent permitted by applicable law, in no event
              will Peteye be liable for any damages whatsoever, whether direct,
              indirect, general, special, compensatory, and/or consequential,
              arising out of or relating to the conduct of you or anyone else in
              connection with the Peteye service, including without limitation,
              bodily injury, death, emotional distress, and/or any other damages
              resulting from reliance on information or content posted on or
              transmitted through the Peteye service, or for any interactions
              with other users of the Peteye service, whether online or offline.
              This includes any claims, losses or damages arising from the
              conduct of users who attempt to defraud or harm you.
            </p>
            <p>
              If you have a dispute with a pet professional or pet parent, you
              agree to release Peteye from all claims, demands and damages of
              every nature, known and unknown, arising out of or in any way
              connected with such disputes, except to the extent stated in the
              Peteye guarantee. In no event will Peteye be liable for direct or
              indirect consequences of a pet parent or pet professional failing
              to comply with applicable laws and regulations.
            </p>
            <h4>g. Taxes:</h4>
            <p>
              Except for taxes on Peteye income and gross receipts or where
              Peteye is otherwise required to collect taxes, you acknowledge
              that you are solely responsible for paying any applicable taxes
              that arise as a result of your purchase, provision, or use of Pet
              Care Services via the Peteye Service. This includes but not
              limited to, any form of sales tax, VAT, or income tax on fees paid
              or received by you through the Peteye Service.
            </p>
            <h4>h. Limitation:</h4>
            <p>
              This Agreement does not create a joint venture, legal partnership,
              employment or agency relationship between Peteye and Client.
            </p>
          </div>
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 11: Termination and Suspension
            </h3>
            <p>
              The obligations and liabilities of the parties incurred before the
              termination date shall survive the termination of this agreement
              for all purposes. These Terms of Service are effective unless and
              until terminated by either you or us. You may terminate these
              Terms of Service at any time by notifying us that you no longer
              wish to use our Services, or when you cease using our site.
            </p>
            <p>
              If in our sole judgment, you fail, or we suspect that you have
              failed, to comply with any term or provision of these Terms of
              Service, we also may terminate this agreement at any time without
              notice and you will remain liable for all amounts due up to and
              including the date of termination; and/or accordingly may deny you
              access to our Services (or any part thereof).
            </p>
            <h4>a. Conditions For Termination Or Suspension Of App Services</h4>
            <p>
              Peteye reserves the right to terminate or suspend the provision of
              app services under certain circumstances. These may include, but
              are not limited to:
            </p>
            <p>
              Violation of the terms and conditions:Users who fail to comply
              with the terms and conditions, engage in prohibited activities, or
              misuse the app may face termination or suspension of their access
              to the app's services.{" "}
            </p>
            <p>
              Legal requirements: If Peteye determines that the user's
              activities violate applicable laws, regulations, or legal
              obligations, it may result in the termination or suspension of the
              user's access to the app.{" "}
            </p>
            <p>
              Maintenance and upgrades: Peteye may temporarily suspend or
              terminate app services to perform maintenance, updates, or
              upgrades to ensure the app's optimal performance and
              functionality.{" "}
            </p>
            <p>
              Security concerns: In the event of security breaches, suspected
              unauthorized access, or any other activities that may jeopardize
              the integrity or security of the app or its users' data, Peteye
              reserves the right to terminate or suspend app services.{" "}
            </p>
            <h4>b. User's Obligations Upon Termination or Suspension</h4>
            <p>
              Upon termination or suspension of app services, users are expected
              to adhere to the following obligations:
            </p>

            <p>
              Discontinuation of app use: Users must immediately cease using the
              app and refrain from accessing or attempting to access any
              features or services provided by Peteye.{" "}
            </p>
            <p>
              Return or deletion of data: Users should remove any downloaded or
              stored app content from their devices. Additionally, if
              applicable, users may be required to delete their pet profiles or
              any other personal data associated with the app.{" "}
            </p>
            <p>
              Compliance with restrictions: Users must respect any restrictions
              or limitations imposed during the termination or suspension
              period, including refraining from creating new accounts or
              attempting to access the app through unauthorized means.{" "}
            </p>
            <p>
              Retention of rights: Termination or suspension of app services
              does not affect the user's underlying rights or obligations under
              applicable laws and regulations.
            </p>

            <p>
              Users need to understand that Peteye's decision to terminate or
              suspend app services is at its sole discretion and may be enforced
              without prior notice. Users are advised to review the terms and
              conditions to ensure compliance and to seek clarification or
              support from Peteye's customer service team in the event of
              termination or suspension concerns.
            </p>
            <h4>c. Prohibited Uses</h4>
            <p>
              In addition to other prohibitions as outlined in the Terms of
              Service, you are prohibited from using the site or its content:
            </p>
            <p>(a) for any unlawful purpose;</p>
            <p>
              (b) to solicit others to perform or participate in any unlawful
              acts;
            </p>
            <p>
              (c) to violate any international, federal, provincial or state
              regulations, rules, laws, or local ordinances;
            </p>
            <p>
              (d) to infringe upon or violate our intellectual property rights
              or the intellectual property rights of others;
            </p>
            <p>
              (e) to harass, abuse, insult, harm, defame, slander, disparage,
              intimidate, or discriminate based on gender, sexual orientation,
              religion, ethnicity, race, age, national origin, or disability;
            </p>
            <p>(f) to submit false or misleading information;</p>
            <p>
              (g) to upload or transmit viruses or any other type of malicious
              code that will or may be used in any way that will affect the
              functionality or operation of the Service or any related website,
              other websites, or the Internet;
            </p>
            <p>(h) to collect or track the personal information of others;</p>
            <p>(i) to spam, phish, pharm, pretext, spider, crawl, or scrape;</p>
            <p>(j) for any obscene or immoral purpose; or</p>
            <p>
              (k) to interfere with or circumvent the security features of the
              Service or any related website, other websites, or the Internet.
            </p>
            <p>
              We reserve the right to terminate your use of the Service or any
              related website for violating any of the prohibited uses.
            </p>

            <p>
              Peteye reserves the right - in the event of violations of this
              agreement or parts of this agreement, to:
            </p>
            <p>(a) warn the user,</p>
            <p>(b) delete part or all of the content provided by the user,</p>
            <p>(c) to temporarily or permanently block the user and/or</p>
            <p>
              (d) dissolve the contractual relationship prematurely and delete
              the user’s profile.
            </p>
            <h4>d. Right to Remove or Screen Your Content</h4>
            <p>
              Though we are not obligated to do so, we reserve the right to
              monitor, screen, edit and/or remove Your Content on the Peteye
              Service. Our enforcement of these Terms concerning Your Content is
              at our discretion, and failure to enforce the Terms in one
              instance does not create a waiver of our right to enforce them in
              another instance. We have no obligation to retain or provide you
              with copies of Your Content, nor will we have any liability to you
              for any deletion, disclosure, loss or modification to Your
              Content. It is solely your responsibility to maintain backup
              copies of Your Content.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 12: Governing Law and Dispute Resolution
            </h3>
            <h4>
              a. Applicable laws and jurisdiction governing the terms and
              conditions.
            </h4>
            <p>
              The terms and conditions, as well as the use of the Peteye app,
              are governed by the laws of the jurisdiction where Peteye is
              registered or operates its principal place of business. Any
              disputes arising from or in connection with the app or the terms
              and conditions shall be subject to the exclusive jurisdiction of
              the courts in that jurisdiction.
            </p>
            <h4>
              b. Procedures For Dispute Resolution, Such As Arbitration Or
              Mediation.
            </h4>
            <p>
              In the event of any dispute or claim arising from or relating to
              the use of the app or the interpretation and application of the
              terms and conditions, Peteye and the user agree to first attempt
              to resolve the matter amicably through good-faith negotiations or
              alternative dispute resolution methods, such as mediation or
              arbitration.
            </p>
            <p>
              <strong>i. Mediation:</strong> If a dispute arises, the parties
              may agree to engage in mediation, whereby a neutral third party
              facilitates discussions to help the parties reach a mutually
              satisfactory resolution. The mediation process will be conducted
              in accordance with the agreed-upon rules and procedures.
            </p>
            <p>
              Any Dispute commenced under this Clause shall be resolved through
              Mediation to be conducted by a Mediator, to be appointed by the
              parties in accordance with the Mediation Act. The seat of
              arbitration shall be Telangana.
            </p>
            <p>
              <strong>ii. Arbitration:</strong>If mediation is unsuccessful or
              not agreed upon, any unresolved dispute shall be referred to
              binding arbitration. The arbitration process will be conducted in
              accordance with the rules and procedures of a mutually agreed-upon
              arbitration provider. The decision of the arbitrator(s) shall be
              final and binding on both Peteye and the user, and any court
              proceedings shall be limited to enforcing the arbitration award.
            </p>
            <p>
              The above procedures for dispute resolution are designed to
              provide an efficient and cost-effective means of resolving
              disputes. By using the Peteye app, users agree to participate in
              good faith in any dispute resolution process initiated by Peteye.
            </p>
            <p>
              It is important to note that these dispute resolution provisions
              do not limit the right of either party to seek interim or
              preliminary relief from a court of competent jurisdiction if
              necessary to protect its rights or interests. Additionally, any
              claims arising from intellectual property rights infringement or
              unauthorized use of the app's content may be subject to legal
              remedies without prior resorting to mediation or arbitration.
            </p>
            <p>
              Users are encouraged to carefully read and understand the dispute
              resolution provisions, and if any disputes arise, they should
              reach out to Peteye's customer support for further guidance on the
              appropriate resolution process.
            </p>
            <p>
              Any Dispute commenced under this Clause shall be resolved through
              arbitration to be conducted by 3 (three) arbitrators, to be
              appointed by the parties in accordance with the Arbitration Act.
              The seat of arbitration shall be Telangana.
            </p>
          </div>
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 13: Modifications to Terms and Conditions
            </h3>
            <h4>a. Right To Modify or Update The Terms and Conditions</h4>
            <p>
              Peteye reserves the right to modify, update, or revise the terms
              and conditions at any time, without prior notice. The
              modifications may be made to reflect changes in app functionality,
              legal requirements, industry practices, or for any other valid
              reason determined by Peteye. Users are advised to review the terms
              and conditions periodically to stay informed about any updates.
            </p>
            <h4>
              b. Notification of Changes and User's Acceptance of Updated Terms
            </h4>
            <p>
              {" "}
              In the event of modifications to the terms and conditions, Peteye
              will make reasonable efforts to notify users of the changes. This
              may include sending a notification through the app, posting a
              notice on the Peteye website, or using any other means deemed
              appropriate by Peteye. The notification will specify the effective
              date of the updated terms and provide a summary of the changes
              made.
            </p>
            <p>
              Users are required to review the updated terms and conditions and
              indicate their acceptance by continuing to use the app. By using
              the app after the effective date of the modified terms, users
              signify their agreement and acceptance of the revised terms and
              conditions. If users do not agree with the updated terms, they
              must discontinue using the app and terminate their account, if
              applicable.{" "}
            </p>
            <p>
              It is the responsibility of the users to regularly check for
              updates to the terms and conditions and ensure their ongoing
              compliance. Failure to review or accept the updated terms does not
              absolve users from their obligations under the revised terms and
              conditions. The user's continued use of or access to the app
              following the posting of any changes constitutes acceptance of
              those changes.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 14 : Consideration and Payment Processing
            </h3>
            <h4>a. Modification To The Service And Prices</h4>
            <p>
              Prices for our products are subject to change without notice. We
              reserve the right at any time to modify or discontinue the Service
              (or any part or content thereof) without notice at any time. We
              shall not be liable to you or any third party for any
              modification, price change, suspension or discontinuance of the
              Service.
            </p>
            <h4>b. Payment Processing</h4>
            <p>
              For payment processing, we utilize the services of Razorpay
              Software Private Limited (“Razorpay”). Neither PET EYE nor
              Razorpay stores your card data on their servers. Payment
              information is encrypted in accordance with the Payment Card
              Industry Data Security Standard (PCI-DSS) during payment
              processing. We retain your purchase transaction data only as long
              as necessary to complete the transaction. Our payment gateway
              adheres to PCI-DSS standards, ensuring secure handling of credit
              card information. For detailed information, you can review the
              terms and conditions of RazorPay at{" "}
              <a href="https://razorpay.com/terms/" target="_blank">
                https://razorpay.com/terms/
              </a>
              .
            </p>
            <h4>c. Accuracy Of Billing And Account Information</h4>
            <p>
              We reserve the right to refuse any order you place with us. You
              agree to provide current, complete and accurate purchase and
              account information for all purchases. You agree to promptly
              update your account and other information, including your email
              address credit card numbers and expiration dates, so that we can
              complete your transactions and contact you as needed.
            </p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 15: Insurance
            </h3>
            <p>
              We are acting for and on behalf of the Insurer and arrange the
              policy with the Insurer on your behalf. You do not pay us a fee
              for doing this.
            </p>
            <h4>a. Intermediary</h4>
            <p>
              We are an insurance intermediary who does not provide personal
              recommendations about the insurance products we offer.
            </p>
            <h4>b. Commission</h4>
            <p>
              When we sell you a policy the insurer pays us a percentage
              commission from the total premium.
            </p>
            <h4>c. Handling Client and Insurer Money</h4>
            <p>We collect and hold money as agents of the insurer.</p>
          </div>

          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 16: Registration / Subscription Plan Contract
            </h3>
            <p>
              The online tracking requires both, the possession of a Peteye GPS
              tracker as well as the registration of the customer via the
              website and a subscription plan contract based on a variety of
              plan options shown at the website and app (hereinafter
              “subscription plan”).
            </p>
            <p>
              {" "}
              Peteye reserves the right, in the case of reasonable suspicion of
              misuse or misuse of purpose (in particular recording movement
              patterns of third persons), to block the customer's access to the
              online tracking temporarily or forever.
            </p>
            <p>
              The charges listed by Peteye for the respective subscription plan
              include, unless otherwise stated, any value-added tax applicable
              to the services.
            </p>
            <p>
              After registration or log-in and selection of the most applicable
              subscription plan (either by the customer or through Peteye in
              certain cases) by entering the serial number of the device, select
              the country of use of the GPS tracker and selection of the
              respective subscription plan - if necessary the payment interval
              and the payment method - in each case by clicking respective
              selection boxes or selection in a drop-down menu, the user will be
              provided with the pre-contractual information in a summarized
              form.
            </p>
            <p>
              The subscription automatically renews at the end of the
              subscription period. It will be extended by the billing interval
              that was selected by the customer. If the customer has already
              cancelled the subscription before the end of the subscription
              interval, or if the renewal payment fails, the subscription ends
              automatically.
            </p>
            <p>
              Peteye will confirm the receipt of the user's offer via e-mail.
              <br /> In the case of successful service activation, the
              acceptance of the contract takes place at the latest by granting
              unrestricted access to the services by Peteye.
            </p>
            <p>
              As part of the subscription plans offered, it is possible to
              secure GPS Trackers against damage, loss and theft.
            </p>
          </div>
          <div>
            <h3 className="h2 text-[clamp(1.2rem,1.5vw,8rem)] font-[Prata]">
              Section 17: Miscellaneous
            </h3>
            <h4>a. Severability Clause</h4>
            <p>
              If any provision of the terms and conditions is found to be
              invalid, illegal, or unenforceable by a court of competent
              jurisdiction, the remaining provisions shall continue to be valid
              and enforceable to the fullest extent permitted by law. The
              invalidity, illegality, or unenforceability of any provision shall
              not affect the validity or enforceability of the remaining
              provisions of the terms and conditions.
            </p>
            <h4>b. Entire Agreement Clause</h4>
            <p>
              The terms and conditions constitute the entire agreement between
              Peteye and the user regarding the use of the app and supersede any
              prior agreements, understandings, or representations, whether oral
              or written. Any additional or conflicting terms proposed by the
              user are expressly rejected unless agreed to in writing by Peteye.
            </p>
            <h4>c. Contact Information for Inquiries or Support</h4>
            <p>
              For inquiries, support, or any questions related to the app, users
              can contact Peteye using the following contact information:
            </p>

            <p>
              Email: <a href="mailto:Care@peteye.pet">Care@peteye.pet</a>
            </p>
            <p>Phone: [+919704456520]</p>
            <p>
              Mailing Address: 4th floor - 401, Arunodaya Apartments, Street 15,
              Himayath Nagar, Hyderabad, 500029, Telangana.
            </p>

            <p>
              Users are encouraged to reach out to Peteye's customer support for
              assistance, feedback, or any issues encountered while using the
              app. Peteye's customer support team will make reasonable efforts
              to respond to inquiries and provide necessary support within a
              reasonable timeframe.
            </p>
            <p>
              Users need to provide accurate and complete contact information
              when seeking support to ensure a prompt and efficient response.
            </p>
            <p>
              Please note that while Peteye strives to provide reliable and
              timely support, there may be circumstances where response times
              may vary and certain inquiries or issues may require additional
              time for resolution.{" "}
            </p>
            <h4>d. User Comments, Feedback And Other Submissions</h4>
            <p>
              If, at our request, you send certain specific submissions (for
              example contest entries) or without a request from us you send
              creative ideas, suggestions, proposals, plans, or other materials,
              whether online, by email, by postal mail, or otherwise
              (collectively, ‘comments’), you agree that we may, at any time,
              without restriction, edit, copy, publish, distribute, translate
              and otherwise use in any medium any comments that you forward to
              us. We are and shall be under no obligation <br /> (1) to maintain
              any comments in confidence; <br /> (2) to pay compensation for any
              comments, or (3) to respond to any comments.
            </p>
            <p>
              We may, but have no obligation to, monitor, edit or remove content
              that we determine in our sole discretion are unlawful, offensive,
              threatening, libellous, defamatory, pornographic, obscene or
              otherwise objectionable or violates any party’s intellectual
              property or these Terms of Service.
            </p>
            <p>
              You agree that your comments will not violate any right of any
              third party, including copyright, trademark, privacy, personality
              or other personal or proprietary right. You further agree that
              your comments will not contain libelous or otherwise unlawful,
              abusive or obscene material, or contain any computer virus or
              other malware that could in any way affect the operation of the
              Service or any related website. You may not use a false e-mail
              address, pretend to be someone other than yourself, or otherwise
              mislead us or third-parties as to the origin of any comments. You
              are solely responsible for any comments you make and their
              accuracy. We take no responsibility and assume no liability for
              any comments posted by you or any third-party.
            </p>
            <h4>e. Errors, Inaccuracies And Omissions</h4>
            <p>
              Occasionally there may be information on our app or in the Service
              that contains typographical errors, inaccuracies or omissions that
              may relate to product descriptions, pricing, promotions, offers,
              product shipping charges, transit times and availability. We
              reserve the right to correct any errors, inaccuracies or
              omissions, and to change or update information or cancel orders if
              any information in the Service or on any related website is
              inaccurate at any time without prior notice (including after you
              have submitted your order).
            </p>
            <p>
              We undertake no obligation to update, amend or clarify information
              in the Service or on any related website, including pricing
              information, except as required by law. No specified update or
              refresh date applied in the Service or on any related app should
              be taken to indicate that all information in the Service or on any
              related app has been modified or updated.
            </p>
            <h4>f. Force Majure Clause</h4>
            <p>
              Neither party will be liable for inadequate performance to the
              extent caused by a natural disaster, acts of war or terrorism,
              riot, labour condition, governmental action, and Internet
              disturbances including but not limited to any cause/event outside
              the reasonable control of parties.
            </p>
          </div>
        </div>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
}
