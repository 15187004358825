import "../Homepage/HomePageModal/HomePageModal.css";
import { FaBirthdayCake } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import { useState } from "react";
import axios from "../../axios";
import Cookies from "js-cookie";

// Helper function to format date to "DD, Month, YYYY"
const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options).replace(/, (\d{4})/, ", $1");
};

// Helper function to calculate age from birth date
const calculateAge = (birthDateString) => {
  const birthDate = new Date(birthDateString);
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs); // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const ConnectedPetModal = ({
  isOpen,
  isClose,
  className,
  connectedPet,
  openPetModal,
  updateConnectedPets,
}) => {
  const loginToken = Cookies.get("loginToken");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const trimDesc = (desc) => {
    if (typeof desc === "string") {
      return desc.length > 10 ? desc.slice(0, 10) + " .." : desc;
    } else if (Array.isArray(desc)) {
      return desc.map((item) =>
        item.length > 10 ? item.slice(0, 10) + " .." : item
      );
    } else {
      console.error(
        "Provided description is neither a string nor an array:",
        desc
      );
      return "";
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  // if (!connectedPet || connectedPet.length === 0) {
  //   alert("No Pet Connected");
  //   return null;
  // }

  const petInfo = connectedPet[0]?.petinfo; // Assuming you want to display the first pet
  const formattedConnectedDate = formatDate(connectedPet[0]?.connectedDate);
  const petAge = calculateAge(petInfo?.birthDate);

  const disconnectPet = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.patch(
        "/nfc/userpetdisconnect",
        { petId: connectedPet[0]?.petId },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        // Handle successful response
        openPetModal(false);
        updateConnectedPets();
      } else {
        // Handle error response
        setError(response?.data?.message || "Failed to disconnect pet");
      }
    } catch (error) {
      setError(error.response?.data?.message || "Failed to disconnect pet");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-background">
          <img
            src={
              petInfo?.petProfileImage ||
              "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+3(4).png"
            }
            alt={petInfo?.petName}
            className={`${className}`}
          />
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="modal-description">
          <div className={`pet-description ${className}`}>
            <div className="pet-name">
              <p>{petInfo?.petName}</p>
              <span>{trimDesc(petInfo?.petBreed)}</span>
            </div>
            <div className="pet-info">
              <p className="birthday">
                <span>
                  <FaBirthdayCake className="cake-icon" />
                </span>
                <span>{petAge} Years</span>
              </p>
              <p className="gender">
                <span>
                  <BsGenderAmbiguous className="gender-icon" />
                </span>
                <span>{petInfo?.sex}</span>
              </p>
            </div>
          </div>
          <div className="pet-address">
            <p>Last Seen: Himayathnagar | Blue Fox Hotel</p>
            <span>
              I’m Shiro, a playful, friendly Labrador. I love outdoor adventures
              and belly rubs
            </span>
          </div>
          <div className="nfc-div">
            <p>
              <DogPettingIcon name={"qr"} /> {connectedPet[0]?.qrcode}
            </p>
            <img
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/nfc/pettag.png"
              alt="pet tag"
            />
            <span>
              <DogPettingIcon name={"unlink"} /> {formattedConnectedDate}
            </span>
            <button onClick={disconnectPet} disabled={loading}>
              <DogPettingIcon name={"disconnect"} />{" "}
              {loading ? "Disconnecting..." : "Disconnect"}
            </button>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
