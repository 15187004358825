import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt, FaMap, FaSatellite } from "react-icons/fa";
import { useSelector } from "react-redux";

const Marker = () => (
  <div
    style={{
      color: "red",
      fontSize: "24px",
      transform: "translate(-50%, -50%)",
    }}
  >
    <FaMapMarkerAlt />
  </div>
);

export const Map = ({ switchToFound, setSwitchToFound }) => {
  const [mapType, setMapType] = useState("ROADMAP"); // Default to 'ROADMAP'
  const [error, setError] = useState(null);
  const { login } = useSelector((state) => state);

  const defaultProps = {
    center: {
      lat: 17.4065,
      lng: 78.4772,
    },
    zoom: 11,
  };

  const mapOptions = (maps) => {
    return {
      mapTypeId: maps.MapTypeId[mapType], // Use the state variable for mapType
      fullscreenControl: true,
      mapTypeControl: false, // Hide the default map type control
      streetViewControl: true,
    };
  };

  const handleToggle = () => {
    setMapType((prevType) =>
      prevType === "ROADMAP" ? "SATELLITE" : "ROADMAP"
    );
  };

  useEffect(() => {
    const handleError = (error) => {
      setError(error);
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  return (
    <div style={{ height: "100vh", width: "100%", position: "relative" }}>
      {error ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "20px",
            zIndex: 1,
          }}
        >
          <p className="error-message">
            There was an error loading the map. Please check your internet
            connection and try again.
          </p>
        </div>
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              top: "62vh",
              left: "10px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "5px",
              zIndex: 1, // Ensure the toggle is above the map
            }}
          >
            <button
              onClick={handleToggle}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                padding: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {mapType === "ROADMAP" ? (
                <FaMap style={{ fontSize: "24px", color: "#000" }} />
              ) : (
                <FaSatellite style={{ fontSize: "24px", color: "#000" }} />
              )}
            </button>
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAlBKS14oFQpY77xiIkd3PuPtPyqLTUYu8",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              options={mapOptions}
            >
              <Marker
                lat={defaultProps.center.lat}
                lng={defaultProps.center.lng}
              />
            </GoogleMapReact>
          </div>
          {!login.login && (
            <div className="absolute top-[2vh] left-1/2 transform -translate-x-1/2 flex items-center text-[clamp(1rem,1.2vw,6rem)] text-[Open Sans] px-[1.5vw] py-[.1vw] bg-white shadow-[0px_.1vw_.5vw_0px_rgba(0,0,0,0.15)] rounded-[.5vw] z-1">
              <p className="px-[1.5vw] py-[.1vw]">
                {switchToFound ? "Found Pets" : "Lost Pets"}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
