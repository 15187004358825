import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import "./PetOwnerDetails.css";
import axios from "../../../../../axios";
import Axios from "axios";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import SimpleBackdrop from "../../../../../Components/Backdrop/Backdrop";
import { OwnervalidateForm } from "./OwnerValidation";
import AlertBox from "../../../../../Components/Alert/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import RegHeader from "../RegHeader/RegHeader";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import Terms from "../Term&Con/Terms";

const location_img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/location_535239.png";

function PetOwnerDetails({ setopenPetInfo, setinfoCompleted }) {
  const { socialData, login } = useSelector((state) => state);
  const googleData = socialData?.googleData || "";
  const sigupData = login?.details || "";
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState({ Name: "" });
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [ownerComplete, setownerComplete] = useState(false);
  const [openOwner, setopenOwner] = useState(true);
  const [openTerms, setopenTerms] = useState(false);
  const [termsComplete, settermsComplete] = useState(false);

  console.log("signupData", sigupData);

  const [formData, setFormData] = useState({
    firstName:
      googleData?.given_name ||
      sigupData?.userInfo?.firstName ||
      googleData?.short_name ||
      "",
    Mobilenumber: sigupData?.userInfo?.mobile || "",
    lastName: googleData?.family_name || googleData?.last_name || "",
    SecondaryMobilenumber: "",
    State: "",
    City: "",
    Address2: "",
    Pincode: "",
    District: "",
    Area: "",
    HouseFloorNo: "",
  });

  // ********** Form Submission ************

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      let Nationality = nationality?.Name;
      let userInfo = { ...formData, Nationality };
      let formValidation = OwnervalidateForm(userInfo);
      if (formValidation == true) {
        dispatch({ type: "userInfo", payload: { userInfo: userInfo } });
        // setopenPetInfo(true);
        setownerComplete(true);
        setopenOwner(false);
        setopenTerms(true);
        // setinfoCompleted(true);
      } else {
        setopenAlert(!openAlert);
        setErrMessage(formValidation);
        return;
      }
    } catch (error) {
      console.log(error);
      setopenAlert(!openAlert);
      setErrMessage("error occured");
      return;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "SecondaryMobilenumber") {
      if (value === formData.Mobilenumber) {
        setErrMessage("Secondary mobile number cannot be the same as primary.");
        setopenAlert(true);
        return;
      }
      if (!/^\d*$/.test(value)) {
        // Ensures only numeric input
        setErrMessage("Only numbers are allowed for mobile numbers.");
        setopenAlert(true);
        return;
      }

      if (value.length > 10) {
        setErrMessage("Mobile number must be exactly 10 digits.");
        setopenAlert(true);
        return;
      }
    }
    if (name === "lastName") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        // Ensure only alphabets
        setErrMessage("Last name can only contain alphabets.");
        setopenAlert(true);
        return;
      }

      if (value.length > 15) {
        // Example character limit of 20
        setErrMessage("Last name cannot exceed 15 characters.");
        setopenAlert(true);
        return;
      }
    }

    if (name === "Address2" && /\d|[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      setErrMessage("Address cannot contain numbers or special characters.");
      setopenAlert(true);
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (
      name === "Pincode" &&
      value.length === 6 &&
      nationality?.Name === "India"
    ) {
      fetchPostalCode(value);
      setopenBackdrop(true);
    }
    if (name === "Pincode") {
      if (value.length > 6) {
        setErrMessage("Pincode must be exactly 6 digits.");
        setopenAlert(true);
        return;
      }
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = () => {
    axios
      .get("/user/country")
      .then((response) => {
        const countriesData = response.data.message;
        const indiaIndex = countriesData.findIndex(
          (country) => country.Name === "India"
        );
        if (indiaIndex !== -1) {
          const updatedCountries = [
            countriesData[indiaIndex],
            ...countriesData.slice(0, indiaIndex),
            ...countriesData.slice(indiaIndex + 1),
          ];
          setCountries(updatedCountries);
        } else {
          setCountries(countriesData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPostalCode = (code) => {
    Axios.get(`https://api.postalpincode.in/pincode/${code}`)
      .then((response) => {
        console.log(response.data[0]?.PostOffice[0]);
        const postalData = response?.data[0]?.PostOffice[0];
        setFormData((prevData) => ({
          ...prevData,
          City: postalData?.District || "",
          State: postalData?.State || "",
          District: postalData?.District || "",
          Area: postalData?.Name || "",
          Pincode: code,
        }));
        setopenBackdrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex  flex-col justify-center  relative overflow-hidden ">
      {!openTerms && (
        <>
          <div className=" h-[12vh] mb-[1vh] shadow-[0px_4.171px_12.618px_0px_rgba(0,0,0,0.25)] flex items-center justify-center w-full ">
            <div className="flex items-center justify-start gap-[1.5vw]  w-[80%] ">
              <div
                className={`${
                  ownerComplete
                    ? "bg-[#F5F5F5] px-[.8vw] py-[1vw] rounded-[20%]"
                    : openOwner
                    ? "bg-[#F5F5F5] px-[.8vw] py-[1vw] rounded-[20%] "
                    : "bg-[#F5F5F5] px-[.8vw] py-[1vw] rounded-[20%]"
                }`}
              >
                <DogPettingIcon name={"petownerdetailsicon"} />
              </div>
              <p className="text-[clamp(1rem,1.2vw,6rem)] text-[Prata] font-[500]">
                Pet Owner Details
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="reg-body petowner">
              <input
                className="textfield-size-reg "
                id=""
                type="text"
                placeholder="First name"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                readOnly
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="Mobile number"
                variant="outlined"
                name="Mobilenumber"
                value={formData.Mobilenumber}
                onChange={handleInputChange}
                readOnly={sigupData?.userInfo?.mobile ? true : false}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="Nick Name"
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="Secondary Mobile number &ensp;   &ensp;  &ensp; / Optional"
                variant="outlined"
                name="SecondaryMobilenumber"
                value={formData.SecondaryMobilenumber}
                onChange={handleInputChange}
              />

              <Autocomplete
                id="country-select-demo"
                sx={{ width: "25vw" }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.Name}
                renderOption={(props, option) => (
                  <Box
                    sx={{ fontSize: "clamp(0.8rem, 0.9vw, 2.2rem)" }}
                    component="li"
                    {...props}
                  >
                    {option.Name}
                  </Box>
                )}
                value={nationality}
                onChange={(e, newValue) => setNationality(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="nationality"
                    placeholder="Choose a country"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: "14px",
                        border: "none",
                        outline: "none",
                        padding: "0px",
                        margin: "0px",
                        display: "flex",
                        alignItems: "center",
                      },
                      disableUnderline: true,
                    }}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                      "& .MuiInputBase-root": {
                        "&:hover": {
                          "&:not(.Mui-disabled)": {
                            border: "none",
                          },
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                      },
                    }}
                  />
                )}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="Pincode"
                variant="outlined"
                name="Pincode"
                value={formData.Pincode}
                onChange={handleInputChange}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="Area"
                variant="outlined"
                name="Area"
                value={formData.Area}
                onChange={handleInputChange}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="City"
                variant="outlined"
                name="City"
                value={formData.City}
                onChange={handleInputChange}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="District"
                variant="outlined"
                name="District"
                value={formData.District}
                onChange={handleInputChange}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="State"
                variant="outlined"
                name="State"
                value={formData.State}
                onChange={handleInputChange}
              />
              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="Address"
                variant="outlined"
                name="Address2"
                value={formData.Address2}
                onChange={handleInputChange}
              />

              <input
                className="textfield-size-reg"
                id=""
                type="text"
                placeholder="House / Floor no"
                variant="outlined"
                name="HouseFloorNo"
                value={formData.HouseFloorNo}
                onChange={handleInputChange}
              />
            </div>
          </form>
          <div className="absolute top-[60vh] h-[12vh] bg-[linear-gradient(181deg,_rgba(255,255,255,0)_-1.77%,_rgba(255,255,255,0.6)_49.92%)] backdrop-blur-[3.8793px] flex items-center justify-center w-full ">
            <button
              onClick={handleSubmit}
              className="bg-black text-white px-[1.8vw] py-[.7vw] rounded-[1.5vw] flex items-center justify-center gap-[.5vw] font-[500] text-[clamp(1rem,1.2vw,6rem)]"
            >
              <DogPettingIcon name="petowner-nexticon" />
              Next
            </button>
          </div>
        </>
      )}
      {openBackdrop && (
        <SimpleBackdrop open={openBackdrop} setopenBackdrop={setopenBackdrop} />
      )}
      {openAlert && (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      )}
      {openTerms && <Terms settermsComplete={settermsComplete} />}
    </div>
  );
}

export default PetOwnerDetails;
