import axios from "axios";

const instance = axios.create({
  // baseURL:'http://localhost:2020/',
  // baseURL: "http://192.168.1.13:2020/",

  baseURL: " https://devbackendapi.peteye.pet/",
  withCredentials: true,
});

export default instance;
