import React from "react";
import { useNavigate } from "react-router-dom";
export default function SecondSection() {
  const navigate = useNavigate();
  const handlesignup = () => {
    navigate("/login");
  };
  return (
    <div className="w-full h-[93vh] md:h-[97vh] xl:h-[100vh]  flex flex-col items-center justify-center xl:justify-end bg-black text-white">
      <div className=" flex flex-col items-center justify-center gap-[4vw] md:gap-[2vw] xl::gap-[.5vw] h-[60vh] xl:h-[50vh] ">
        <h2 className=" text-[clamp(1.4rem,3vw,7rem)] md:text-[clamp(1.5rem,4.5vw,7rem)] xl:text-[clamp(1.5rem,3vw,7rem)] font-['Prata'] text-center">
          PetEYE is More Than Just a Brand
        </h2>
        <p className="text-[clamp(.8rem,1.5vw,4.5rem)] md:text-[clamp(1rem,2vw,4.5rem)] xl:text-[clamp(1rem,1.5vw,4.5rem)] font-['Open Sans'] text-center">
          We’re a Lifeline for Pets and Their Families
        </p>
        <button
          onClick={handlesignup}
          className="text-[clamp(.8rem,1vw,2.5rem)] md:text-[clamp(1rem,1.3vw,4.5rem)] border-none  font-['Poppins'] px-[4vw] py-[1.2vw] md:px-[2vw] md:py-[.8vw] bg-white text-black  rounded-[4vw] md:rounded-[2vw] "
        >
          Sign-up Now
        </button>
      </div>
      <div className="flex justify-flex-end items-end h-[30vh] xl:h-[50vh] ">
        <img
          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Servicewebp/Services+1.webp"
          alt="Services"
          title="Services"
          className="w-[100vw]  xl:h-[100%]  md:w-[95vw] xl:w-[80vw] object-fill   xl:object-cover"
        />
      </div>
    </div>
  );
}
