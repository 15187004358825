export const OwnervalidateForm = (formData) => {
    let message = '';
    console.log(formData,"##33");
  
    if (!formData.firstName.trim()) {
     return message = 'First name is required';
    }
    if (!formData.Nationality.trim()) {
        return message = 'Nationality is required';
       }
    if (!formData.State.trim()) {
        return message = 'State is required';
    }
    if (!formData.District.trim()) {
        return message = 'District is required';
       }
    if (!formData.City.trim()) {
        return message = 'City is required';
    }
    if (!formData.Area.trim()) {
        return message = 'Area is required';
       }
 
    if (!formData.HouseFloorNo.trim()) {
        return message = 'House / FloorNo is required';
       }
  
    if (!formData.Mobilenumber.trim() || formData.Mobilenumber.length !== 10 || !/^\d+$/.test(formData.Mobilenumber)) {
        return message = 'Enter a valid 10-digit mobile number';
    }
  
    if (!formData.Pincode.trim() || formData.Pincode.length !== 6 || !/^\d+$/.test(formData.Pincode)) {
      return message = 'Enter a valid 6-digit pincode';
    }
  
    return true ;
  };
  