import React, { useState, useRef } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./Notification.css";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";

export default function Notification({ isOpen, isClose }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };
  return (
    <div>
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className={isOpen ? "modal-container" : "modal-container close"}>
          <div className="notification-picker">
            <div className="notification-picker-title">
              <DogPettingIcon name={"bell"} />
              <h2>Notifications</h2>
              <div className="modal-close" onClick={isClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
