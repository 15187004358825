export const petInfoValidateForm = (formData) => {
  let message = "";

  if (!formData.petName.trim()) {
    return (message = "Pet name is required");
  }
  if (!formData.petType.trim()) {
    return (message = "Pet type is required");
  }
  if (!formData.petBreed.trim()) {
    return (message = "Pet breed is required");
  }
  if (!formData.birthDate) {
    return (message = "Birth date is required");
  }
  if (!formData.petWeight.trim()) {
    return (message = "Pet weight is required");
  }
  if (!formData.sex.trim()) {
    return (message = "Pet gender is required");
  }
  if (!formData.Vaccination.length && formData.nonvaccination == "") {
    return (message = "choose date for vaccination");
  }

  return true;
};
