import React, { useState, useEffect } from "react";
import "./Product.css";
import DogPettingIcon from "../../Pages/Homepage/DogPettingIcon";
import axios from "../../axios";
import Cookies from "js-cookie";
import ShopGif from "../components/ShopGif/ShopGifs";
import { useDispatch, useSelector } from "react-redux";
import VaccinePet from "./VaccinePet";

export default function Product({ isOpen, isClose, product }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeVariantIndex, setActiveVariantIndex] = useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [addToCartError, setAddToCartError] = useState(null);
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [selectPetForVaccine, setSelectPetForVaccine] = useState(false);
  const { login } = useSelector((state) => state);
  let petInfo;
  {
    login?.login && (petInfo = login.details.petInfo);
  }
  // const petInfo = login.details.petInfo;
  const loginToken = Cookies.get("loginToken");
  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });
  const [wishMarkList, setWishMarkList] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchWishlistStatus(product.id);
  }, [product.id]);

  const handleWishlistToggle = () => {
    if (isInWishlist) {
      removeFromWishlist(product.id);
    } else {
      addToWishlist(product.id, product.Category);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % (product?.productImages?.length || 1)
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [product]);

  const updateProductWishlistStatus = (productId, isInWishlist) => {
    // setFilteredProducts((prevProducts) =>
    //   prevProducts.map((product) =>
    //     product.id === productId ? { ...product, isInWishlist } : product
    //   )
    // );
    console.log("Updated product wishlist status:", isInWishlist);
  };

  const fetchWishlistStatus = async (productId) => {
    try {
      const response = await axios.get("/shop/wishlistproducts", {
        headers: { Authorization: loginToken },
      });

      const wishlist = response.data.message || [];
      setWishMarkList(response.data.message);

      // Log the entire wishlist for debugging
      console.log("Wishlist items:", wishlist);

      const isProductInWishlist = wishlist.some((item) => {
        // Log each item while checking the condition
        console.log("Checking item:", item);
        return item.Products.id === productId;
      });

      console.log(productId, "productId");
      console.log(isProductInWishlist, "isProductInWishlist");
      setIsInWishlist(isProductInWishlist);
      dispatch({ type: "refresh" });
      updateProductWishlistStatus(productId, isProductInWishlist);
      return isProductInWishlist;
    } catch (error) {
      console.error("Error fetching wishlist status:", error);
      return false;
    }
  };

  const addToWishlist = async (productId, category) => {
    try {
      const response = await axios.post(
        "/shop/addtowishlist",
        {
          ProductId: productId,
          ProductCategory: category,
        },
        {
          headers: { Authorization: loginToken },
        }
      );

      if (response.data.status) {
        setIsInWishlist(true);
        updateProductWishlistStatus(productId, true);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-wishlist.png",
          message: "Added to wishlist Successfully!",
        });
        setShowSuccessGif(true);
        dispatch({ type: "refresh" });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      const response = await axios.delete(
        `/shop/removewishlistpdt/${productId}`,
        {
          headers: { Authorization: loginToken },
        }
      );

      if (response.data.status) {
        setIsInWishlist(false);
        updateProductWishlistStatus(productId, false);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
          message: "Removed from wishlist Successfully!",
        });
        setShowSuccessGif(true);
        dispatch({ type: "refresh" });
        console.log(response.data);
        console.log("Product removed from wishlist:", productId);
      }
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  if (!isOpen || !product) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };
  const handlePetVaccine = () => {
    setSelectPetForVaccine((prev) => !prev);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleAddToCart = async () => {
    setIsAddingToCart(true);
    setAddToCartError(null);

    try {
      const response = await axios.post(
        "/shop/addtocart",
        {
          quantity: quantity,
          ProductId: product.id,
          variantId: product.productvariants[activeVariantIndex].id,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      if (response.data.status === 200) {
        // Show success GIF for newly added product
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif",
          message: "Added to Cartedd Successfully!",
        });
      } else if (response.data.status === 500) {
        // Show different GIF for already-in-cart message
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp",
          message: "Product is already in cart.",
        });
      }
      dispatch({ type: "refresh" });
      console.log("Product added to cart:", response.data.status);
      // Show the GIF after setting details based on the response
      setShowSuccessGif(true);
    } catch (error) {
      console.error("Error adding product to cart:", error);
      setAddToCartError("Failed to add product to cart. Please try again.");
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleGifClose = () => {
    setShowSuccessGif(false);
  };

  const images =
    product.productImages && product.productImages.length > 0
      ? product.productImages.map((img) => ({
          src: img.url,
          alt: product.ProductName,
        }))
      : [{ src: "", alt: product.ProductName }];

  const activeVariant = product.productvariants[activeVariantIndex];
  const oldPrice = parseFloat(activeVariant.price);
  const offerPercentage = parseFloat(activeVariant.OfferPercentage);
  const newPrice = oldPrice - (oldPrice * offerPercentage) / 100;

  return (
    <div>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          {showSuccessGif && (
            <ShopGif
              onClose={handleGifClose}
              message={gifDetails.message}
              gif={gifDetails.gif}
            />
          )}

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="shop-productmodal">
            <h3>{product.ProductName}</h3>
            <div className="shop-productmodal-image">
              <div className="shop-image-container">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className={`shop-carousel-image ${
                      index === currentIndex ? "active" : ""
                    }`}
                  />
                ))}
                <div className="product-card-off">
                  <p className="product-card-off-amount">{offerPercentage}%</p>
                  <p className="product-card-off-text">Off</p>
                </div>
              </div>

              <div className="shop-dot-container">
                {images.map((_, index) => (
                  <button
                    key={index}
                    className={`shop-dot ${
                      index === currentIndex ? "active" : ""
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  />
                ))}
              </div>
              <div className="shop-productmodal-price">
                {product.productvariants.map((variant, index) => (
                  <div
                    key={index}
                    className={`product-item ${
                      activeVariantIndex === index ? "active" : "inactive"
                    }`}
                    onClick={() => setActiveVariantIndex(index)}
                  >
                    <h5>{variant.weight}</h5>
                    <p>&#8377;{variant.price}</p>
                  </div>
                ))}
              </div>
              <div>
                <p className="shop-productmodal-details">Details</p>
                <div className="shop-productmodal-details-line"></div>
                <div className="shop-product-details">
                  <h6>Description</h6>
                  <p>{product.ProductDetails}</p>
                </div>
                {product.additionalDetails &&
                  product.additionalDetails.map((detail, index) => (
                    <div key={index} className="shop-product-details">
                      <h6>{detail.title}</h6>
                      <p>{detail.content}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="shop-product-price">
            <div className="shop-product-price-details">
              <h4>&#8377;{oldPrice.toFixed(2)}</h4>
              <span>&#8377;{newPrice.toFixed(2)}</span>
            </div>
            <div className="shop-product-buybutton">
              <div
                className="shop-product-buybutton-wish"
                onClick={handleWishlistToggle}
              >
                <DogPettingIcon
                  name={
                    isInWishlist
                      ? "shop-wishlisticon-checked"
                      : "shop-wishlisticon-unchecked"
                  }
                />
              </div>
              {product.Category == "Vaccine" ||
              (product.Category == "Deworm" && petInfo.length > 0) ? (
                <div
                  className="shop-product-buybutton-add2cart cursor-pointer"
                  onClick={handlePetVaccine}
                >
                  <DogPettingIcon name={"shop-addcart"} /> &nbsp; Add
                </div>
              ) : (
                <div
                  className="shop-product-buybutton-add2cart"
                  onClick={handleAddToCart}
                  style={{ cursor: isAddingToCart ? "not-allowed" : "pointer" }}
                >
                  <DogPettingIcon name={"shop-addcart"} /> &nbsp; Add
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {selectPetForVaccine && (
        <VaccinePet
          isOpen={selectPetForVaccine}
          isClose={handlePetVaccine}
          product={product}
        />
      )}
    </div>
  );
}
