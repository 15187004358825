import React, { useState, useEffect } from "react";
import DogPettingIcon from "../../../../Pages/Homepage/DogPettingIcon";
import "./Checkout.css";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { useShop } from "../../ShopContext";
import CartItems from "../CartItems";
import PharmacyItems from "../PharmacyItems";
import Address from "./Address";
import { useSelector } from "react-redux";
import ShopGif from "../../ShopGif/ShopGifs";
import { useNavigate } from "react-router-dom";

export default function Checkout({ isOpen, isClose }) {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isClosing1, setIsClosing1] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const {
    ActiveTab,
    handleActiveTab,
    calculateCartTotal,
    calculatePharmacyCartTotal,
    totalDiscount,
    cartTotal,
    pharmacyCartTotal,
    pharmacyCartData,
    paraVetChecked,
    cartData,
  } = useShop();
  console.log(ActiveTab, "active tab");
  const loginToken = Cookies.get("loginToken");
  const [errMessage, setErrMessage] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState({ Name: "" });
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [openAlert, setopenAlert] = useState(false);
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });
  const [open, setOpen] = useState(true);
  const [openOrderSuccess, setopenOrderSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openAuth, setopenAuth] = useState(false);
  const [total, setTotal] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [error, setError] = useState("");

  console.log(Location, "location");
  const [newAddress, setNewAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    secondaryPhone: "",
    pincode: "",
    nationality: "",
    state: "",
    district: "",
    city: "",
    houseFloorNo: "",
    area: "",
  });
  const categories = Object.keys(pharmacyCartData).filter((category) =>
    pharmacyCartData[category]?.map((item) => item)
  );
  console.log(categories, "pharmacy category");

  const [paraVetAmount, setParaVetAmount] = useState(0);
  const petSelectionDetails = JSON.parse(
    localStorage.getItem("petSelectionDetails")
  );
  const selectedPetIds = petSelectionDetails.map((item) => item);
  console.log(selectedPetIds, "petSelectionDetails");

  useEffect(() => {
    fetchAddresses();
  }, [loginToken]);

  useEffect(() => {
    loadRazorpayScript();
  }, []);
  useEffect(() => {
    fetchParavetPricing(categories, petSelectionDetails);
  }, []);
  const handleGifClose = () => {
    setShowSuccessGif(false);
  };

  const getPrescriptionFileData = () => {
    const storedData = localStorage.getItem("PrescriptionFile");
    if (!storedData) return null;
    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error("Error parsing stored file data:", error);
      return null;
    }
  };

  // Example usage:
  const prescriptionFileData = getPrescriptionFileData();
  console.log(prescriptionFileData, "prescriptionFileData");

  const fetchParavetPricing = async (categories, petSelectionDetails) => {
    try {
      // Get the serviceType and transform it if needed
      let serviceType = categories[0];
      if (serviceType === "Vaccine") {
        serviceType = "Vaccination";
      } else if (serviceType === "Deworm") {
        serviceType = "Deworming";
      }
      console.log(serviceType, "service type");

      const petType = petSelectionDetails[0].petType;
      console.log(petType, "pet type");

      // Only proceed if we have both values
      if (!serviceType || !petType) {
        console.warn("Missing serviceType or petType");
        return;
      }
      const response = await axios.get(
        `/shop/paravetpricing?petType=${petType}&serviceType=${serviceType}`,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      setParaVetAmount(response.data);
      console.log(response.data, "paravet amount");
    } catch (err) {
      Error(err.message);
    }
  };

  //for both vaccine and deworm functionality
  // const fetchParavetPricing = async (categories, selectedPetIds) => {
  //   try {
  //     // Transform and handle multiple service types
  //     const serviceTypes = categories.map((type) => {
  //       if (type === "Vaccine") {
  //         return "Vaccination";
  //       } else if (type === "Deworm") {
  //         return "Deworming";
  //       }
  //       return type;
  //     });
  //     console.log(serviceTypes, "service types");

  //     // Get petType from selectedPetIds
  //     const petType = selectedPetIds[0]?.petType;
  //     console.log(petType, "pet type");

  //     // Only proceed if we have values
  //     if (!serviceTypes.length || !petType) {
  //       console.warn("Missing serviceType or petType");
  //       return;
  //     }

  //     // Make parallel requests for each service type
  //     const pricePromises = serviceTypes.map((serviceType) => {
  //       return axios.get(
  //         `/shop/paravetpricing?petType=${petType}&serviceType=${serviceType}`,
  //         {
  //           headers: {
  //             Authorization: loginToken,
  //           },
  //         }
  //       );
  //     });

  //     // Wait for all requests to complete
  //     const responses = await Promise.all(pricePromises);

  //     // Combine all pricing data
  //     const allPricing = responses.map((response, index) => ({
  //       serviceType: serviceTypes[index],
  //       ...response.data,
  //     }));

  //     setParaVetAmount(allPricing);
  //     console.log(allPricing, "paravet amounts");
  //   } catch (err) {
  //     Error(err.message);
  //   }
  // };

  const loadRazorpayScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = handleRazorpayScriptLoad;
    document.body.appendChild(script);
  };

  const fetchAddresses = async () => {
    try {
      const response = await axios.get("/shop/getaddress", {
        headers: {
          Authorization: loginToken,
        },
      });
      setAddresses(response.data.message);
      console.log("Addresses:", response.data.message);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRazorpayScriptLoad = () => {};

  console.log(paraVetChecked, "paraVetChecked");

  const finalCartTotal = (cartTotal * 1.18 + 100 - totalDiscount).toFixed(2);

  const finalPharmacyCartTotal = (
    pharmacyCartTotal * 1.18 +
    100 -
    totalDiscount +
    (paraVetChecked && paraVetAmount?.data?.price
      ? paraVetAmount.data.price
      : 0)
  ).toFixed(2);

  // ORDER CREATION FOR PAYMENT
  const amount = ActiveTab === "cart" ? finalCartTotal : finalPharmacyCartTotal;
  const uploadImageToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      console.log("File uploaded to S3:", response.data);

      return response.data.Message ? response.data.Message.getPeteye : "";
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handlePayment = async () => {
    if (!addressId) {
      setError("Add address for shipment");
      setTimeout(() => setError(""), 3000);
      return;
    }

    // Check for prescription file
    const prescriptionFileData = getPrescriptionFileData();

    if (prescriptionFileData) {
      // Handle case with prescription file
      try {
        // Convert base64 to File object
        const base64Response = await fetch(prescriptionFileData.dataUrl);
        const blob = await base64Response.blob();
        const file = new File([blob], prescriptionFileData.fileName, {
          type: prescriptionFileData.fileType,
        });

        // Upload to S3
        const prescriptionUrl = await uploadImageToS3(file);

        if (prescriptionUrl) {
          // Add prescription details to API
          await axios.post(
            "/shop/addprescription",
            {
              prescription: prescriptionUrl,
              fileType: prescriptionFileData.fileType.split("/")[1],
              selectedPetIds: [],
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          );
        }
      } catch (error) {
        console.error("Error handling prescription:", error);
        // Continue with payment even if prescription handling fails
      }
    }

    // Proceed with normal payment flow
    axios
      .post(
        "/razerpay/ordercreate",
        {
          amount: parseInt(amount),
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      )
      .then((response) => {
        if (response?.data?.status == "200") {
          const orderId = response.data?.message?.getresponse.id;
          const key = response.data?.message?.rzp_key;
          initiateRazorpayPayment(orderId, key);
        }
        console.log(response, "payment response");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Helper functions

  // INITIALIZATION OF RAZORPAY

  const initiateRazorpayPayment = (orderId, key) => {
    const options = {
      key: key,
      amount: amount * 100,
      currency: "INR",
      name: "petEYE",
      description: "Online Transaction",
      image:
        "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/image+16407.png",
      order_id: orderId,
      handler: async (response) => {
        try {
          await verifyPayment(response, orderId);
        } catch (error) {
          console.error(error);
          setopenAlert(!openAlert);
          setErrMessage("Payment verification failed");
        }
      },
      prefill: {
        name: addressId?.firstname,
        email: "",
        contact: addressId?.mobilenumber,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#262427",
      },
    };

    if (window.Razorpay) {
      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      console.error("Razorpay script is not loaded");
    }
  };

  // PAYMENT VERIFICATION

  const verifyPayment = async (payment) => {
    try {
      const response = await axios.post(
        "/razerpay/verifyorder",
        {
          razorpay_order_id: payment?.razorpay_order_id,
          razorpay_payment_id: payment?.razorpay_payment_id,
        },
        {
          headers: {
            Authorization: loginToken,
            razorpaysignature: payment?.razorpay_signature,
          },
        }
      );
      console.log(response);
      if (response.data.success == "200") {
        purchaseAdd(payment);
      } else {
        setopenAlert(!openAlert);
        setErrMessage("Payment failed");
      }
    } catch (error) {
      console.error(error);
      setopenAlert(!openAlert);
      setErrMessage("Payment verification failed");
    }
  };

  // const purchaseAdd = async (payment) => {
  //   let formData = {
  //     Productamount: productamount,
  //     Tax: taxAmount,
  //     quantity: quantity,
  //     productinfo: 0,
  //     overAllAmount: amount,
  //     razorpay_order_id: payment.razorpay_order_id,
  //     Signature: payment.razorpay_signature,
  //     razorpay_id: payment.razorpay_payment_id,
  //     ShippingAddress:
  //       addresses.firstname +
  //       " " +
  //       addresses.area +
  //       " " +
  //       addresses.city +
  //       " " +
  //       addresses.state +
  //       " " +
  //       addresses.pincode +
  //       " " +
  //       addresses.mobilenumber +
  //       " " +
  //       addresses.HouseFloorNo +
  //       " " +
  //       addresses.district,
  //     orderType: orderType,
  //     paravetSelected: paravet,
  //     selectedDate: selectedDate,
  //     timeslot: timeslot,
  //     variantId: variantId,
  //     productId: productId,
  //     overAllAmount: productoverallamt,
  //     PrescriptionId: "",
  //     selectedPetIds: selectedPetIds,
  //   };

  //   try {
  //     // First API call to add purchase
  //     const purchaseResponse = await axios.post(
  //       "/shop/purchaseadd",
  //       { ...formData },
  //       {
  //         headers: {
  //           Authorization: loginToken,
  //         },
  //       }
  //     );

  //     if (purchaseResponse.data.status === "200") {
  //       // If purchase is successful, remove from cart
  //       // Update UI only after both operations succeed
  //       setGifDetails({
  //         gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
  //         message: "Removed from wishlist Successfully!",
  //       });
  //       setShowSuccessGif(true);
  //       setopenOrderSuccess(true);
  //       isClose();
  //     }
  //   } catch (purchaseError) {
  //     console.error("Error processing purchase:", purchaseError);
  //     // Handle purchase error appropriately
  //   }
  // };

  const createProductInfo = (
    productIds,
    variantIds,
    productAmounts,
    quantities,
    taxes,
    overallAmounts
  ) => {
    return productIds.map((productId, index) => ({
      productid: productId,
      variantId: variantIds[index],
      Productamount: productAmounts[index],
      quantity: quantities[index],
      Tax: taxes[index],
      overAllAmount: overallAmounts[index],
    }));
  };

  const calculateTotals = (productinfo) => {
    return {
      totalQuantity: productinfo.reduce((sum, item) => sum + item.quantity, 0),
      totalTax: productinfo.reduce((sum, item) => sum + item.Tax, 0),
    };
  };
  const getCartData = (ActiveTab, cartData, pharmacyCartData) => {
    const sourceData = ActiveTab === "cart" ? cartData : pharmacyCartData;

    return {
      taxAmount: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.Products.Tax)
      ),
      productAmount: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.productvariant.price)
      ),
      quantity: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.quantity)
      ),
      variantId: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.variantId)
      ),
      productId: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.Products.id)
      ),
      productOverallAmount: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.productvariant.price * 1.18)
      ),
      productName: Object.values(sourceData).flatMap((array) =>
        array.map((item) => item.Products.ProductName)
      ),
    };
  };

  const purchaseAdd = async (payment) => {
    console.log("Payment Details:", payment);
    console.log(
      "Address Details:",
      addresses.map((item) => item)
    );

    const {
      taxAmount,
      productAmount,
      quantity,
      variantId,
      productId,
      productName,
      productOverallAmount,
    } = getCartData(ActiveTab, cartData, pharmacyCartData);

    // Log extracted data - now showing all items
    console.log("Extracted Cart Data:", {
      taxAmount,
      productAmount,
      quantity,
      variantId,
      productId,
      productOverallAmount,
    });

    // Create productinfo array with all items
    const productinfo = productId.map((id, index) => ({
      ProductName: String(productName[index]),
      productid: Number(productId[index]),
      variantId: Number(variantId[index]),
      Productamount: String(productAmount[index]),
      quantity: String(quantity[index]),
      Tax: Number(taxAmount[index]),
      overAllAmount: Number(productOverallAmount[index]),
    }));

    // Validate address details
    const requiredAddressFields = [
      "firstname",
      "area",
      "city",
      "state",
      "pincode",
      "mobilenumber",
      "HouseFloorNo",
      "district",
    ];
    const missingFields = addresses.flatMap((address) =>
      requiredAddressFields.filter((field) => !address[field])
    );

    if (missingFields.length > 0) {
      console.error("Missing address fields:", missingFields);
      throw new Error("Complete address information is required");
    }

    // Format shipping address
    const shippingAddress = addresses
      .map((address) =>
        [
          address.firstname,
          address.HouseFloorNo,
          address.area,
          address.district,
          address.city,
          address.state,
          address.pincode,
          address.mobilenumber,
        ]
          .filter(Boolean)
          .join(", ")
      )
      .join("; ");

    // Validate payment details
    if (
      !payment.razorpay_order_id ||
      !payment.razorpay_signature ||
      !payment.razorpay_payment_id
    ) {
      console.error("Missing payment details:", payment);
      throw new Error("Complete payment information is required");
    }

    const formData = {
      Productamount: productAmount.reduce(
        (sum, amount) => sum + Number(amount),
        0
      ),
      Tax: taxAmount.reduce((sum, tax) => sum + Number(tax), 0),
      quantity: quantity.reduce((sum, qty) => sum + Number(qty), 0),
      productinfo: productinfo.map((item) => ({
        ...item,
        Productamount: Number(item.Productamount),
        quantity: Number(item.quantity),
        overAllAmount: Number(item.overAllAmount),
      })),
      overAllAmount: amount,
      razorpay_order_id: payment.razorpay_order_id,
      Signature: payment.razorpay_signature,
      razorpay_id: payment.razorpay_payment_id,
      ShippingAddress: shippingAddress,
      orderType: ActiveTab === "cart" ? "General" : "Medical",
      paravetSelected:
        ActiveTab === "cart" ? "" : paraVetChecked ? "Yes" : "No",
      selectedDate:
        ActiveTab === "cart" ? "" : localStorage.getItem("selectedVaccineDate"),
      timeslot:
        ActiveTab === "cart"
          ? ""
          : localStorage.getItem("selectedVaccineTimeSlot"),
      PrescriptionId: ActiveTab === "cart" ? "" : "",
      selectedPetIds:
        ActiveTab === "cart"
          ? ""
          : petSelectionDetails.map((item) => ({
              petId: Number(item.petId),
              productId: Number(item.productId),
            })),
    };

    console.log("Final Request Payload:", JSON.stringify(formData, null, 2));

    try {
      const purchaseResponse = await axios.post("/shop/purchaseadd", formData, {
        headers: {
          Authorization: loginToken,
          "Content-Type": "application/json",
        },
      });

      if (purchaseResponse.data.status === 200) {
        console.log("Purchase successful:", purchaseResponse.data);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
          message: "Removed from wishlist Successfully!",
        });
        setShowSuccessGif(true);
        setopenOrderSuccess(true);
        ActiveTab === "pharmacy" && localStorage.clear();
        isClose();
        navigate("/store");
      } else {
        console.error("Purchase response not OK:", purchaseResponse.data);
      }
    } catch (error) {
      console.error("Purchase Error:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        requestData: formData,
      });
      throw error;
    }
  };

  const handleSelectAddress = (addressId) => {
    setSelectedAddressId(addressId);
    setAddressId(addressId);
    console.log("Selected Address ID:", addressId); // Save the selected address ID
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleClose1 = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setAddNewAddress((prev) => !prev);
      setIsEditing(null);
    }, 1000);
  };

  const handleAddNewAddress = () => {
    setAddNewAddress((prev) => !prev);
  };

  // Handle the edit button click
  const handleEditAddress = (index) => {
    if (!addNewAddress) {
      // Only set editing if address form isn't already open
      const selectedAddress = addresses[index];
      setIsEditing(selectedAddress.id);
      setAddNewAddress(true); // This will open the address form
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="checkout-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="shop-checkout-title">
            <DogPettingIcon name={"cart-checkout"} />
            <h2>Proceed to Check - Out</h2>
          </div>
        </div>
        <div className="checkout-delivery">
          <div className="checkout-delivery-details">
            <div>
              <h2>Delivery to</h2>
              <div className="delivery-details">
                {isLoading ? (
                  <p>Loading addresses...</p>
                ) : addresses.length > 0 ? (
                  addresses.map((address, index) => (
                    <div
                      key={address.id} // Assuming `address.id` is a unique identifier
                      className={`delivery-details-address ${
                        selectedAddressId === address.id ? "selected" : ""
                      }`}
                      onClick={() => handleSelectAddress(address.id)} // Select the address
                      style={{
                        border:
                          selectedAddressId === address.id
                            ? "2px solid black"
                            : "",
                      }}
                    >
                      <h4>{`${address.firstname} `}</h4>
                      <h5>{`${address.HouseFloorNo}, ${address.district}, ${address.city}, ${address.state}, ${address.pincode}`}</h5>
                      <p>Phone No. {address.mobilenumber}</p>
                      <div
                        className="delivery-details-edit"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent click from triggering select
                          handleEditAddress(index);
                        }}
                      >
                        <p>Edit</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      alignContent: "center",
                      fontSize: "clamp(.8rem, 1vw, 3.6rem)",
                    }}
                  >
                    Add a new address below.
                  </p>
                )}
                <div
                  className="delivery-details-addaddress "
                  onClick={() => setAddNewAddress((prev) => !prev)}
                >
                  <DogPettingIcon name="add-address" />
                  <h2>Add Address</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden ">
            {ActiveTab === "cart" && <CartItems />}
            {ActiveTab === "pharmacy" && <PharmacyItems />}
            <div className="p-[1vw]">
              <div className="shop-cart-ordersummary">
                <h3>Order Summary</h3>
                <div className="shop-cart-ordersummary-details">
                  <div className="shop-cart-ordersummary-list">
                    <h6>Subtotal</h6>
                    <p>
                      ₹{" "}
                      {(ActiveTab === "cart"
                        ? calculateCartTotal()
                        : calculatePharmacyCartTotal()
                      ).toFixed(2)}
                    </p>
                  </div>
                  {ActiveTab === "pharmacy" && paraVetChecked && (
                    <div className="shop-cart-ordersummary-list">
                      <h6>ParaVet</h6>
                      <p>₹ {paraVetAmount?.data?.price || 0}</p>
                    </div>
                  )}

                  <div className="shop-cart-ordersummary-list">
                    <h6>Discount</h6>
                    <p> - ₹ {totalDiscount.toFixed(2)}</p>
                  </div>
                  <div className="shop-cart-ordersummary-list">
                    <h6>GST</h6>
                    <p>
                      ₹{" "}
                      {(
                        (ActiveTab === "cart"
                          ? calculateCartTotal()
                          : calculatePharmacyCartTotal()) * 0.18
                      ).toFixed(2)}
                    </p>
                  </div>
                  <div className="shop-cart-ordersummary-list">
                    <h6>Shipping</h6>
                    <p>₹ 100</p>
                  </div>
                  <div className="shop-cart-line"></div>
                  <div className="shop-cart-ordersummary-list">
                    <h6>Total</h6>
                    <p>
                      ₹{" "}
                      {ActiveTab === "cart"
                        ? finalCartTotal
                        : finalPharmacyCartTotal}
                    </p>
                  </div>
                </div>
                <div className="shop-cart-ordersummary-list cart-total-savings">
                  <h6>Your total savings</h6>
                  <p>₹ {totalDiscount.toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shopcart-checkoutbuttons flex items-center gap-[.5vw] ">
          {error && <p className="error-message">{error}</p>}
          <button className="shop-cart-button" onClick={handlePayment}>
            {ActiveTab === "cart" ? (
              <>
                <DogPettingIcon name="check" />
                &ensp;₹ {finalCartTotal} Pay Now
              </>
            ) : (
              <>
                <DogPettingIcon name="check" />
                &ensp;₹ {finalPharmacyCartTotal} Pay Now
              </>
            )}
          </button>
        </div>
        {(addNewAddress || isEditing !== null) && (
          <Address
            isClosing={isClosing1}
            handleClose={handleClose1}
            isEditing={isEditing}
            initialAddress={
              isEditing !== null
                ? addresses.find((addr) => addr.id === isEditing)
                : null
            }
            loginToken={loginToken}
            setAddresses={setAddresses}
            addresses={addresses}
          />
        )}
        {showSuccessGif && (
          <ShopGif
            onClose={handleGifClose}
            message={gifDetails.message}
            gif={gifDetails.gif}
          />
        )}
      </div>
    </div>
  );
}
