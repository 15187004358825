import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import axios from "../../../../axios";
import { OTPInput } from "../../../Profile/Petprofile/Settings/OTPInput";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { ForgotChangePassword } from "./ForgotChangePassword";

export const EnterOTP = ({
  isOpen,
  isClose,
  contactType,
  contactValue,
  setOpenEnterOTP,
  setOpenChangePasswordSetting,
  userData,
}) => {
  const loginToken = Cookies.get("loginToken");
  const otpInputRef = useRef();

  const [otp, setOtp] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(90);
  const [resendAvailable, setResendAvailable] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);

  useEffect(() => {
    if (!resendAvailable) {
      const timer = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(timer);
            setResendAvailable(true);
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendAvailable]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    setError("");
  };

  const toggleChangePasswordModal = () => {
    setChangePasswordModal((prev) => !prev);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000);
  };

  const handleGetCodeClick = async () => {
    try {
      const payload = { email: contactValue };
      const response = await axios.post(
        "/auth/forget/sendmailandmobile",
        payload,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      if (response.data.message) {
        setResendTimer(90);
        setResendAvailable(false);
        setError("");
      } else {
        setError("Failed to resend OTP. Please try again.");
      }
    } catch (err) {
      console.error("Error resending OTP:", err);
      setError("An error occurred. Please try again later.");
    }
  };

  const handleVerifyOtp = async () => {
    if (!otpInputRef.current.validate()) {
      setError("Please enter a valid 6-digit OTP");
      return;
    }

    try {
      const payload = {
        email: contactValue,
        otp: otpInputRef.current.getOtp(),
      };

      const response = await axios.post(
        "/auth/forgetvalidate/verifyotp",
        payload,
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      console.log("Response:", response.data);
      if (response.data.status === 200) {
        toggleChangePasswordModal();
      } else {
        setError(response.data.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      if (error.response) {
        setError(
          error.response.data.message || "Invalid OTP. Please try again."
        );
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const getMessage = () => {
    return contactType === "mobile"
      ? "We've sent a 6-digit code to your mobile number"
      : "We've sent a 6-digit code to your email address";
  };

  const getContactDisplay = () => {
    return contactType === "mobile" ? `+91 ${contactValue}` : contactValue;
  };

  return (
    <>
      <div
        className={`modal-overlay2 ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container2 ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>Forgot Password</p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="get-code-about">
              <p>{getMessage()}</p>
            </div>
            <div className="code-mail-number">
              <p>{getContactDisplay()}</p>
            </div>
            <div className="otp-box">
              <div className="otp">
                <OTPInput
                  length={6}
                  onChange={handleOtpChange}
                  ref={otpInputRef}
                />
                <span>
                  Didn't receive any OTP?{" "}
                  {resendAvailable ? (
                    <b
                      onClick={handleGetCodeClick}
                      style={{ cursor: "pointer" }}
                    >
                      Resend Now
                    </b>
                  ) : (
                    <b>{`Resend in ${resendTimer}s`}</b>
                  )}
                </span>
                <div style={{ height: "10px" }}>
                  {error && (
                    <p className="error-message error-animation">{error}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-control" style={{ marginTop: "10px" }}>
              <button onClick={handleVerifyOtp}>
                <DogPettingIcon name={"accept"} />
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>

      {changePasswordModal && (
        <ForgotChangePassword
          isClose={toggleChangePasswordModal}
          isOpen={changePasswordModal}
          setOpenEnterOTP={setOpenEnterOTP}
          setOpenChangePasswordSetting={setOpenChangePasswordSetting}
          userData={{ email: contactValue, contactType }}
        />
      )}
    </>
  );
};
