import React from "react";
import "./Failedgif.css";
import { useEffect } from "react";

export default function Failedgif({ message }) {
  return (
    <div className="failed-gif">
      <img
        src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
        alt="failed"
      />
      <span>{message}</span>
    </div>
  );
}
