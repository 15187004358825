import React, { useState, useRef, useEffect } from "react";
import "./FilterModal.css";
import DogPettingIcon from "../Pages/Homepage/DogPettingIcon";
import { MdOutlineCheck } from "react-icons/md";

export default function Product({ isOpen, isClose }) {
  const [petType, setPetType] = useState(null); // No pre-selection
  const [sortBy, setSortBy] = useState(null); // No pre-selection
  const [foodType, setFoodType] = useState(null); // No pre-selection
  const [brand, setBrand] = useState(null); // No pre-selection
  const [clickedOption, setClickedOption] = useState(null); // Track the clicked option for animation
  const MAX_PRICE = 500000;
  const [priceRange, setPriceRange] = useState([0, MAX_PRICE]);
  const sliderRef = useRef(null);

  const formatPrice = (value) => {
    return value
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
      })
      .replace("₹", "Rs. ");
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  // Handle changes for both sliders (min and max)
  const handleSliderChange = (e, index) => {
    const newValue = Math.round(parseInt(e.target.value));
    const updatedRange = [...priceRange];

    if (index === 0 && newValue >= 0 && newValue <= priceRange[1]) {
      updatedRange[0] = newValue;
    } else if (
      index === 1 &&
      newValue <= MAX_PRICE &&
      newValue >= priceRange[0]
    ) {
      updatedRange[1] = newValue;
    }

    setPriceRange(updatedRange);
  };
  // New function to handle click on the slider container
  const handleSliderClick = (e) => {
    const sliderWidth = sliderRef.current.clientWidth;
    const clickX = e.clientX - sliderRef.current.getBoundingClientRect().left;
    const percentage = (clickX / sliderWidth) * MAX_PRICE;

    // Set the nearest slider value based on click position
    const newValue = Math.round(percentage);
    if (
      Math.abs(newValue - priceRange[0]) < Math.abs(newValue - priceRange[1])
    ) {
      setPriceRange([
        Math.max(0, Math.min(newValue, priceRange[1])),
        priceRange[1],
      ]);
    } else {
      setPriceRange([
        priceRange[0],
        Math.min(MAX_PRICE, Math.max(newValue, priceRange[0])),
      ]);
    }
  };

  // Update the background between the sliders based on the current values
  useEffect(() => {
    const minPercentage = (priceRange[0] / MAX_PRICE) * 100;
    const maxPercentage = (priceRange[1] / MAX_PRICE) * 100;

    if (sliderRef.current) {
      sliderRef.current.style.setProperty(
        "--min-percentage",
        `${minPercentage}%`
      );
      sliderRef.current.style.setProperty(
        "--max-percentage",
        `${maxPercentage}%`
      );
    }
  }, [priceRange]);

  const handleOptionClick = (setFunction, option) => {
    setFunction(option);
    setClickedOption(option); // Set the clicked option for animation
    setTimeout(() => setClickedOption(null), 500); // Reset after animation
  };

  const OptionWithTick = ({ isSelected, onClick, children }) => (
    <div
      className={`filter-option ${isSelected ? "selected" : ""} ${
        clickedOption === children ? "animate" : ""
      }`}
      onClick={onClick}
    >
      {children}
      {isSelected && (
        <span className="tick">
          <MdOutlineCheck />
        </span>
      )}
    </div>
  );

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className={isOpen ? "modal-container" : "modal-container close"}>
        <div className="modal-close" onClick={isClose}>
          <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
        </div>
        <div className="filter-modal-content p-[1vw]">
          <h2 className="filter-title">
            <DogPettingIcon name={"wishlist-filter"} /> Filter
          </h2>

          {/* Scrollable content starts here */}
          <div className="filter-scrollable-section ">
            {/* Pet Type Section */}
            <div className="filter-section">
              <h3 className="section-title">Pet</h3>
              <div className="filter-options">
                {["Dog", "Cat", "Other"].map((pet) => (
                  <OptionWithTick
                    key={pet}
                    isSelected={petType === pet}
                    onClick={() => handleOptionClick(setPetType, pet)}
                  >
                    {pet}
                  </OptionWithTick>
                ))}
              </div>
            </div>

            {/* Sort By Section */}
            <div className="filter-section">
              <h3 className="section-title">Sort By</h3>
              <div className="filter-options">
                {[
                  "Price : Low to High",
                  "Price : High to Low",
                  "Newest Arrival",
                  "Avg. Customer Review",
                ].map((option) => (
                  <OptionWithTick
                    key={option}
                    isSelected={sortBy === option}
                    onClick={() => handleOptionClick(setSortBy, option)}
                  >
                    {option}
                  </OptionWithTick>
                ))}
              </div>
            </div>

            {/* Price Range Section */}
            <div className="filter-section">
              <h3 className="section-title">Price Range</h3>
              <div className="price-range">
                <span>{formatPrice(priceRange[0])}</span>
                <span>{formatPrice(priceRange[1])}</span>
              </div>
              <div className="slider-container" ref={sliderRef}>
                <input
                  type="range"
                  min="0"
                  max={MAX_PRICE}
                  value={priceRange[0]}
                  onChange={(e) => handleSliderChange(e, 0)}
                  className="price-slider"
                  style={{ zIndex: 2 }}
                />
                <input
                  type="range"
                  min="0"
                  max={MAX_PRICE}
                  value={priceRange[1]}
                  onChange={(e) => handleSliderChange(e, 1)}
                  className="price-slider"
                  style={{ zIndex: 2 }}
                />
              </div>
            </div>
            {/* Food Type Section */}
            <div className="filter-section">
              <h3 className="section-title">Type</h3>
              <div className="filter-options">
                {["Dry Food", "Wet Food", "Lorem ipsum", "Lorem ipsum"].map(
                  (type) => (
                    <OptionWithTick
                      key={type}
                      isSelected={foodType === type}
                      onClick={() => handleOptionClick(setFoodType, type)}
                    >
                      {type}
                    </OptionWithTick>
                  )
                )}
              </div>
            </div>

            {/* Brand Section */}
            <div className="filter-section">
              <h3 className="section-title">Brand</h3>
              <div className="filter-options">
                {["Brand 1", "Brand 2", "Brand 3", "Brand 4"].map(
                  (brandName) => (
                    <OptionWithTick
                      key={brandName}
                      isSelected={brand === brandName}
                      onClick={() => handleOptionClick(setBrand, brandName)}
                    >
                      {brandName}
                    </OptionWithTick>
                  )
                )}
              </div>
            </div>
          </div>

          {/* Buttons at the bottom */}
          <div className="filter-buttons">
            <button
              onClick={() => {
                // Handle reset logic
              }}
              className="reset-button"
            >
              Reset
            </button>
            <button
              onClick={() => {
                // Handle apply logic
              }}
              className="apply-button"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
