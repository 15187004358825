import React, { useState, useEffect } from "react";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { DeleteConfirmation } from "./DeleteConfirmation";

export default function DeleteAccount({ isOpen, isClose }) {
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const loginToken = Cookies.get("loginToken");

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  const handleDeleteModal = () => {
    setDeleteConfirmation((prev) => !prev);
  };

  return (
    <div
      className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"delete"} /> Delete Account
            </p>
          </div>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="delete-account-container">
          <div className="delete-account-about">
            <h3>Delete Account</h3>
            <p>
              Deleting your account is a permanent action and cannot be undone.
              This will remove all your data and personal information from our
              system. If you did not mean to delete your account, please cancel
              this action. Otherwise, confirm to proceed with account deletion.
            </p>
          </div>
          <div className="delete-account-control">
            <button className="delete-btn" onClick={handleDeleteModal}>
              <DogPettingIcon name={"delete-btn"} />
              Delete Account
            </button>
          </div>
        </div>
        {deleteConfirmation && (
          <DeleteConfirmation
            isClose={handleDeleteModal}
            isOpen={deleteConfirmation}
          />
        )}
      </div>
    </div>
  );
}
