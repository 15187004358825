

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function AlertBox({open,setOpen,setopenAlert,errMessage}) {

  setTimeout(() => {
    handleClose()
 }, 4000);


  const handleClose = () => {
 
    setopenAlert(false)
    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         {errMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}