import React, { useEffect } from 'react'
import './Checkout.css'
import { CiCirclePlus } from "react-icons/ci";
import { RiCloseCircleFill } from 'react-icons/ri'
import { PiNumberCircleOneFill, PiNumberCircleThreeFill, PiNumberCircleTwoFill } from 'react-icons/pi';
import { FaIndianRupeeSign, FaMinus, FaPlus } from 'react-icons/fa6';
import { useState } from 'react';
import AddAddress from '../AddAddress/AddAddress';
import axios from '../../../../../axios';
import Cookies from 'js-cookie';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import credit from 'credit-card-type'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const loginToken = Cookies.get('loginToken');


function Checkout({cartItems,setAddressId}) {

  const [openAddAddress, setopenAddAddress] = useState(false);
  const [openupdateAddress, setopenupdateAddress] = useState(false);
  const [address, setaddress] = useState([]);
  const [editData, seteditData] = useState('');
  const [refresh, setrefresh]=useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [loading, setLoading] = useState(true); 
// const card=credit('6080332123456789')
// console.log("CREDIT CARD",card)


  useEffect(()=>{
  axios.get('/shop/getaddress',{
    headers: {
      Authorization:loginToken 
    }
  }).then((response)=>{
    console.log(response.data)
    if(response?.data?.status=='200'){
       setaddress(response.data.message)
    }
    if (response.data.message.length > 0) {
      setSelectedAddress(response.data.message[0].id);
      setAddressId(response.data.message[0])
    }
    setTimeout(() => {
      setLoading(false); // Data is loaded
    }, 1000);
  })
  },[openAddAddress,refresh,openupdateAddress])

  const handleDelete=(Id)=>{
    axios.delete(`/shop/deleteaddress/${Id}`,{
      headers: {
        Authorization:loginToken 
      }
    }).then((response)=>{
      console.log(response)
      if(response.data.status=='200'){
        setrefresh(!refresh)
      }
    }).catch((error)=>{
      console.log(error)
    })
  }
  const handleSelectAddress = (Id,item) => {
    setSelectedAddress(Id);
    setAddressId(item)
  };
  
  const renderAddress=()=>{
    return(
      <>
      {
                    address?.map((item)=>{
                        return(
                            <div
                            key={item.id}
                            className={`address-sub ${selectedAddress === item.id ? 'selected' : ''}`}
                            onClick={() => handleSelectAddress(item.id,item)}
                             >

                            <div>
                                <div style={{width:'100%', display:'flex',justifyContent:'space-between', }}>
                                <p>{item.firstname}</p>
                            <RiCloseCircleFill onClick={()=>handleDelete(item.id)} style={{fontSize:'22px' ,marginTop:'3px',marginRight:'4px', cursor:'pointer'}}/>
                                </div>
                                <p>{item.area}, {item.district}, {item.state}</p>
                                <p>{item.pincode}</p>
                                <div style={{width:'100%', display:'flex',justifyContent:'space-between', }}>
                                <p>{item.mobilenumber}</p>
                                 <p onClick={()=>{setopenupdateAddress(true); seteditData(item)}} style={{marginRight:'8px',cursor:'pointer'}} ><u>Edit</u></p>
                                </div>

                            </div>
                             </div> 
                        )
                    })
                }
     
      </>
    )
  }
  const renderAddAddress=()=>{
    return(
      <div className='add-address'>
      <CiCirclePlus onClick={()=>setopenAddAddress(true)} style={{fontSize:"25px", cursor:'pointer'}}/>
          <p onClick={()=>setopenAddAddress(true)}>Add Address</p>
      </div>
    )
  }
  const renderproducts=()=>{
    return(
      <>
       <div style={{width:'100%', display:'flex',alignItems:'center', flexDirection:'column'}}>
            <div style={{width:'96%'}}>
         {
         cartItems.map((item,index)=>{
            return(
        <div key={index}>
        <div className='checkout-pdt'>
        <div style={{display:'flex',justifyContent:'center'}}>
              <span>{index+1}</span>
              </div>
              <div style={{display:'flex',justifyContent:'center'}}>
              <img src={item?.Products?.productImagemodel?.[0]?.url} alt="" />
              </div>
              <div className='cart-item-section'>
              <span>{item.Products.Category}</span>
              <p>{item?.Products?.ProductName.split(" ").slice(0, 4).join(" ")}</p>
              {
                item.ProductCategory=='devices' ? "" :
              <p>{item?.unit} {item?.Products?.Category=='medicine'?'ml' :'KG'}</p>
              }
              </div>
              <div className='qty'>
              <FaMinus   style={{fontSize:'13px', cursor:'pointer'}} />
                <input className='qty-input' value={item.quantity}   type="text" readOnly />
                <FaPlus    style={{fontSize:'13px', cursor:'pointer'}}/>
              </div>
              <div className='food-total'>
              <span>Total</span>
              <p><FaIndianRupeeSign style={{paddingTop:'5px'}} />{item?.amount}</p>
              </div>
               
        </div>
        </div>
        
            )
          })
        }
        </div></div>
      </>
    )
  }
  const renderAddressLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={150} height={100} />
    </div>
  );
  const renderLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={650} height={80} />
    </div>
  );
  return (
    <div style={{width:'100%',display:'flex',flexDirection:'column',}}>
        <div className='address-main'>
        <PiNumberCircleOneFill style={{fontSize:'25px'}} />
      <p> Address</p>
        </div>
      <div className='address'>
        <div style={{display:'flex',width:'95%',gap:'10px'}}> 
         
            {
                address?.length!=0 ?
                <>
                {
                  loading ?
                  address.map(()=>{
                    return(
                      renderAddressLoadingSkeleton()
                    )
                  }):
                  renderAddress()
                }
                {
                  loading ?
                    renderAddressLoadingSkeleton()
                  :
                  renderAddAddress()
                }
                </>
                 
                : 
               <>
               {
                  loading ?
                    renderAddressLoadingSkeleton()
                  :
                  renderAddAddress()
                }
               </>
            }
        </div>
      </div>
      <div className='address-main'>
        <PiNumberCircleTwoFill style={{fontSize:'25px'}} />
      <p> Order Summary</p>
        </div>
 
              {
                loading?
                cartItems.map(()=>{
                  return(
                    renderLoadingSkeleton()
                  )
                }):
                renderproducts()
              }
         
        {/* <div className='address-main'>
        <PiNumberCircleThreeFill style={{fontSize:'25px'}} />
      <p> Payment Details</p>
        </div> */}
      {/* <div style={{display:'flex',justifyContent:'center'}}>
        <div style={{width:'94%'}}>
      <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={paymentMethod} 
        onChange={(event) => setPaymentMethod(event.target.value)}
      >
        <FormControlLabel    value="card" control={<Radio />} label="Credit / Debit card" />
        <FormControlLabel   value="UPI" control={<Radio />} label="UPI" />
      </RadioGroup>
    </FormControl>
        </div>
      </div> */}
        {
          openAddAddress?
          <AddAddress open={openAddAddress} handleClose={setopenAddAddress} editData={null} update={false} />
           : 
          ''
        }
         {
          openupdateAddress?
          <AddAddress open={openupdateAddress} handleClose={setopenupdateAddress} editData={editData} update={true} />
           : 
          ''
        }
    </div>
  )
}

export default Checkout
