import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  FaLinkedin,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";

export const InviteOthersModal = ({ isOpen, isClose }) => {
  const [copyText, setCopyText] = useState("Copy Link");
  const [showShareArea, setShowShareArea] = useState(false);
  const [isShareAreaClosing, setIsShareAreaClosing] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleCopyLink = () => {
    const linkToCopy = "https://example.com/invite-link"; // Replace with the actual link
    navigator.clipboard.writeText(linkToCopy).then(() => {
      setCopyText("Copied");
      setTimeout(() => {
        setCopyText("Copy Link");
      }, 2000); // Reset text after 2 seconds
    });
  };

  const handleShareProfile = () => {
    setShowShareArea((prev) => !prev);
  };

  const handleCloseShareArea = () => {
    setIsShareAreaClosing(true);
    setTimeout(() => {
      setShowShareArea(false);
      setIsShareAreaClosing(false);
    }, 300); // Match the duration of the closing animation
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  if (!isOpen) return null;

  return (
    <>
      <div
        className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
          {/* <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"owner-profile"} /> Invite
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="invite-others-div">
              <div className="user-name">
                <p>Vaibhav</p>
              </div>

              <div className="qr">
                <div className="qr-box">
                  <p className="big-qr">
                    <DogPettingIcon name={"big-qr"} />
                  </p>
                </div>
                <div className="owner-profile-box">
                  <p className="owner-profile">
                    <span>
                      <DogPettingIcon name={"owner-profile"} />
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="invite-control">
              <button className="copy-link-btn" onClick={handleCopyLink}>
                <DogPettingIcon name={"share-link"} />
                {copyText}
              </button>
              <button
                className="share-profile-btn"
                onClick={handleShareProfile}
              >
                <DogPettingIcon name={"share"} />
                Share Profile
              </button>
            </div>
            {showShareArea && (
              <div
                className={`share-area ${isShareAreaClosing ? "closing" : ""}`}
              >
                <div className="share-area-content">
                  <AiOutlineCloseCircle
                    className="close-share-area-icon"
                    onClick={handleCloseShareArea}
                  />
                  <FaXTwitter className="social-icon" />
                  <FaLinkedin className="social-icon" />
                  <FaWhatsapp className="social-icon" />
                  <FaFacebook className="social-icon" />
                  <FaInstagram className="social-icon" />
                </div>
              </div>
            )}
          </div> */}
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div
            className="modal-description"
            style={{
              margin: "auto",
            }}
          >
            <p
              style={{
                fontSize: "clamp(1rem, 1.5vw, 3.5rem)",
                fontWeight: "500",
              }}
            >
              Coming Soon...
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
