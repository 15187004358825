import React, { useState, useEffect } from "react";
import axios from "../axios";
import DogPettingIcon from "../Pages/Homepage/DogPettingIcon";
import "./Shop.css";
import { CiSearch } from "react-icons/ci";
import { ProductCard } from "./ProductCard";
import Cookies from "js-cookie";
import FilterModal from "./FilterModal"; // Import the modal component
import Product from "./components/Product";
import ProductActions from "./components/CartWishlist/ProductActions";
import NewHeader from "../Components/Header/NewHeader";
import WishlistItems from "./components/CartWishlist/WishlistItems";
import { useDispatch, useSelector } from "react-redux";
import ShopGif from "./components/ShopGif/ShopGifs";
import TrackOrder from "./components/TrackOrder/TrackOrder";
import DownloadAppScreen from "../Website/DownloadAppScreen/DownloadAppScreen";

function Shop() {
  const loginToken = Cookies.get("loginToken");
  const [activeLink, setActiveLink] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false); // State for modal visibility
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isWishlistModalOpen, setIsWishlistModalOpen] = useState(false);
  const [trackOrderModalOpen, setTrackOrderModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const shopImages = [
    {
      id: 1,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-bookmark.png",
      name: "remove-bookmar",
    },
    {
      id: 2,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-cart.png",
      name: "remove-cart.",
    },
    {
      id: 3,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/cart-already.png",
      name: "cart-already",
    },
    {
      id: 4,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
      name: "remove-wishlist",
    },
    {
      id: 5,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-wishlist.png",
      name: "add-wishlist",
    },
    {
      id: 6,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-cart.png",
      name: "add-cart",
    },
    {
      id: 7,
      img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-Bookmark.png",
      name: "add-Bookmark",
    },
  ];
  const [wishMarkList, setWishMarkList] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });

  const handleViewDetails = (product) => {
    setSelectedProduct(product);

    setIsProductModalOpen(true);
  };
  const handleTrackOrder = () => {
    setTrackOrderModalOpen((prevState) => !prevState);
  };

  const closeProductModal = () => {
    setIsProductModalOpen(false);
    setSelectedProduct(null);
  };

  const handleWishlist = () => {
    setIsWishlistModalOpen((prev) => !prev);
  };

  const handleNavClick = (linkName) => {
    if (linkName === "Filter") {
      // Toggle the filter modal when "Filter" is clicked
      setIsFilterModalOpen(true);
    } else {
      setActiveLink(linkName);
    }
  };

  const closeModal = () => {
    setIsFilterModalOpen(false);
  };

  const categoryMapping = {
    "Vaccine/Deworm": ["Deworm", "Vaccine"],
    Food: "Food",
    Medicine: "Medicine",
    Devices: "Devices",
    Accessories: "Accessories",
  };

  const navLinks = [
    { name: "All", icon: "wishlist-star" },
    { name: "Food", icon: "wishlist-food" },
    { name: "Vaccine/Deworm", icon: "wishlist-deworm" },
    { name: "Medicine", icon: "wishlist-medicine" },
    { name: "Devices", icon: "wishlist-device" },
    { name: "Accessories", icon: "wishlist-accessory" },
    { name: "Filter", icon: "wishlist-filter" },
  ];

  const fetchProducts = async () => {
    try {
      const response = await axios.get("/shop/searchproduct", {
        headers: {
          Authorization: loginToken,
        },
      });
      setAllProducts(response.data.message);
      setFilteredProducts(response.data.message);

      console.log("Products:", response.data.message);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    if (activeLink === "All") {
      setFilteredProducts(allProducts);
    } else {
      const backendCategory = categoryMapping[activeLink] || activeLink;
      const filtered = allProducts.filter((product) => {
        if (Array.isArray(backendCategory)) {
          return backendCategory.includes(product.Category);
        }
        return product.Category === backendCategory;
      });
      setFilteredProducts(filtered);
    }
  }, [activeLink, allProducts]);

  const handleSearch = async (e) => {
    setSearchQuery(e.target.value);
    try {
      const params = {
        ProductName: e.target.value,
        category: activeLink !== "All" ? activeLink : "",
        petType: "Dog",
        priceMin: "200",
        priceMax: "1000",
        type: "Dry",
        brand: "Pedigree",
        sort: "price_asc",
      };

      const response = await axios.get("/shop/searchproduct", {
        params,
        headers: {
          Authorization: loginToken,
        },
      });
      console.log("Search results:", response.data.message);
      setFilteredProducts(response.data.message);
    } catch (error) {
      console.error("Error searching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const updateProductWishlistStatus = (productId, isInWishlist) => {
    setFilteredProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, isInWishlist } : product
      )
    );
    console.log("Updated product wishlist status:", isInWishlist);
  };

  const fetchWishlistStatus = async (productId) => {
    try {
      const response = await axios.get("/shop/wishlistproducts", {
        headers: { Authorization: loginToken },
      });

      const wishlist = response.data.message || [];
      setWishMarkList(response.data.message);

      // Log the entire wishlist for debugging
      console.log("Wishlist items:", wishlist);

      const isProductInWishlist = wishlist.some((item) => {
        // Log each item while checking the condition
        console.log("Checking item:", item);
        return item.Products.id === productId;
      });

      console.log(productId, "productId");
      console.log(isProductInWishlist, "isProductInWishlist");
      dispatch({ type: "refresh" });
      updateProductWishlistStatus(productId, isProductInWishlist);
      return isProductInWishlist;
    } catch (error) {
      console.error("Error fetching wishlist status:", error);
      return false;
    }
  };

  const addToWishlist = async (productId, category) => {
    try {
      const response = await axios.post(
        "/shop/addtowishlist",
        {
          ProductId: productId,
          ProductCategory: category,
        },
        {
          headers: { Authorization: loginToken },
        }
      );

      if (response.data.status) {
        updateProductWishlistStatus(productId, true);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/add-wishlist.png",
          message: "Added to wishlist Successfully!",
        });
        setShowSuccessGif(true);
        dispatch({ type: "refresh" });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      const response = await axios.delete(
        `/shop/removewishlistpdt/${productId}`,
        {
          headers: { Authorization: loginToken },
        }
      );

      if (response.data.status) {
        updateProductWishlistStatus(productId, false);
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
          message: "Removed from wishlist Successfully!",
        });
        setShowSuccessGif(true);
        dispatch({ type: "refresh" });
        console.log(response.data);
        console.log("Product removed from wishlist:", productId);
      }
    } catch (error) {
      console.error("Error removing from wishlist:", error);
    }
  };

  const handleGifClose = () => {
    setShowSuccessGif(false);
  };
  return (
    <>
      <NewHeader />
      <div className="hidden md:block">
        <section className="shop-section">
          <div className="shop-top">
            <div className="shop-header">
              <div className="search-bar">
                <CiSearch className="search-bar-icon" />
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
              <div className="track-order" onClick={handleTrackOrder}>
                <DogPettingIcon name={"track-order"} />
              </div>
              <div className="wishlist" onClick={handleWishlist}>
                <DogPettingIcon name={"wishlist-icon"} />
              </div>
            </div>
            <div className="shop-type">
              <div className="shop-nav-links">
                {navLinks.map((link) => (
                  <p
                    key={link.name}
                    className={`shop-nav-link ${
                      activeLink === link.name ? "shop-active" : ""
                    }`}
                    onClick={() => handleNavClick(link.name)}
                  >
                    {link.name}
                    <span className="icon-wrapper">
                      <DogPettingIcon
                        name={link.icon}
                        active={activeLink === link.name}
                      />
                    </span>
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="shop-bottom">
            <div className="shop-container">
              <div>
                <p className="product-title">Best Seller</p>
              </div>

              {filteredProducts.length === 0 ? (
                <div className="no-products">
                  <p>No products found for this category or search.</p>
                  <p>Try changing the filter or search criteria.</p>
                </div>
              ) : (
                <div className="product-container">
                  {filteredProducts.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      onViewDetails={handleViewDetails}
                      // allProducts={allProducts}
                      addToWishlist={addToWishlist}
                      removeFromWishlist={removeFromWishlist}
                      fetchWishlistStatus={fetchWishlistStatus}
                      isInWishlist={product.isInWishlist}
                      wishMarkList={wishMarkList}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <div className="block md:hidden">
        <DownloadAppScreen />
      </div>

      {/* Filter Modal */}
      {isFilterModalOpen && (
        <FilterModal isClose={closeModal} isOpen={isFilterModalOpen} />
      )}
      {isProductModalOpen && (
        <Product
          isOpen={isProductModalOpen}
          isClose={closeProductModal}
          product={selectedProduct}
        />
      )}
      {isWishlistModalOpen && (
        <WishlistItems
          isOpen={handleWishlist}
          isClose={handleWishlist}
          product={selectedProduct}
        />
      )}
      {trackOrderModalOpen && (
        <TrackOrder isOpen={handleTrackOrder} isClose={handleTrackOrder} />
      )}
      {showSuccessGif && (
        <ShopGif
          onClose={handleGifClose}
          message={gifDetails.message}
          gif={gifDetails.gif}
        />
      )}
    </>
  );
}

export default Shop;
