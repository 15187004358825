import React, { useState } from "react";
import DogPettingIcon from "../../Pages/Homepage/DogPettingIcon";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "../../axios";
import { useDispatch } from "react-redux";
import ShopGif from "./ShopGif/ShopGifs";
import { useShop } from "./ShopContext";

export default function VaccinePet({ isOpen, isClose, product }) {
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;
  console.log(petInfo, "petInfo");
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [activeVariantIndex, setActiveVariantIndex] = useState(0);
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { handleSelectedPetIds } = useShop();
  const [selectedPets, setSelectedPets] = useState([]);
  console.log(product, "productid");
  const variants = product.productvariants[0]?.id;
  console.log(variants, "product");
  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  const togglePetSelection = (petId, petType, product) => {
    const existingSelection =
      JSON.parse(localStorage.getItem("petSelectionDetails")) || [];

    // Check if the pet is already selected for the same product
    const existingPetWithSameProduct = existingSelection.find(
      (item) =>
        item.petId === petId && item.productId === JSON.stringify(product.id)
    );

    // If pet exists with same product, remove it. Otherwise, add it
    const filteredSelection = existingSelection.filter(
      (item) =>
        !(item.petId === petId && item.productId === JSON.stringify(product.id))
    );

    const updatedSelectionWithTypes = existingPetWithSameProduct
      ? filteredSelection // Remove if exists with same product
      : [
          ...filteredSelection,
          {
            // Add new entry
            petId: petId,
            petType: petType,
            tax: JSON.stringify(product.Tax),
            productId: JSON.stringify(product.id),
            ProductName: JSON.stringify(product.ProductName),
            variantId: JSON.stringify(product.productvariants[0]?.id),
            price: JSON.stringify(product.productvariants[0]?.price),
          },
        ];

    // Update localStorage
    if (updatedSelectionWithTypes.length > 0) {
      localStorage.setItem(
        "petSelectionDetails",
        JSON.stringify(updatedSelectionWithTypes)
      );
      console.log(
        localStorage.getItem("petSelectionDetails"),
        "updatedSelectionWithTypes"
      );
    } else {
      localStorage.removeItem("petSelectionDetails");
    }

    // Update selected pets state with all current petIds
    const newSelectedPets = updatedSelectionWithTypes.map((item) => item.petId);
    setSelectedPets(newSelectedPets);

    // Update the context whenever selection changes
    handleSelectedPetIds(updatedSelectionWithTypes);
    console.log(updatedSelectionWithTypes, "updatedselectionwithtypes");

    dispatch({
      type: "paravetdata",
      payload: updatedSelectionWithTypes,
    });

    console.log(
      `Selected Pet ID: ${petId}, Pet Type: ${petType}, Product: ${JSON.stringify(
        product.ProductName
      )}`
    );
  };

  const handleSelectAll = (e, product) => {
    if (e.target.checked) {
      const existingSelection =
        JSON.parse(localStorage.getItem("petSelectionDetails")) || [];

      // Remove any existing entries for this product
      const filteredSelection = existingSelection.filter(
        (item) => item.productId !== JSON.stringify(product.id)
      );

      // Create new entries for all pets with this product
      const allPets = petInfo.map((pet) => ({
        petId: pet.id,
        petType: pet.petType,
        tax: JSON.stringify(product.Tax),
        productId: JSON.stringify(product.id),
        ProductName: JSON.stringify(product.ProductName),
        variantId: JSON.stringify(product.productvariants[0]?.id),
        price: JSON.stringify(product.productvariants[0]?.price),
      }));

      // Combine existing selections (for other products) with new selections
      const updatedSelection = [...filteredSelection, ...allPets];

      // Update localStorage with combined selection
      localStorage.setItem(
        "petSelectionDetails",
        JSON.stringify(updatedSelection)
      );

      // Update selected pets state with all current petIds
      const allPetIds = updatedSelection.map((item) => item.petId);
      setSelectedPets(allPetIds);

      // Update context
      handleSelectedPetIds(updatedSelection);

      dispatch({
        type: "paravetdata",
        payload: updatedSelection,
      });
      console.log("Select All", updatedSelection);
    } else {
      // When deselecting all, only remove entries for current product
      const existingSelection =
        JSON.parse(localStorage.getItem("petSelectionDetails")) || [];
      const updatedSelection = existingSelection.filter(
        (item) => item.productId !== JSON.stringify(product.id)
      );

      if (updatedSelection.length > 0) {
        localStorage.setItem(
          "petSelectionDetails",
          JSON.stringify(updatedSelection)
        );
        const remainingPetIds = updatedSelection.map((item) => item.petId);
        setSelectedPets(remainingPetIds);
        handleSelectedPetIds(updatedSelection);
      } else {
        localStorage.removeItem("petSelectionDetails");
        setSelectedPets([]);
        handleSelectedPetIds([]);
      }

      dispatch({
        type: "paravetdata",
        payload: updatedSelection,
      });
      console.log("Deselect All for current product");
    }
  };

  const handleAddToCart = async () => {
    setIsAddingToCart(true);
    try {
      const response = await axios.post(
        "/shop/addtocart",
        {
          quantity: quantity,
          ProductId: product.id,
          variantId: variants,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      if (response.data.status === 200) {
        // Show success GIF for newly added product
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif",
          message: "Added to Cartedd Successfully!",
        });
      } else if (response.data.status === 500) {
        // Show different GIF for already-in-cart message
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp",
          message: "Product is already in cart.",
        });
      }
      setShowSuccessGif(true);
      dispatch({ type: "refresh" });
      handleClose();
      console.log("Product added to cart:", response.data);
    } catch (error) {
      console.error("Error adding product to cart:", error);
    } finally {
      setIsAddingToCart(false);
    }
  };
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };
  const handleGifClose = () => {
    setShowSuccessGif(false);
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="notification-picker">
          <div
            className="notification-picker-title"
            style={{ marginBottom: "3vh" }}
          >
            <h2>Choose pet for vaccination</h2>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>

          {/* Select All Checkbox */}
          <div className="select-all-container w-full flex items-center justify-end gap-[.5vw]">
            <input
              type="checkbox"
              id="select-all"
              checked={selectedPets.length === petInfo.length}
              onChange={(e) => handleSelectAll(e, product)}
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
            <label
              htmlFor="select-all"
              className="cursor-pointer text-[clamp(.8rem,1vw,3rem)]"
            >
              Select all
            </label>
          </div>

          {/* Pet Cards */}
          <div className="flex flex-row items-center justify-center flex-wrap gap-[1vw] w-[33vw] px-[1vw] py-[.5vw] h-[58vh] overflow-auto no-scrollbar pb-[9vh]">
            {petInfo.map((pet) => (
              <div
                key={pet.id}
                className={`flex flex-col items-center gap-[.5vw] w-[8vw] h-[20vh] p-[1vw] rounded-[1.2vw] cursor-pointer overflow-hidden ${
                  selectedPets.includes(pet.id)
                    ? "bg-black text-white"
                    : "bg-white text-black  "
                }`}
                onClick={() => togglePetSelection(pet.id, pet.petType, product)}
              >
                <div className="vaccine-pet-img">
                  <img
                    src={
                      pet.petProfileImage ||
                      "http://localhost:3000/static/media/animated-dog.f5b0acb0f806c280136d.avif"
                    }
                    alt="pet"
                    className="rounded-[1vw] object-fill w-[5vw] h-[5vw]"
                  />
                </div>
                <p className="vaccine-pet-name text-[clamp(.8rem,.8vw,2.5rem)] font-[550]">
                  {pet.petName}
                </p>
              </div>
            ))}
          </div>

          <div className="shopcart-checkoutbuttons">
            <button className="shop-cart-button" onClick={handleAddToCart}>
              <DogPettingIcon name="check" />
              &ensp;Confirm
            </button>
          </div>
        </div>
      </div>
      {showSuccessGif && (
        <ShopGif
          onClose={handleGifClose}
          message={gifDetails.message}
          gif={gifDetails.gif}
        />
      )}
    </div>
  );
}
