import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import "./LandingPage.css";
import NewHeader from "../../Components/Header/NewHeader";
import AOS from "aos";
import { useTitle } from "../hooks/useTitle";
import SecondSection from "./components/HeroSection/SecondSection";
import HeroSection from "./components/HeroSection/HeroSection";
import TagSection from "./components/HeroSection/TagSection";
import ShopSection from "./components/HeroSection/ShopSection";
import ComingSoonSection from "./components/HeroSection/ComingSoonSection";
import { DataManagement } from "./components/DataManagement/DataManageMent";

const ScrollableSection = ({ children, id }) => {
  const sectionRef = useRef(null);

  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          navigate(`#${id}`, { replace: true });
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [id, navigate]);

  return (
    <section ref={sectionRef} id={id} className="h-screen snap-start">
      {children}
    </section>
  );
};

const LandingPage = () => {
  useTitle("PetEYE: Data and Health Management Services.");
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 500, delay: 50 });
  }, []);

  useEffect(() => {
    // Handle scroll when hash changes
    if (location.hash) {
      const targetId = location.hash.slice(1); // Remove the # symbol
      const element = document.getElementById(targetId);
      if (element) {
        // Smooth scroll to the element
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const [rotationIndex, setRotationIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotationIndex((prevIndex) => (prevIndex + 1) % 21);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const step = 360 / 21;
  const imageUrls = [
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277285.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277286.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277287.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277289.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277291.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277290.webp",
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Frame+1171277288.webp",
  ];

  const linkUrls = [
    "https://g.co/kgs/8C7oX5f",
    "https://g.co/kgs/aAC8GGz",
    "https://g.co/kgs/PDjcnf9",
    "https://maps.app.goo.gl/ApDoviV7A3nETvXT8",
    "https://g.co/kgs/Md34hcb",
    "https://g.co/kgs/kDFrnm1",
    "https://g.co/kgs/dgtdrRP",
  ];

  return (
    <>
      <div className="snap-y snap-mandatory h-screen overflow-y-scroll bg-white">
        <NewHeader />
        <ScrollableSection id="hero">
          <HeroSection />
        </ScrollableSection>
        <ScrollableSection id="second">
          <SecondSection />
        </ScrollableSection>
        <ScrollableSection id="data-management">
          <DataManagement />
        </ScrollableSection>
        <ScrollableSection id="tag">
          <TagSection />
        </ScrollableSection>
        <ScrollableSection id="reviews">
          <section className="h-screen flex flex-col gap-[10vh] 3xl:gap-[20vh] overflow-hidden">
            <h4 className="text-[clamp(1.5rem,2.5vw,7rem)] text-center text-[700] w-[70vw] ml-auto mr-auto mt-[10vh] font-[Prata]">
              "PetEye Reviews: Heartfelt Reviews from our furry "Friends &
              Family"
            </h4>
            <div className="circleCarousel">
              <div
                className="circle"
                style={{
                  "--items": 21,
                  "--rotation": `${step * rotationIndex * -1}deg`,
                }}
              >
                {[...Array(21)].map((_, index) => (
                  <a
                    key={index}
                    href={linkUrls[index % linkUrls.length]}
                    className="image-anchor"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ "--index": index }}
                  >
                    <div
                      className="image"
                      style={{
                        "--index": index,
                        backgroundImage: `url(${
                          imageUrls[index % imageUrls.length]
                        })`,
                      }}
                    ></div>
                  </a>
                ))}
              </div>
            </div>
          </section>
        </ScrollableSection>
        <ScrollableSection id="shop">
          <ShopSection />
        </ScrollableSection>
        {/* <ScrollableSection id="coming-soon">
          <ComingSoonSection />
        </ScrollableSection> */}

        <ScrollableSection id="footer">
          <Footer />
        </ScrollableSection>
      </div>
    </>
  );
};

export default LandingPage;
