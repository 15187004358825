import React from "react";

export default function TagSection() {
  return (
    <div className=" w-full bg-black text-white  h-[100vh] flex flex-col items-center justify-center">
      <div className="flex  flex-col gap-[7vw] md:gap-[.5vw]  md:flex-row items-center justify-center  md:w-[82vw] w-[90vw] m-auto">
        <div className="flex flex-col gap-[7vw] md:gap-[1.5vw] items-center md:items-start justify-center md:w-[50vw]">
          <div className="bg-white text-black rounded-[5vw] md:rounded-[2vw] w-[30vw] h-[6.5vh] md:w-[15vw] md:h-[5vh] xl:w-[12vw] xl:h-[8vh] flex items-center justify-center content-center align-middle gap-[1vw] md:gap-[.5vw]">
            <img
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/image16407.webp"
              alt="image16407"
              title="Eye Tag"
              className=" w-[8vw] md:w-[4vw] h-100 object-cover"
            />
            <p className="text-[clamp(1.2rem,2.5vw,4.5rem)]  md:text-[clamp(1rem,1.5vw,4.5rem)] font-[Prata] font-[400] content-center align-middle">
              TAG
            </p>
          </div>
          <h4 className="text-[clamp(1.5rem,4vw,9rem)] text-center md:text-left  md:text-[clamp(1.5rem,2.5vw,7rem)]  font-[Prata] font-[600]">
            Ensure your Pet’s Safe Return
          </h4>
          <p className="text-[clamp(.8rem,2.2vw,3.5rem)] text-center md:text-left md:text-[clamp(1rem,1vw,4.5rem)] xl:text-[clamp(1rem,1.2vw,4.5rem)] font-[Poppins] font-[400]">
            Echo is a revolutionary pet identification tag with built-in NFC and
            QR code technology. Access your pet's profile with a simple scan,
            sharing vital information when needed.
          </p>
          <div className="hidden md:flex flex-row items-center gap-[1.5vw] mt-[2vh]">
            <button className="text-[clamp(.8rem,1vw,3rem)] font-[Prata] px-[2vw] py-[.8vw] bg-white text-black font-[700] rounded-[.5vw]">
              Launching Soon
            </button>
            <p className="text-[clamp(.8rem,1vw,3rem)] font-[Prata] font-[600]">
              Find out more
            </p>
          </div>
        </div>
        <div className="w-full h-[30vh]   md:w-[30vw] xl:w-[35vw] xl:h-[100%] md:mt-[10vh]">
          <img
            src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Servicewebp/Group-1171276962.webp"
            alt="image16408"
            title="Pet Tag"
            className="w-full  object-contain h-[100%]"
          />
        </div>
        <div className="md:hidden flex flex-row items-center gap-[5vw]">
          <button className="text-[clamp(1rem,1vw,3rem)] font-[Prata] px-[4vw] py-[1.2vw] bg-white text-black font-[700] rounded-[2vw]">
            Launching Soon
          </button>
          <p className="text-[clamp(1rem,1vw,3rem)] font-[Prata] font-[600]">
            Find out more
          </p>
        </div>
      </div>
    </div>
  );
}
