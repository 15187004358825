import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie"; // Import js-cookie

export const LogoutConfirmation = ({ isOpen, isClose }) => {
  const [isClosing, setIsClosing] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const loginToken = Cookies.get("loginToken");
  const { login, refresh } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleLogout = () => {
    const token = Cookies.get("loginToken");

    if (token) {
      Cookies.remove("loginToken");
      const removedToken = Cookies.get("loginToken");

      if (!removedToken) {
        console.log("Token removed successfully. Refreshing page...");
        window.location.reload();
        dispatch({ type: "refresh" });
        setTimeout(() => {
          navigate("/"); // Redirect to the login page using useNavigate
        }, 100);
        // Small delay to ensure the page reloads before navigating
      } else {
        console.error("Failed to remove the token.");
      }
    } else {
      console.error("No token found in cookies.");
      window.location.reload();
      setTimeout(() => {
        navigate("/"); // Redirect to the login page even if no token is found
      }, 100); // Small delay to ensure the page reloads before navigating
    }
  };
  console.log(!login?.login, "login");
  if (!isOpen) return null;

  return (
    <>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"owner-profile"} /> Logout
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="logout-modal">
              <div className="logout-container">
                <div className="logout-confirmation">
                  <p>Are you sure you want to Logout?</p>
                  <img
                    src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/logout-account.gif"
                    alt="Logout confirmation"
                  />
                </div>
                <div className="logout-container-control">
                  <button className="logout-btn" onClick={handleLogout}>
                    <DogPettingIcon name={"setting-logout"} />
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
