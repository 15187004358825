import React from "react";
import "./Loading.css";

export const Loading = ({
  color = "#ccc",
  size = "120px",
  movingColor = "black",
}) => {
  return (
    <div
      className="spinner"
      style={{
        borderColor: `${color} ${color} ${color} ${movingColor}`,
        width: size,
        height: size,
      }}
    />
  );
};
