import React, { useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";

export default function Modal({
  modaltitle,
  handleClose,
  handleOverlayClick,
  children,
  isClosing,
  iconname,
}) {
  //
  return (
    <div>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="weight-picker">
            <div className="weight-picker-title">
              <DogPettingIcon name={iconname} />
              <h2>{modaltitle}</h2>
              <div className="modal-close" onClick={handleClose}>
                <DogPettingIcon
                  name="closemodal"
                  className="modal-close-icon"
                />
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
