import React, { useState, useEffect, useRef } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./PetDocInfo.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import Failedgif from "../../../../Failed/Failedgif";

export default function PetDocInfo({ isOpen, onClose, petId }) {
  const [isEditing, setIsEditing] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [identity1, setIdentity1] = useState("");
  const [identity2, setIdentity2] = useState("");
  const [identity3, setIdentity3] = useState("");
  const [veterinarians, setVeterinarians] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const [error, setError] = useState("");
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;

  const newVetFormRef = useRef(null);
  const editFormRefs = useRef([]);

  const fetchVeterinarians = async () => {
    try {
      const response = await axios.get(`/petDoctor/getList/${petId.id}`, {
        headers: {
          Authorization: loginToken,
        },
      });
      if (response.data) {
        setVeterinarians(response.data.petDoctorList);
        console.log("valid response format:", response.data.petDoctorList);
      } else {
        console.error("Invalid response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching veterinarians:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchVeterinarians();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEditing !== null) {
      const vet = veterinarians[isEditing];
      setIdentity1(vet.VetDoctorName);
      setIdentity2(vet.VetDoctorNumber);
      setIdentity3(vet.vetDoctorLocation);
      focusOnEditInput();
    }
  }, [isEditing, veterinarians]);

  useEffect(() => {
    if (!isOpen && !isClosing) {
      onClose(); // Call onClose if the modal is not open and closing animation is not active
    }
  }, [isOpen, isClosing, onClose]);

  const focusOnNewVetForm = () => {
    setTimeout(() => {
      if (newVetFormRef.current) {
        newVetFormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        const input = newVetFormRef.current.querySelector("input");
        if (input) input.focus();
      }
    }, 100);
  };

  const focusOnEditInput = () => {
    setTimeout(() => {
      if (editFormRefs.current[isEditing]) {
        editFormRefs.current[isEditing].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        const inputs =
          editFormRefs.current[isEditing].querySelectorAll("input");
        if (inputs.length > 0) inputs[0].focus(); // Focus on the first input
      }
    }, 100); // Adjust delay if necessary
  };

  const handleAddClick = () => {
    setIsAdding(true);
    setIsEditing(null);
    setIdentity1("");
    setIdentity2("");
    setIdentity3("");
    focusOnNewVetForm();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose(); // Close modal on overlay click
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000);
  };

  const handleInputChange = (e, setter, isNumeric = false, maxLength) => {
    const value = e.target.value;
    console.log(`Current input value: ${value}, Length: ${value.length}`);

    // Ensure the input does not exceed the maximum length
    if (value.length > maxLength) {
      setter(value);
      console.log(`Current input value: ${value}, Length: ${value.length}`);
      setError(`Maximum ${maxLength} characters allowed.`);
      setTimeout(() => setError(""), 3000);
      return; // Stop further execution if limit exceeded
    }

    if (isNumeric) {
      if (/^\d*$/.test(value)) {
        setter(value); // Allow only numbers
        setError(""); // Clear error if valid
      } else {
        setError("Only numbers are allowed for phone."); // Set error for invalid input
      }
    } else {
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setter(value); // Allow only alphabets and spaces
        setError(""); // Clear error if valid
      } else {
        setError("Only letters and spaces are allowed."); // Set error for invalid input
      }
    }
  };

  const postVetInfo = async () => {
    try {
      await axios.post(
        "/petDoctor/add",
        {
          petId: petId.id,
          isCurrentConsultant: true,
          VetDoctorName: identity1,
          VetDoctorNumber: identity2,
          VetDoctorLocation: identity3,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      await fetchVeterinarians(); // Refresh the list after posting
    } catch (error) {
      console.error("Error posting veterinarian info:", error);
    }
  };

  const validateInputs = () => {
    // Ensure all fields are filled
    if (!identity1 || !identity2 || !identity3) {
      setError("All fields are required.");
      setTimeout(() => setError(""), 3000);
      return false;
    }

    setError(""); // Clear any error if all validations pass
    return true;
  };

  const handleEditSaveClick = async () => {
    if (isEditing !== null) {
      if (!validateInputs()) return; // Validate before proceeding
      try {
        const vetToUpdate = veterinarians[isEditing];
        const response = await axios.post(
          "/petDoctor/edit",
          {
            petDoctorId: vetToUpdate.id,
            petId: petId.id,
            isCurrentConsultant: true,
            VetDoctorName: identity1,
            VetDoctorNumber: identity2,
            VetDoctorLocation: identity3,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );

        if (response.status === 200) {
          console.log("Edited response data:", response.data);
          await fetchVeterinarians();
          setIsEditing(null);
        } else {
          console.error("Failed to update veterinarian:", response);
        }
      } catch (error) {
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        } else {
          console.error("Error message:", error.message);
        }
      }
    } else if (isAdding) {
      if (!validateInputs()) return; // Validate before proceeding
      await postVetInfo();
      setIsAdding(false);
    }
    setIdentity1("");
    setIdentity2("");
    setIdentity3("");
  };

  const handleEditClick = (index) => {
    setIsEditing(index);
    setIsAdding(false);
  };

  if (!isOpen) return null; // Ensure the component doesn't render if not open

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="petdocinfo-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="petdocinfo-title">
            <DogPettingIcon name={"petdocinfo"} />
            <h2>Veterinarian Information</h2>
          </div>
          <div className="petdocinfo-input-group">
            {veterinarians.length > 0 || isAdding ? (
              veterinarians.map((vet, index) => (
                <div
                  key={index}
                  className="veterinarian-info"
                  ref={(el) => (editFormRefs.current[index] = el)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "25vw",
                    }}
                  >
                    <h2>Veterinarian’s {index + 1}</h2>
                    <button
                      className="petdocinfo-edit-button"
                      onClick={() => handleEditClick(index)}
                    >
                      <FaRegEdit
                        style={{
                          fontSize: "clamp(1.2rem, 1.2vw ,4rem)",
                          fill: "#000",
                          fontWeight: "bolder",
                        }}
                      />
                    </button>
                  </div>
                  {isEditing === index ? (
                    <>
                      <input
                        type="text"
                        placeholder="Veterinarian’s Name"
                        value={identity1}
                        onChange={(e) =>
                          handleInputChange(e, setIdentity1, false, 30)
                        }
                      />
                      <input
                        type="text"
                        placeholder="Veterinarian’s Phone"
                        value={identity2}
                        onChange={(e) =>
                          handleInputChange(e, setIdentity2, true, 10)
                        }
                      />
                      <input
                        type="text"
                        placeholder="Veterinarian’s Address"
                        value={identity3}
                        onChange={(e) =>
                          handleInputChange(e, setIdentity3, false, 100)
                        }
                      />
                      <button
                        className="petdocinfo-button"
                        onClick={handleEditSaveClick}
                        style={{ margin: "auto" }}
                      >
                        <DogPettingIcon
                          name="check"
                          className="petdocinfo-edit-icon"
                        />
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <p>{vet.VetDoctorName}</p>
                      <p>{vet.VetDoctorNumber}</p>
                      <p>{vet.vetDoctorLocation}</p>
                    </>
                  )}
                </div>
              ))
            ) : (
              <Failedgif message="No Veterinarian Info Found" />
            )}
            {isAdding && (
              <div className="veterinarian-info" ref={newVetFormRef}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "25vw",
                  }}
                >
                  <h2>Veterinarian’s {veterinarians.length + 1}</h2>
                  <button
                    className="petdocinfo-edit-button "
                    onClick={() => setIsAdding(false)}
                  >
                    <AiOutlineCloseCircle
                      style={{
                        fontSize: "clamp(1.2rem, 1.2vw ,4rem)",
                        fill: "#000",
                        fontWeight: "bolder",
                      }}
                    />
                  </button>
                </div>
                <input
                  type="text"
                  placeholder="Veterinarian’s Name"
                  value={identity1}
                  onChange={(e) =>
                    handleInputChange(e, setIdentity1, false, 30)
                  }
                />
                <input
                  type="text"
                  placeholder="Veterinarian’s Phone"
                  value={identity2}
                  onChange={(e) => handleInputChange(e, setIdentity2, true, 10)}
                />
                <input
                  type="text"
                  placeholder="Veterinarian’s Address"
                  value={identity3}
                  onChange={(e) =>
                    handleInputChange(e, setIdentity3, false, 100)
                  }
                />
                <button
                  className="petdocinfo-button"
                  onClick={handleEditSaveClick}
                  style={{ margin: "auto" }}
                >
                  <DogPettingIcon
                    name="check"
                    className="petdocinfo-edit-icon"
                  />
                  Confirm
                </button>
              </div>
            )}
          </div>
          {!isAdding && (
            <button className="petdocinfo-button" onClick={handleAddClick}>
              <DogPettingIcon name="save" className="petdocinfo-edit-icon" />
              Add Veterinarian
            </button>
          )}
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
}
