import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

const CalendarComponent = ({ onDateClick, reminders, selectedDate }) => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const getDatesForWeek = (date) => {
    const startDate = date.startOf("week");
    return Array.from({ length: 7 }, (_, index) => startDate.add(index, "day"));
  };

  const datesForWeek = getDatesForWeek(currentDate);

  const handlePreviousWeek = () => {
    setCurrentDate(currentDate.subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setCurrentDate(currentDate.add(1, "week"));
  };

  const hasReminders = (date) => {
    return reminders[date.format("YYYY-MM-DD")]?.some(
      (reminder) => !reminder.isCompleted
    );
  };

  return (
    <Container>
      <NavButton onClick={handlePreviousWeek}>&lt;</NavButton>
      <WeekContainer>
        {datesForWeek.map((date) => (
          <DayContainer key={date.toString()}>
            <Day>{daysOfWeek[date.day()]}</Day>
            <DateDisplay
              selected={date.isSame(selectedDate, "day")}
              isToday={date.isSame(dayjs(), "day")}
              onClick={() => onDateClick(date)}
            >
              {date.date()}
              {hasReminders(date) && <NotificationDot />}
            </DateDisplay>
          </DayContainer>
        ))}
      </WeekContainer>
      <NavButton onClick={handleNextWeek}>&gt;</NavButton>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1.5vw;
  border-radius: 0.8vw;
  display: flex;
`;

const NavButton = styled.button`
  cursor: pointer;
  padding: 0.5vw;
  margin: 0 10px;
  background-color: transparent;
  border: none;
  border-radius: 0.4vw;
  font-size: clamp(1.5rem, 2vw, 4rem);
  transform: translate(0%, 20%);
`;

const WeekContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 0.8vw;
`;

const DayContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8vw;
`;

const Day = styled.div`
  font-weight: 500;
  font-size: clamp(0.8rem, 1vw, 3.5rem);
`;

const DateDisplay = styled.div`
  font-size: clamp(0.8rem, 1vw, 3.5rem);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 3vw;
  height: 8vh;
  border-radius: 0.7vw;
  box-shadow: rgba(0, 0, 0, 0.15) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background-color: ${(props) => (props.selected ? "#000" : "#fff")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  border: ${(props) =>
    props.isToday && !props.selected ? "1px solid #000" : "none"};
  background-color: ${(props) =>
    props.isToday && !props.selected
      ? "#fff"
      : props.selected
      ? "#000"
      : "#fff"};

  position: relative;
`;

const NotificationDot = styled.div`
  width: 0.7vw;
  height: 1.5vh;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -0.2vw;
  right: -0.2vw;
`;

export default CalendarComponent;
