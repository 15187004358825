import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function HeroSection() {
  const images = [
    "Frame%2B1171277161.webp",
    "Frame%2B1171277162.webp",
    "Frame%2B1171277159.webp",
    "Frame%2B1171277163.webp",
    "Frame%2B1171277164.webp",
  ];

  // Logic to slice the images array based on the screen size
  const getVisibleImages = () => {
    if (window.innerWidth >= 1080) return images;
    // Desktop (5 images)
    if (window.innerWidth <= 1080) return images.slice(0, 3); // Mobile (1, 2, 3)
  };
  const birdsGif =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/big+bird.gif";

  useEffect(() => {
    AOS.init({ duration: 500, delay: 50 });
  }, []);

  // Render each image with unique AOS properties based on index
  const renderImage = (src, index) => {
    const delay = 200 + index * 100;
    const duration = 750 + index * 50;

    return (
      <div
        key={index}
        style={{ backgroundColor: "#020202" }}
        className={`flex items-end justify-end xl:w-1/6 w-3/5 md:w-3/5 rounded-tl-[6vw] rounded-tr-[6vw] md:rounded-tl-[2vw] md:rounded-tr-[2vw] ${
          index % 2 === 0
            ? " h-[30vh] md:h-[42vh] xl:h-[48vh]"
            : "h-[48vh] md:h-[30vh] xl:h-[38vh] "
        }`}
        data-aos="fade-up"
        data-aos-delay={delay}
        data-aos-duration={duration}
      >
        <img
          src={`https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/${src}`}
          alt={`HeroImg${index + 1}`}
          title={`HeroImg${index + 1}`}
          className=" object-fill w-[80%] md:w-[90%]  ml-auto mr-auto "
        />
      </div>
    );
  };

  const [visibleImages, setVisibleImages] = useState(getVisibleImages());

  useEffect(() => {
    const handleResize = () => setVisibleImages(getVisibleImages());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="flex flex-col gap-[6vw] items-center justify-end h-[93vh] md:h-[97vh]   xl:h-[100vh] w-full m-auto   bg-cover bg-center"
      style={{ backgroundImage: `url(${birdsGif})` }}
    >
      {/* Title Section */}
      <div
        className="flex flex-col justify-center items-center gap-[1vw]"
        data-aos="fade-up"
      >
        <img
          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/Pet%2BEYE.webp"
          alt="titleImg"
          title="Peteye img"
          className="w-[60vw] md:w-[45vw] xl:w-[32vw] object-contain"
          data-aos-delay="200"
        />
        <p className="text-[clamp(1rem,2vw,6rem)] md:text-[clamp(1.5rem,4vw,6rem)] xl:text-[clamp(1rem,2.2vw,6rem)] font-normal font-[Prata] text-center">
          Nurturing Bonds Ensuring Safety
        </p>
      </div>

      {/* Image Section */}
      <div className="flex flex-row justify-center items-end gap-[2vw] md:gap-[.5vw] w-4/5 md:w-3/5 xl:w-11/12 h-[50vh]">
        {visibleImages.map((src, index) => renderImage(src, index))}
      </div>
    </div>
  );
}
