import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import "./CustomDropdown.css";

const CustomDropdown = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedOption, setCheckedOption] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setCheckedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className={`dropdown-header ${isOpen ? "open" : ""}`}
        onClick={toggleDropdown}
      >
        {checkedOption ? (
          <div className="checked-option">
            <span className="checked-name">{checkedOption.name}</span>
            <span className="checked-price">Rs. {checkedOption.price}</span>
          </div>
        ) : (
          <span>Choose Vaccine</span>
        )}
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </div>
      <div className={`dropdown-options ${isOpen ? "show" : ""}`}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`option ${
              checkedOption && checkedOption.name === option.name
                ? "checked"
                : ""
            }`}
            onClick={() => handleOptionClick(option)}
          >
            <div className="option-content">
              <span className="option-name">{option.name}</span>
              <span className="price">Rs. {option.price}</span>
            </div>
            <div className="radio-wrapper">
              <input
                type="radio"
                checked={checkedOption && checkedOption.name === option.name}
                readOnly
                className="radio-input"
              />
              <span className="radio-custom"></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomDropdown;
