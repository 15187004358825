import React, { useState, useEffect } from "react";
import "./ProductActions.css";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import Failedgif from "../../../Pages/Failed/Failedgif";
import CartItems from "./CartItems";
import PharmacyItems from "./PharmacyItems";
import { useShop } from "../ShopContext";
import WishlistItems from "./WishlistItems";
import { LuUpload } from "react-icons/lu";
import { IoDocumentText } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Prescription from "./Prescription";
import Checkout from "./Checkout/Checkout";

import { AiFillFilePdf } from "react-icons/ai";
import axios from "../../../axios";
import Success from "../../../Pages/Profile/Success/Success";
import Delete from "../../../Components/Delete/Delete";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

export default function ProductActions({ isOpen, isClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [activeTab, setActiveTab] = useState("cart");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [isClosing1, setIsClosing1] = useState(false);
  const [prescriptionOpen, setPrescriptionOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const {
    ActiveTab,
    handleActiveTab,
    calculateCartTotal,
    calculatePharmacyCartTotal,
    pharmacycartItemIds,
    PrescriptionFile,
    cartData,
    pharmacyCartData,
    cartCount,
    pharmacyCount,
  } = useShop();

  const [, setForceUpdate] = useState({});

  useEffect(() => {
    setForceUpdate({});
  }, [cartCount.totalCount, pharmacyCount.totalCount]);

  let petInfo;
  {
    login?.login && (petInfo = login.details.petInfo);
  }
  const dispatch = useDispatch();
  console.log(cartCount.totalCount, pharmacyCount.totalCount, "data count");
  const getPrescriptionFileData = () => {
    const storedData = localStorage.getItem("PrescriptionFile");
    if (!storedData) return [];

    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error("Error parsing stored file data:", error);
      return null;
    }
  };

  const prescriptionFileData = getPrescriptionFileData();
  console.log(prescriptionFileData, "prescriptionFileData");
  const petSelectionDetails = JSON.parse(
    localStorage.getItem("petSelectionDetails")
  );
  console.log(petSelectionDetails, "petSelectionDetails");
  const pharmacylength = prescriptionFileData.length;

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      // isClose();
      handleClose();
      // handleUploadModalClose();
    }
  };

  const handleFileDelete = () => {
    setDeleteModal(true);
  };

  if (!isOpen) return null;

  const handleTabClick = (tab) => {
    handleActiveTab(tab);
    setActiveTab(tab);
    console.log(tab, "tab");
  };
  const handleConfirmDelete = async () => {};

  const handleCancelDelete = () => {
    setDeleteModal(false);
  };
  const handlePrescription = () => {
    setPrescriptionOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };
  const handleCheckout = () => {
    setCheckoutOpen((prev) => !prev);
  };

  return (
    <div>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="shop-cart-wishlist">
            <div
              className={`shop-cart-toggle weight-toggle ${
                activeTab === "cart"
                  ? "family-tab-active"
                  : "community-tab-active"
              }`}
            >
              <button
                className={activeTab === "cart" ? "active" : ""}
                onClick={() => handleTabClick("cart")}
              >
                <DogPettingIcon
                  name={
                    activeTab === "cart"
                      ? "header-addtocart-activeicon"
                      : "header-addtocart"
                  }
                />
                General
              </button>
              <button
                className={activeTab === "pharmacy" ? "active" : ""}
                onClick={() => handleTabClick("pharmacy")}
              >
                <DogPettingIcon
                  name={
                    activeTab === "pharmacy"
                      ? "shop-wishlistbutton-activeicon"
                      : "shop-wishlistbutton-nonactiveicon"
                  }
                />
                Pharmacy
              </button>
            </div>
          </div>

          <div className="cart-items pb-[11vh]">
            {/* Conditionally render data based on active tab */}
            {activeTab === "cart" ? (
              <>
                <CartItems />
              </>
            ) : (
              <>
                <PharmacyItems />
              </>
            )}
          </div>
          {login?.login && (
            <div className="shopcart-checkoutbuttons ">
              {activeTab === "cart" && cartCount.totalCount > 0 ? (
                <button className="shop-cart-button" onClick={handleCheckout}>
                  <DogPettingIcon name="check" />
                  &ensp;₹ {calculateCartTotal().toFixed(2)} Checkout
                </button>
              ) : null}

              {activeTab === "pharmacy" && pharmacyCount.totalCount > 0 ? (
                pharmacylength == 0 ? (
                  <button
                    className="shop-cart-button"
                    onClick={handlePrescription}
                  >
                    <DogPettingIcon name="check" />
                    &ensp;₹ {calculatePharmacyCartTotal().toFixed(2)} Checkout
                  </button>
                ) : (
                  <button className="shop-cart-button" onClick={handleCheckout}>
                    <DogPettingIcon name="check" />
                    &ensp;₹ {calculatePharmacyCartTotal().toFixed(2)} Checkout
                  </button>
                )
              ) : null}
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModal && (
        <Delete
          handleDelete={handleConfirmDelete}
          rejectDelete={handleCancelDelete}
          message="Do you really want to remove this file?"
        />
      )}
      {checkoutOpen && (
        <Checkout isClose={handleCheckout} isOpen={handleCheckout} />
      )}
      {prescriptionOpen && (
        <Prescription
          isClose={handlePrescription}
          isOpen={handlePrescription}
        />
      )}
    </div>
  );
}
