import Icons from "../../../../context/Icons";
import { DataManagementCard } from "./DataManagementCard";
import { PiLightbulbFilament } from "react-icons/pi";
import { GiNightSleep } from "react-icons/gi";
import DataManagementCarousel from "./DataManagementCarousel";
const DataManagementDetails = [
  {
    id: 1,
    title: "Data Management System ",
    description:
      "Discover unparalleled data control and efficiency with our Data Management Service. Experience the peace of mind that comes with top-tier security.",
    img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/dms-service.webp",
    icons: [
      {
        id: 1,
        icon: <Icons name={"dms-copy"} />,
        title: "Organize Medical Records",
      },
      {
        id: 2,
        icon: <Icons name={"dms-paw"} />,
        title: "Create and Manage Pet Profiles",
      },
      {
        id: 3,
        icon: <Icons name={"dms-info"} />,
        title: "Share Information ",
      },
      {
        id: 4,
        icon: <Icons name={"dms-notifications"} />,
        title: "Alert critical conditions",
      },
    ],
  },

  {
    id: 2,
    title: "On Demand Services",
    description:
      "Elevate your doorstep experience with our On-Demand Services - where convenience, reliability, and exceptional customer care converge to simplify your life.",
    img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/ondemand-servoce.webp",
    icons: [
      {
        id: 1,
        icon: <Icons name={"dms-inject"} />,
        title: "Vaccination Service",
      },
      {
        id: 2,
        icon: <Icons name={"dms-help"} />,
        title: "Pet Supplements",
      },
      {
        id: 3,
        icon: <Icons name={"dms-aid"} />,
        title: "Essential first aid",
      },
      {
        id: 4,
        icon: <Icons name={"dms-pills"} />,
        title: "Pharmaceuticals",
      },
    ],
  },

  {
    id: 3,
    title: "Your Pet’s Guardian",
    description:
      "Pet EYE is more than just a pet tracker; it's a comprehensive platform designed to protect your beloved pet. Our innovative solution combines:",
    img: "https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/your-guardian.webp",
    icons: [
      {
        id: 1,
        icon: <Icons name={"dms-tracking"} />,
        title: "Robust Tracking",
      },
      {
        id: 2,
        icon: <PiLightbulbFilament />,
        title: "Innovative Technology",
      },
      {
        id: 3,
        icon: <Icons name={"dms-community"} />,
        title: "Community Driven Safety",
      },
      {
        id: 4,
        icon: <GiNightSleep />,
        title: "Peace Of Mind",
      },
    ],
  },
];

export const DataManagement = () => {
  return (
    <section className="h-screen w-screen overflow-hidden flex items-center justify-center">
      <DataManagementCarousel items={DataManagementDetails} />
    </section>
  );
};
