import React from 'react'
import './Wishlist.css'
import { FaHeart } from 'react-icons/fa'
import { useState } from 'react';
import { BsCurrencyRupee } from 'react-icons/bs';
import { FaCartShopping, FaIndianRupeeSign } from 'react-icons/fa6';
import axios from '../../../../axios';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Modal } from '@mui/material';
import { ModalDialog } from '@mui/joy';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Auth from '../Auth/Auth';
const loginToken = Cookies.get('loginToken');

function Wishlist({setcartStatus,cartStatus}) {
  const cartEmpty='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/cartempty.gif'
  const exclamation='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/AlreadyExist.gif'
  const offerTag='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+34624469.png'
  const successGif='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/AddtoCart.gif'
  const share='https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/share.png'
  const [selectedUnitPrice, setSelectedUnitPrice] = useState('');
  const [viewProduct, setviewProduct] = useState('')
  const [reviewLine, setreviewLine] = useState(false);
  const [wishlistProducts, setwishlistProducts] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [openAddtocart, setopenAddtocart] = useState(false)
  const [value, setvalue] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const [open, setOpen] =useState(false);

// Add to cart

  const addtocart=(quantity,ProductId,ProductCategory)=>{
    const unit=selectedUnitPrice?.unit ? selectedUnitPrice?.unit : null;
    const amount=viewProduct?.Products?.Productamount?.[0]?.amount   ?  ((selectedUnitPrice.amount) - (Math.floor((selectedUnitPrice.amount) * ((viewProduct?.Products?.OfferPercentage) / 100))) - 2)    :  ((selectedUnitPrice) - (Math.floor((selectedUnitPrice) * ((viewProduct?.Products?.OfferPercentage) / 100))) - 2)
    axios.post('/shop/addtocart',{quantity,ProductId,unit,amount,ProductCategory},{
      headers: {
        Authorization:loginToken 
      }
    }).then((response)=>{
     console.log(response.data)
      if(response.data.status=='200'){
        setopenAddtocart(true)
        setcartStatus(!cartStatus)
        removefromwishlist(ProductId)
        setvalue('Added to cart')
        setTimeout(() => {
          setopenAddtocart(false)
        }, 1000);
      }
      if(response.data.status=='500'){
        setopenAddtocart(true)
        setvalue('Product already in cart')
        setTimeout(() => {
          setopenAddtocart(false)
        }, 1000);
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  //Fetching wishlist products from backend

  useEffect(()=>{
    if(!loginToken){
      setOpen(true)
    }
    axios.get('/shop/wishlistproducts', {
     headers: {
       Authorization: loginToken
     }
   }).then((response)=>{
    console.log(response.data)
     if (response.data.status == '200') {
      setTimeout(() => {
        setLoading(false); // Data is loaded
      }, 1000);
      setwishlistProducts(response.data.message)
      setviewProduct(response.data.message[0]);
      const pdt=response.data.message;
      setSelectedUnitPrice(pdt?.[0]?.Products?.Productamount?.[0]?.amount?  pdt?.[0]?.Products?.Productamount?.[0] : pdt?.[0]?.Products?.Productamount);
     }
   }).catch((error)=>{
    console.log(error)
   })
 },[refresh])

 // Share product

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Product Title',
        text: 'Check out this amazing product!',
        url: 'https://peteye.pet'
      }).then(() => {
        console.log('Shared successfully');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      const productUrl = 'https://peteye.pet';
      const shareText = 'Check out this amazing product: Product Title - ' + productUrl;
      window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(shareText), '_blank');
    }
  };

  // Remove product from wishlist

  const removefromwishlist=(id)=>{
    axios.delete(`/shop/removewishlistpdt/${id}`,{
      headers: {
        Authorization:loginToken 
      }
    }).then((response)=>{
      console.log(response.data)
     
        setrefresh(!refresh)
      
    }).catch((error)=>{
      console.log(error)
    })
  }

  //Handle select option

  const handleProductChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedUnit = e.target.options[selectedIndex].getAttribute('data-unit');
    const selectedPrice = e.target.value;
    setSelectedUnitPrice({ unit: selectedUnit, amount: selectedPrice });
  };

  const renderProducts=()=>{
    return(
      <>
      {

wishlistProducts?.map((item,index)=>{
   return(
     
<div key={item.id} style={{display:'flex',justifyContent:'center'}}>
<div onClick={()=>{setviewProduct(item); setSelectedUnitPrice(item?.Products?.Productamount?.[0]?.amount? item?.Products?.Productamount?.[0] :item?.Products?.Productamount)}} style={{background:'#F5F5F5',cursor:'pointer'}} className='wishlist-main'>
<div style={{display:'flex',justifyContent:'center'}}>
     <span>{index+1}</span>
     </div>
     <div style={{display:'flex',justifyContent:'center'}}>
       {console.log(item)}
             <img src={item?.Products?.productImages?.[0]?.url} alt="" />
          
     </div>
     <div  className='cart-item-section'>
     <span>{item?.Products?.Category}</span>
     <p>{item?.Products?.ProductName.split(" ").slice(0, 4).join(" ")}</p>
     {
       item.ProductCategory=='devices' ? "" :
     <p>{item?.Products?.productvariants?.[0]?.unit}</p>
     }
     </div>
     
     <div className='cart-total'>
     <span>Total</span>
     <p><FaIndianRupeeSign style={{paddingTop:'5px',fontSize:'12px'}} />
      
     {((item?.Products?.productvariants?.[0]?.price)-(Math.floor((item?.Products?.productvariants?.[0]?.price) * ((item?.Products?.productvariants?.[0]?.OfferPercentage)/100 )))-2)}</p>

     </div>
     {/* <div className='wishlist-addcart'>
     <button onClick={()=>addtocart(item?.Products?.quantity,item?.Products?.id,item?.ProductCategory)} >
     <FaCartShopping />
     <p>Add</p>
     </button> 
     </div> */}
     <div>
     <RiCloseCircleFill onClick={()=>removefromwishlist(item.ProductId)} style={{fontSize:'25px' , cursor:'pointer'}}/>
     </div>
</div>
</div>

   )
 })
}
      </>
    )
  }
  const renderViewProduct=()=>{
    return(
      <>
      <div className='food-med-sub2'>
    <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>

<div  className='offer-tag-view-pdt'>
<img src={offerTag} alt="offerTag" />
<div className='centered-view-pdt'>{viewProduct?.Products?.OfferPercentage}% <br/>Off</div>
</div>
<img onClick={()=>handleShare()} className='share-img' src={share} alt="share" />
</div>
      <div className='viewImg'>
        {/* {
          viewProduct?.productImagemodel?.map((item)=>{
            return(
              <img src={item.url} alt="" />
            )
          })
        } */}

     <img  src={viewProduct?.Products?.productImages?.[0]?.url} alt="pdtImg" />

      </div>
      
      
      <p>{viewProduct?.Products?.ProductName}</p>

      <div>
        {
          viewProduct?.ProductCategory=='food' ?
      <select className='product-unit' name="unit" id="unit"  onChange={handleProductChange}>
<option value="" selected disabled>{viewProduct?.Products?.productvariants?.[0]?.unit} </option>
{viewProduct?.Products?.productvariants?.map((item,index) => (
  <option key={index} className="product-unit-option" value={item?.price} data-unit={item?.unit}>
    {item?.unit} 
  </option>
))}
</select> : ""
        }

       

      <div style={{ display:'flex', justifyContent:'space-around',marginTop:'-20px' }}>
        <div className='pdt-details'>
          <p style={{paddingTop:'10px'}} onClick={()=>setreviewLine(false)}>Details</p>
        </div>
        {/* <div className='pdt-review'>
          <p onClick={()=>setreviewLine(true)}>Reviews</p>
        </div> */}
      </div>
      <div style={{ display:'flex',justifyContent:'center' }}>
      <div class={`${reviewLine ? 'half-colored-line-review' : 'half-colored-line-details'}`}></div>      
      </div>
      <div className='pdt-description'>
      <p>{viewProduct?.Products?.Description}</p>
      </div>
       
      <div className='viewPdt-cart'>
      <div className='view-details'>
        <strike style={{color:'rgb(149 144 144)'}}>
        <div style={{display:'flex',alignItems:'center'}}>
        <BsCurrencyRupee style={{fontSize:'14px'}} />
         <p style={{color:'rgb(149 144 144)'}}> {viewProduct?.Products?.productvariants?.[0]?.price  } </p>
          </div>
        </strike>
        &ensp;
       <div style={{display:'flex',alignItems:'center'}}>
         <BsCurrencyRupee style={{fontSize:'14px'}} />
         
         <p style={{color:'black'}}> {viewProduct?.Products?.Productamount?.[0]?.amount   ?  ((selectedUnitPrice.amount) - (Math.floor((selectedUnitPrice.amount) * ((viewProduct?.Products?.OfferPercentage) / 100))) - 2)    :  ((selectedUnitPrice) - (Math.floor((selectedUnitPrice) * ((viewProduct?.Products?.OfferPercentage) / 100))) - 2)}</p>
       </div>
      </div>
      <div style={{display:'flex',gap:'5px'}}>
      <div className='wishlist'><FaHeart style={{ color:'red' }} /></div>
      <button onClick={()=>addtocart(viewProduct?.Products?.quantity,viewProduct?.Products?.id,viewProduct?.ProductCategory)} >
        <FaCartShopping />
        <p>Add</p>
        </button>
      </div>

      </div>
      </div>

      </div>
      </>
    )
  }
  const renderProductLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'8px'}} width={650} height={80} />
    </div>
  );

  const renderViewPdtLoadingSkeleton = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Skeleton style={{margin:'5px',borderRadius:'15px'}} width={300} height={450} />
    </div>
  );
  return (
    <>
    {
      wishlistProducts?.length!=0 ?
      <div className='food-med-main'>
    <div className='food-med-sub1' >
      
      
      <div className='wl-pdts'>
       <div className='wl-head'>
         <div>
       <p>Wishlist</p>
        <FaHeart  style={{ color:'red' }} />
         </div>

        </div>
          <> 
          {loading ?
              wishlistProducts.map(()=>{
              return(
              renderProductLoadingSkeleton() 
               )
                                  
               })
               : renderProducts()}
          </>
      </div>
    </div>
    {loading ?
      renderViewPdtLoadingSkeleton() 
      : renderViewProduct()}
  <Modal  open={openAddtocart}  >
          <ModalDialog sx={{display:'flex' ,flexDirection:'column',alignItems:'center', fontFamily:'monospace',borderRadius:'15px',padding:"35px"}} size={'sm'}>
            {/* <ModalClose /> */}
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', gap:'10px'}}>
            {
              value=='Added to cart' ?
              <img style={{height:'20vh',objectFit:'contain'}} src={successGif} alt="successGif" />
              :
              <img style={{height:'20vh',objectFit:'contain'}} src={exclamation} alt="exclamation" />
            }
            <p style={{textAlign:'center',fontWeight:'600',fontFamily:'Poppins',margin:'0',fontSize:'large'}}>{value}</p>
            </div>
          </ModalDialog>
        </Modal>
  </div>
      : 
      <div className='empty-cart'>

          <img src={cartEmpty} alt="" />
          <p>Oops, your Wishlist is empty.</p>
          </div>
    }
     {
      open ?
      <Auth open={open} handleClose={setOpen} /> 
      :''
    }
  </>
  )
}

export default Wishlist
