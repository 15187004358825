import React, { useEffect, useState } from "react";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import axios from "../../axios";
import { useSelector } from "react-redux";
import Failedgif from "../Failed/Failedgif";
import alternateImg from "../../assets/animated-dog.avif";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { CgDanger } from "react-icons/cg";
import { Loading } from "../../Components/Loading/Loading";
import { HomePageModal } from "../Homepage/HomePageModal/HomePageModal";
import Cookies from "js-cookie";

export default function LostFound({ switchToFound, setSwitchToFound }) {
  const loginToken = Cookies.get("loginToken");

  const { login } = useSelector((state) => state);
  const [lostFoundLoading, setLostFoundLoading] = useState(false);
  const [lostPets, setLostPets] = useState([]);
  const [foundPets, setFoundPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [modal, setModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const isSearchActive = searchTerm.trim().length > 0;

  const handleSearch = async (value) => {
    setSearchTerm(value);
    if (value.trim()) {
      setSearchLoading(true);
      setSearchError(null);

      try {
        console.log("Searching with:", {
          petName: value.trim(),
          active: switchToFound ? "found" : "lost",
        });

        const response = await axios.get("/nfc/searchlostfound", {
          params: {
            petName: value.trim(),
            active: switchToFound ? "found" : "lost",
          },
          headers: {
            Authorization: loginToken,
          },
        });

        console.log("Search Response:", response.data);
        setSearchResults(response.data.message || []);
      } catch (error) {
        console.error("Search error:", error);
        setSearchError(
          error.response?.data?.message || "Failed to search pets"
        );
        setSearchResults([]);
      } finally {
        setSearchLoading(false);
      }
    } else {
      setSearchResults([]);
      setSearchError(null);
    }
  };

  // Trigger search when toggle changes
  useEffect(() => {
    if (searchTerm) {
      handleSearch(searchTerm);
    }
  }, [switchToFound]);

  // Debounced search effect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        handleSearch(searchTerm);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    const fetchLostPetInfo = async () => {
      try {
        const response = await axios.get("/nfc/lostedpets", {
          headers: {
            Authorization: loginToken,
          },
        });
        setLostPets(response.data.message);
        console.log("Lost Pets:", response.data.message);
      } catch (error) {
        console.error("Error fetching lost pet info:", error);
      }
    };

    const fetchFoundPetInfo = async () => {
      try {
        const response = await axios.get("/nfc/foundedpets", {
          headers: {
            Authorization: loginToken,
          },
        });
        setFoundPets(response.data.message);
        console.log("Found Pets:", response.data.message);
      } catch (error) {
        console.error("Error fetching found pet info:", error);
      }
    };
    const fetchData = async () => {
      setLostFoundLoading(true); // Set loading to true before fetching
      await Promise.all([fetchLostPetInfo(), fetchFoundPetInfo()]);

      setTimeout(() => {
        setLostFoundLoading(false); // Set loading to false after fetching
      }, 300);
    };

    fetchData();
  }, [loginToken]);
  const handleModalOpening = (pet) => {
    setSelectedPet(pet);
    setModal(!modal);
  };

  const renderPets = (pets) => {
    const petsToRender = isSearchActive ? searchResults : pets;

    if (searchLoading) {
      return (
        <div className="flex justify-center items-center py-4">
          <Loading color="#eee" size="50px" />
        </div>
      );
    }
    if (searchError) {
      return <div className="text-red-500 text-center py-4">{searchError}</div>;
    }

    if (isSearchActive && petsToRender.length === 0) {
      return (
        <div>
          <Failedgif message="No pets found matching your search." />
        </div>
      );
    }

    if (!isSearchActive && petsToRender.length === 0) {
      return (
        <div>
          <Failedgif message="No pets available." />
        </div>
      );
    }

    return petsToRender.map((data, index) => {
      console.log(`Rendering pet ${index}:`, data);
      const date = data.foundstatus
        ? new Date(data.foundDate)
        : new Date(data.updatedAt);
      const formattedDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
      const statusText = data.foundstatus
        ? `Found on ${formattedDate}`
        : `Lost on ${formattedDate}`;
      const lastSeen = data.lastseen || "Never seen";
      const animationDelay = `${index * 0.3}s`;
      const statusClass = data.foundstatus
        ? "found-status-text-nologin"
        : "lost-status-text-nologin";
      const iconClass = data.foundstatus
        ? "found-info-icon-nologin"
        : "info-icon-nologin";
      return (
        <div
          className="lost-found-card-nologin"
          key={data?.id}
          style={{ "--animation-delay": animationDelay }}
        >
          <div
            className="left-img-card-nologin"
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            onClick={() => handleModalOpening(data)}
          >
            <img
              src={data?.petinfo?.petProfileImage || alternateImg}
              alt={data?.petinfo?.petName}
            />
          </div>
          <div
            className="left-content-card-nologin"
            onClick={() => handleModalOpening(data)}
          >
            <h3>{data?.petinfo?.petName}</h3>
            <p className={statusClass}>
              {statusText} &ensp;
              {lastSeen}
              {data?.foundstatus ? (
                <IoCheckmarkDoneCircle className={iconClass} />
              ) : (
                <CgDanger className={iconClass} />
              )}
            </p>
          </div>
          <div className="bg-white px-[.8vw] py-[1vw] rounded-[.75vw]">
            <DogPettingIcon name="map-nologin" />
          </div>
        </div>
      );
    });
  };
  return (
    <div>
      <div className="relative w-full px-[1vw] py-[4vh]">
        <span className="absolute inset-y-0 left-[2vw] flex items-center text-gray-500">
          <DogPettingIcon name="lostfound-search-icon" />
        </span>

        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full bg-white text-[#616161] rounded-[.75vw] py-[1.5vh] px-[3.5vw] border-none outline-none shadow-[0px_.2vw_10vw_0px_rgba(0,0,0,0.25)]"
          placeholder="Search"
        />
      </div>
      <div
        className={`live-past-sessions-toggle ${
          switchToFound ? "past-tab-active" : ""
        }`}
      >
        <>
          <button
            className={!switchToFound ? "active" : ""}
            onClick={() => setSwitchToFound(false)}
          >
            <DogPettingIcon name={"Lost"} active={switchToFound} />
            Lost Pets
          </button>
          <button
            className={switchToFound ? "active" : ""}
            onClick={() => setSwitchToFound(true)}
          >
            <DogPettingIcon name={"Found"} active={!switchToFound} />
            Found Pets
          </button>
        </>
      </div>
      <div className="lostfound-nologin-section">
        <div>
          {lostFoundLoading && !searchLoading ? (
            <Loading color="#eee" size="50px" />
          ) : (
            <div className="lost-found-data">
              {switchToFound ? renderPets(foundPets) : renderPets(lostPets)}
            </div>
          )}
          {modal && (
            <HomePageModal
              isClose={handleModalOpening}
              className={selectedPet?.foundstatus ? "found" : "lost"}
              isOpen={modal}
              headerText={
                selectedPet?.foundstatus
                  ? "I’m Reunited with my owner, thank you!"
                  : "I’m Lost! Please help shiro find his owner"
              }
              header={true}
              aboutDiv={true}
              petInfo={selectedPet}
            />
          )}
        </div>
      </div>
    </div>
  );
}
