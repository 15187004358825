import React, { useState, useImperativeHandle, forwardRef } from "react";
import "./OTPInput.css";

export const OTPInput = forwardRef(({ length, onChange }, ref) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));

  useImperativeHandle(ref, () => ({
    validate: () => {
      return otp.every((digit) => digit !== "");
    },
    getOtp: () => otp.join(""),
  }));

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length === 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      onChange(newOtp.join(""));
      // Move focus to the next input
      if (index < length - 1) {
        e.target.nextSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      onChange(newOtp.join(""));
      // Move focus to the previous input
      if (index > 0) {
        e.target.previousSibling.focus();
      }
    }
  };

  return (
    <div className="otp-input">
      {otp.map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={otp[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
        />
      ))}
    </div>
  );
});
