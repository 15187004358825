import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import { OTPInput } from "./OTPInput";
import { ChangePassword } from "./ChangePassword";
import axios from "../../../../axios";
import Cookies from "js-cookie";

export const EnterOTP = ({
  isOpen,
  isClose,
  type,
  numberMessage,
  emailMessage,
  setOpenEnterOTP,
  setOpenChangePasswordSetting,
  userData,
}) => {
  const loginToken = Cookies.get("loginToken");

  const [otp, setOtp] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [error, setError] = useState(false);
  const [resendTimer, setResendTimer] = useState(90);
  const [resendAvailable, setResendAvailable] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);

  useEffect(() => {
    if (!resendAvailable) {
      const timer = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(timer);
            setResendAvailable(true);
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendAvailable]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 3000); // Error message will disappear after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    setError(false);
    if (newOtp.length === 6) {
      handleVerifyOtp(newOtp);
    }
  };

  const toggleChangePasswordModal = () => {
    setChangePasswordModal((prev) => !prev);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleVerifyOtp = async (otpToVerify = otp) => {
    try {
      let response;
      if (type === "mobile") {
        response = await axios.post(
          "/auth/verifyOtpMobile",
          { otp: otpToVerify },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
      } else if (type === "email") {
        response = await axios.post(
          "/auth/verifyOtpEmail",
          { otp: otpToVerify },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
      }

      if (response?.data?.status === 200) {
        toggleChangePasswordModal();
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      console.error("Error verifying OTP:", error);
    }
  };

  const handleGetCodeClick = async () => {
    try {
      let response;
      if (type === "mobile") {
        response = await axios.get("/auth/sendOtpMobile", {
          headers: {
            Authorization: loginToken,
          },
        });
      } else if (type === "email") {
        response = await axios.get("/auth/sendOtpEmail", {
          headers: {
            Authorization: loginToken,
          },
        });
      }
      if (response?.data?.status === 200) {
        setResendAvailable(false);
        setResendTimer(90);
      } else {
        console.error("Failed to send OTP:", response);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <>
      <div
        className={`modal-overlay2 ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container2 ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>Change Password</p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="get-code-about">
              {type === "mobile" ? (
                <p>{numberMessage}</p>
              ) : (
                <p>{emailMessage}</p>
              )}
            </div>
            <div className="code-mail-number">
              <p>
                {type === "mobile" ? `+91 ${userData.mobile}` : userData.email}
              </p>
            </div>
            <div className="otp-box">
              <div className="otp">
                <OTPInput length={6} onChange={handleOtpChange} />
                <span>
                  Didn’t receive any OTP?{" "}
                  {resendAvailable ? (
                    <b
                      onClick={handleGetCodeClick}
                      style={{ cursor: "pointer" }}
                    >
                      Resend Now
                    </b>
                  ) : (
                    <b>{`Resend in ${resendTimer}s`}</b>
                  )}
                </span>
                {error && (
                  <p className="error-message error-animation">
                    Invalid OTP. Please try again.
                  </p>
                )}
              </div>
            </div>
            <div className="modal-control">
              <button onClick={() => handleVerifyOtp()}>
                <DogPettingIcon name={"accept"} />
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>

      {changePasswordModal && (
        <ChangePassword
          isClose={toggleChangePasswordModal}
          isOpen={changePasswordModal}
          setOpenEnterOTP={setOpenEnterOTP}
          setOpenChangePasswordSetting={setOpenChangePasswordSetting}
          userData={userData}
        />
      )}
    </>
  );
};
