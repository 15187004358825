import { createContext, useContext, useState, useEffect } from "react";
import axios from "../../axios";
import Cookies from "js-cookie";

const initialState = {
  cartData: [],
  pharmacyCartData: [],
  cartItemIds: [],
  pharmacycartItemIds: [],
  totalDiscount: 0,
  ActiveTab: "cart",
  PrescriptionFile: [],
  cartTotal: null,
  pharmacyCartTotal: null,
  selectedPetIds: [],
  cartCount: 0,
  pharmacyCount: 0,
  paraVetChecked: [],
  selectedVaccineDate: null,
  setSelectedVaccineDate: null,
  selectedVaccineTimeSlot: null,
  setSelectedVaccineTimeSlot: null,
  handleSelectedPetIds: () => {},
  handleQuantityCartChange: () => {},
  fetchCartItems: () => {},
  calculateTotalDiscount: () => {},
  groupItemsByCategory: () => {},
  fetchPharmacyCartItems: () => {},
  calculateCartTotal: () => {},
  calculatePharmacyCartTotal: () => {},
  getItemsByCategory: () => {},
  getCategories: () => {},
  refreshCart: () => {},
  handleActiveTab: () => {},
  handlePrescriptionFile: () => {},
  handleParaVetChecked: () => {},
};
const ShopContext = createContext(initialState);

export default function ShopContextProvider({ children }) {
  const loginToken = Cookies.get("loginToken");
  const [cartData, setCartData] = useState([]);
  const [pharmacyCartData, setPharmacyCartData] = useState([]);
  const [cartItemIds, setCartItemIds] = useState([]);
  const [pharmacycartItemIds, setpharmacycartItemIds] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [ActiveTab, setActiveTab] = useState("cart");
  const [PrescriptionFile, setPrescriptionFile] = useState([]);
  const [cartTotal, setCartTotal] = useState(null);
  const [pharmacyCartTotal, setPharmacyCartTotal] = useState(null);
  const [selectedPetIds, setSelectedPetIds] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [pharmacyCount, setPharmacyCount] = useState(0);
  const [selectedVaccineDate, setSelectedVaccineDate] = useState("");
  const [selectedVaccineTimeSlot, setSelectedVaccineTimeSlot] = useState("");
  const [paraVetChecked, setParaVetChecked] = useState([]);

  const handleParaVetChecked = (checked) => {
    setParaVetChecked(checked);
    console.log(checked, "para vet checked");
  };

  const handleSelectedPetIds = (petIds) => {
    setSelectedPetIds(petIds);
    console.log(petIds, "petid for vaccine ");
  };
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };
  const handlePrescriptionFile = (file) => {
    setPrescriptionFile(file);
    // console.log(file, "file");
  };
  const fetchCartCount = async () => {
    try {
      const response = await axios.get("/shop/generalproductscartcount", {
        headers: {
          Authorization: loginToken,
        },
      });
      setCartCount(response.data);
      console.log("Cart count:", response.data);
    } catch (err) {
      console.log("Error fetching cart count:", err);
    }
  };
  const fetchPharmacyCount = async () => {
    try {
      const response = await axios.get("/shop/pharmacyproductscartcount", {
        headers: {
          Authorization: loginToken,
        },
      });
      setPharmacyCount(response.data);
      console.log("Pharmacy cart count:", response.data);
    } catch (err) {
      console.log("Error fetching cart count:", err);
    }
  };
  const fetchCartItems = async () => {
    try {
      const response = await axios.get("/shop/listgeneralcartitems", {
        headers: {
          Authorization: loginToken,
        },
      });
      console.log("Cart data:", response.data.message);
      const groupedData = groupItemsByCategory(response.data.message);
      setCartData(groupedData);
      console.log("Cart data:", groupedData);
      const ids = response.data.message.map((item) => item.id);
      setCartItemIds(ids);
      console.log("Cart item IDs:", ids);
      calculateTotalDiscount(response.data.message);
    } catch (err) {
      console.log("Error fetching cart data:", err);
    }
  };
  const fetchPharmacyCartItems = async () => {
    try {
      const response = await axios.get("/shop/listpharmacycartitems", {
        headers: {
          Authorization: loginToken,
        },
      });
      console.log(response.data.message, "pharmacy cart data");
      const groupedData = groupItemsByCategory(response.data.message);
      setPharmacyCartData(groupedData);

      console.log("pharmacy group cart data:", groupedData);
      const ids = response.data.message.map((item) => item.id);
      setpharmacycartItemIds(ids);
      console.log("pharmacy Cart item IDs:", ids);
      calculateTotalDiscount(response.data.message);
    } catch (err) {
      console.log("Error fetching cart data:", err);
    }
  };

  useEffect(() => {
    fetchCartItems();
    fetchPharmacyCartItems();
    fetchCartCount();
    fetchPharmacyCount();
  }, [loginToken]);

  const groupItemsByCategory = (items) => {
    try {
      // Input validation
      if (!Array.isArray(items)) {
        console.warn("Invalid input: items must be an array");
        return {};
      }

      return items.reduce((acc, item) => {
        // Safely access category, handling potential undefined values
        const category = item?.Products?.Category || "Uncategorized";

        // Initialize category array if it doesn't exist
        if (!acc[category]) {
          acc[category] = [];
        }

        // Add the full item to the appropriate category array
        acc[category].push({
          ...item,
          category: category, // Adding category for easier reference
        });

        return acc;
      }, {});
    } catch (error) {
      console.error("Error grouping items by category:", error);
      return {};
    }
  };
  const getItemsByCategory = (groupedData, category) => {
    return groupedData[category] || [];
  };

  // Helper function to get all available categories
  const getCategories = (groupedData) => {
    return Object.keys(groupedData);
  };

  const calculateTotalDiscount = (items) => {
    const discount = items.reduce((total, item) => {
      const originalPrice =
        (item.productvariant.price / item.quantity) *
        (1 / (1 - (item.productvariant.OfferPercentage || 0) / 100));
      return (
        total + (originalPrice * item.quantity - item.productvariant.price)
      );
    }, 0);
    setTotalDiscount(discount);
    console.log("Total Discount:", discount);
    return discount;
  };
  const calculateDiscountedPrice = (item) => {
    console.log(item.quantity, "item for discount");
    return item.productvariant.price * item.quantity;
  };

  const calculateCartTotal = () => {
    const Total = Object.values(cartData)
      .flat()
      .reduce(
        (total, item) => total + parseFloat(calculateDiscountedPrice(item)),
        0
      );
    setCartTotal(Total);
    console.log("Total:", Total);
    return Total;
  };

  const calculatePharmacyCartTotal = () => {
    const pharmacyTotal = Object.values(pharmacyCartData)
      .flat()
      .reduce(
        (total, item) => total + parseFloat(calculateDiscountedPrice(item)),
        0
      );
    setPharmacyCartTotal(pharmacyTotal);
    return pharmacyTotal;
  };

  const handleQuantityCartChange = async (id, delta) => {
    const category = Object.keys(cartData).find((cat) =>
      cartData[cat].some((item) => item.id === id)
    );
    if (!category) return;

    const item = cartData[category].find((item) => item.id === id);
    if (!item) return;

    const newQuantity = Math.max(0, parseInt(item.quantity) + parseInt(delta));

    if (newQuantity === 0) {
      try {
        // API call to delete the item from the cart
        await axios.put(
          `/shop/deletecart/${id}`,
          {},
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        console.log("Item removed from cart:", id);

        // Update the local state after successful deletion
        const newCartData = { ...cartData };
        newCartData[category] = newCartData[category].filter(
          (item) => item.id !== id
        );
        if (newCartData[category].length === 0) {
          delete newCartData[category];
        }
        setCartData(newCartData);
        setCartItemIds(cartItemIds.filter((itemId) => itemId !== id));
        setpharmacycartItemIds(
          pharmacycartItemIds.filter((itemId) => itemId !== id)
        );
        calculateTotalDiscount(Object.values(newCartData).flat());
      } catch (error) {
        console.error("Error removing item from cart:", error);
      }
    } else {
      try {
        // Update the quantity in the backend via API call

        // Update local state
        const newCartData = { ...cartData };
        const updatedCartItems = newCartData[category].map((cartItem) => {
          if (cartItem.id === id) {
            const basePrice = cartItem.productvariant.price / cartItem.quantity;
            const newPrice = basePrice * newQuantity;
            const discount = cartItem.productvariant.OfferPercentage || 0;
            // const discountedPrice = newPrice * (1 - discount / 100);

            return {
              ...cartItem,
              quantity: newQuantity,
              productvariant: {
                ...cartItem.productvariant,
                price: newPrice,
              },
            };
          }
          return cartItem;
        });
        newCartData[category] = updatedCartItems;
        setCartData(newCartData);
        calculateTotalDiscount(Object.values(newCartData).flat());

        const response = await axios.post(
          "/shop/updatetocart",
          {
            ProductId: item.Products.id,
            quantity: newQuantity,
            variantId: item.productvariant.id,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );

        // Log updated item details
        // const updatedItem = newCartData[category].find(
        //   (item) => item.id === id
        // );
        if (response.data.status === 200) {
          console.log(
            "Item quantity updated in cart:",
            item.Products.id,
            newQuantity,
            item
          );
        }
      } catch (error) {
        console.error("Error updating cart quantity:", error);
        // Optionally add error handling UI feedback here
      }
    }
  };

  const handleQuantityPharmacyCartChange = async (id, delta) => {
    const category = Object.keys(pharmacyCartData).find((cat) =>
      pharmacyCartData[cat].some((item) => item.id === id)
    );
    if (!category) return;

    const item = pharmacyCartData[category].find((item) => item.id === id);
    if (!item) return;

    const newQuantity = Math.max(0, parseInt(item.quantity) + parseInt(delta));

    if (newQuantity === 0) {
      try {
        // Update UI first
        const newPharmacyCartData = { ...pharmacyCartData };
        newPharmacyCartData[category] = newPharmacyCartData[category].filter(
          (item) => item.id !== id
        );
        if (newPharmacyCartData[category].length === 0) {
          delete newPharmacyCartData[category];
        }
        setPharmacyCartData(newPharmacyCartData);
        setCartItemIds(cartItemIds.filter((itemId) => itemId !== id));
        setpharmacycartItemIds(
          pharmacycartItemIds.filter((itemId) => itemId !== id)
        );
        calculateTotalDiscount(Object.values(newPharmacyCartData).flat());

        // Then make API call
        await axios.put(
          `/shop/deletecart/${id}`,
          {},
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
      } catch (error) {
        console.error("Error removing item from cart:", error);
        // Optionally revert the UI changes if API call fails
      }
    } else {
      try {
        // Update UI immediately
        const newPharmacyCartData = { ...pharmacyCartData };
        const updatedCartItems = newPharmacyCartData[category].map(
          (cartItem) => {
            if (cartItem.id === id) {
              const basePrice = cartItem.productvariant.price;
              const productTotalPrice = basePrice * newQuantity;
              return {
                ...cartItem,
                quantity: newQuantity,
                productTotalPrice: productTotalPrice,
                productvariant: {
                  ...cartItem.productvariant,
                },
              };
            }
            return cartItem;
          }
        );

        newPharmacyCartData[category] = updatedCartItems;
        setPharmacyCartData(newPharmacyCartData);
        calculateTotalDiscount(Object.values(newPharmacyCartData).flat());

        // Then make API call
        const response = await axios.post(
          "/shop/updatetocart",
          {
            ProductId: item.Products.id,
            quantity: newQuantity,
            variantId: item.productvariant.id,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );

        if (response.data.status === 200) {
          // Update any additional data from API response if needed
          console.log(
            "Item quantity updated in cart:",
            item.Products.id,
            newQuantity,
            item
          );
        }
      } catch (error) {
        console.error("Error updating cart quantity:", error);
        // Optionally revert the UI changes if API call fails
      }
    }
  };

  const value = {
    cartData,
    pharmacyCartData,
    cartItemIds,
    pharmacycartItemIds,
    totalDiscount,
    ActiveTab,
    PrescriptionFile,
    cartTotal,
    pharmacyCartTotal,
    selectedPetIds,
    cartCount,
    pharmacyCount,
    selectedVaccineDate,
    selectedVaccineTimeSlot,
    paraVetChecked,
    handleParaVetChecked,
    setSelectedVaccineDate,
    setSelectedVaccineTimeSlot,
    handleSelectedPetIds,
    handleQuantityCartChange,
    handleQuantityPharmacyCartChange,
    fetchCartItems,
    calculateTotalDiscount,
    groupItemsByCategory,
    fetchPharmacyCartItems,
    calculateCartTotal,
    calculatePharmacyCartTotal,
    handleActiveTab,
    handlePrescriptionFile,
  };

  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>;
}

export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error("useShop must be used within a ShopProvider");
  }
  return context;
};
