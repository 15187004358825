import React from "react";
import Icons from "../../../../context/Icons";
import { useNavigate } from "react-router-dom";

export default function ShopSection() {
  const navigate = useNavigate();
  const handlestore = () => {
    navigate("/store");
  };
  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-start  gap-[5vw] md:gap-[6vw] xl:gap-[15vw] items-center justify-center h-[100vh] w-full  m-auto bg-black text-white">
      <h1 className=" md:hidden text-[clamp(1.5rem,4.5vw,8rem)] text-center capitalize  md:text-[clamp(1.5rem,3vw,8rem)] font-[Prata] font-[400]">
        Everything your pet needs to thrive.
      </h1>
      <img
        className="md:hidden w-[55vw]  object-contain ml-auto mr-auto "
        src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/shop-mobile-image.webp"
        alt="Frame1597885025"
        title="Shop Img"
      />
      <img
        className="hidden md:block md:h-[75vh] md:self-end   xl:h-[100vh] object-contain  "
        src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/shop-image.webp"
        alt="Frame1597885025"
        title="Shop Img"
      />
      {/* <h1 className=" hidden text-[clamp(1.5rem,2vw,8rem)] text-center  md:text-[clamp(1.5rem,3vw,8rem)] font-[Prata] font-[400]">
        Everything your pet needs to thrive.
      </h1> */}
      <div className="flex flex-col gap-[4vh] items-center justify-center md:items-start md:justify-start w-[45vw] md:mr-[3vw]">
        <h1 className="hidden md:block text-[clamp(1.5rem,2vw,8rem)] text-center md:text-[clamp(1.5rem,4vw,8rem)]  xl:text-[clamp(1.5rem,3.5vw,8rem)] font-[Prata] font-[400] md:text-start capitalize leading-[1.2]">
          Everything your pet needs to thrive.
        </h1>
        <p className="text-[clamp(.8rem,1.1vw,3rem)] font-[Poppins] font-[400] w-[85vw] md:w-[auto]  text-center md:text-start ">
          We're dedicated to improving the lives of pets everywhere. With
          innovative products like our Echo device, we help pets stay healthy,
          happy, and thriving. Join us in making a real difference for pets and
          their owners. Together, we can ensure every pet lives their best life.
        </p>
        <button
          onClick={handlestore}
          className=" flex items-center text-[clamp(1rem,1.2vw,3.6rem)] font-[Prata] px-[4vw] py-[1.2vw] md:px-[2vw] md:py-[.8vw] bg-white text-black font-[500] rounded-[3.5vw] md:rounded-[1.5vw]"
        >
          Shop Now &nbsp;
          <Icons name="shop-button-direction" />
        </button>
      </div>
    </div>
  );
}
