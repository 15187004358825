import React, { useEffect, useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import "./ProductActions.css";
import Cookies from "js-cookie";
import axios from "../../../axios";
import Failedgif from "../../../Pages/Failed/Failedgif";
import ShopGif from "../../components/ShopGif/ShopGifs";
import { useDispatch, useSelector } from "react-redux";
import VaccinePet from "../VaccinePet";

export default function WishlistItems({ items, isClose, product }) {
  const [wishlistData, setWishlistData] = useState(items);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [activeVariantIndex, setActiveVariantIndex] = useState(0);
  const loginToken = Cookies.get("loginToken");
  const dispatch = useDispatch();
  const [showSuccessGif, setShowSuccessGif] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [addToCartError, setAddToCartError] = useState(null);
  const [selectPetForVaccine, setSelectPetForVaccine] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { login } = useSelector((state) => state);
  let petInfo;
  {
    login?.login && (petInfo = login.details.petInfo);
  }

  const [gifDetails, setGifDetails] = useState({
    gif: "",
    message: "",
  });

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleGifClose = () => {
    setShowSuccessGif(false);
  };

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const response = await axios.get("/shop/wishlistproducts", {
          headers: {
            Authorization: loginToken,
          },
        });
        console.log("Wishlist data:", response.data.message);

        const initializedData = response.data.message.map((item) => {
          const defaultOption = item.Products.productvariants[0];
          const originalPrice = parseFloat(defaultOption.price);
          const discount =
            (originalPrice * (defaultOption.OfferPercentage || 0)) / 100;
          const discountedPrice = originalPrice - discount;

          return {
            ...item,
            selectedWeight: defaultOption.unit,
            originalPrice,
            discount,
            discountedPrice,
          };
        });

        setWishlistData(initializedData);
      } catch (error) {
        console.error(
          "Error fetching Wishlist data:",
          error.response?.data || error.message
        );
      }
    };

    fetchCartData();
  }, [loginToken]);

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 300);
  };

  const handleWeightChange = (id, selectedWeight) => {
    const newWishData = wishlistData?.map((item) => {
      if (item.id === id) {
        const weightOption = item.Products.productvariants.find(
          (option) => option.unit === selectedWeight
        );

        const originalPrice = parseFloat(weightOption.price);
        const discount =
          (originalPrice * (weightOption.OfferPercentage || 0)) / 100;
        const discountedPrice = originalPrice - discount;

        return {
          ...item,
          selectedWeight,
          originalPrice,
          discount,
          discountedPrice,
        };
      }
      return item;
    });

    setWishlistData(newWishData);
  };

  const deleteWishlist = async (itemId) => {
    try {
      const response = await axios.delete(`/shop/removewishlistpdt/${itemId}`, {
        headers: {
          Authorization: loginToken,
        },
      });
      if (response.data.status) {
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/store/remove-wishlist.png",
          message: "Removed from wishlist Successfully!",
        });
        setShowSuccessGif(true);
      }
      setWishlistData((prevData) =>
        prevData.filter((item) => item.ProductId !== itemId)
      );
      console.log(response.data,"wishlist deleted");
    } catch (error) {
      console.error(
        "Error deleting Wishlist item:",
        error.response?.data || error.message
      );
      setError("Failed to delete the item. Please try again.");
    }
  };

  const handlePetVaccine = (item) => {
    setSelectedProduct(item.Products);
    setSelectPetForVaccine(true);
  };

  const handleAddToCart = async (item) => {
    setIsAddingToCart(true);
    setAddToCartError(null);

    try {
      // Find the variant index based on selected weight
      const variantIndex = item.Products.productvariants.findIndex(
        (variant) => variant.unit === item.selectedWeight
      );

      const response = await axios.post(
        "/shop/addtocart",
        {
          quantity: quantity,
          ProductId: item.ProductId,
          variantId: item.Products.productvariants[variantIndex].id,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );

      if (response.data.status === 200) {
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif",
          message: "Added to Cart Successfully!",
        });
      } else if (response.data.status === 500) {
        setGifDetails({
          gif: "https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp",
          message: "Product is already in cart.",
        });
      }
      console.log("Add to cart response:", response.data);
      dispatch({ type: "refresh" });
      setShowSuccessGif(true);
    } catch (error) {
      console.error("Error adding product to cart:", error);
      setAddToCartError("Failed to add product to cart. Please try again.");
    } finally {
      setIsAddingToCart(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 modal-overlay z-[9999]
      ${isModalClosing ? "opacity-0 fade-out" : "opacity-100"}`}
      onClick={handleOverlayClick}
    >
      <div
        className={`modal-container
        ${isModalClosing ? "scale-95 opacity-0 close" : "scale-100 opacity-100"}`}
      >
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"shop-wishlistbutton-nonactiveicon"} /> Wishlist
            </p>
          </div>

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="shopcart-item p-[1vw]">
          <div className="shop-cart-items">
            {wishlistData?.length === 0 ? (
              <Failedgif message="No items in the Wishlist" />
            ) : (
              wishlistData?.map((item) => (
                <div className="shop-cart-item relative" key={item.id}>
                  <div className="shop-cart-item-image">
                    <img
                      src={item.Products.productImages[0]?.url}
                      alt={item.Products.ProductName}
                    />
                  </div>
                  <div className="shop-cartitemdetails">
                    <div>
                      <h3>{item.Products.ProductName}</h3>
                      <p>{item.Products.Description}</p>
                    </div>
                    <div className="shop-cart-item-price-quantity wishtocart-price">
                      <select
                        className="shop-cart-item-weight"
                        value={item.selectedWeight}
                        onChange={(e) =>
                          handleWeightChange(item.id, e.target.value)
                        }
                      >
                        {item.Products.productvariants.map((option) => (
                          <option key={option.unit} value={option.unit}>
                            {option.unit}
                          </option>
                        ))}
                      </select>
                      <p className="flex flex-row items-center gap-[.5vw]">
                        <span className="line-through">
                          ₹{item.originalPrice?.toFixed(2)}
                        </span>
                        <span className="text-black font-[550]">
                          ₹{item.discountedPrice?.toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    {item.ProductCategory === "Vaccine" && petInfo.length > 0 ? (
                      <div
                        className="bg-black text-white px-[.5vw] py-[1vw] rounded-[.8vw] flex items-center text-[clamp(.8rem,.8vw,2.5rem)] font-[550] cursor-pointer"
                        onClick={() => handlePetVaccine(item)}
                      >
                        <DogPettingIcon name={"shop-addcartwishlist"} /> &nbsp; Add
                      </div>
                    ) : (
                      <div
                        className="bg-black text-white py-[.5vw] px-[1vw] rounded-[.8vw] flex items-center text-[clamp(.8rem,.8vw,2.5rem)] font-[550] cursor-pointer"
                        onClick={() => handleAddToCart(item)}
                        style={{
                          cursor: isAddingToCart ? "not-allowed" : "pointer",
                        }}
                      >
                        <DogPettingIcon name={"shop-addcartwishlist"} /> &nbsp; Add
                      </div>
                    )}
                  </div>
                  <p
                    className="absolute top-[1vh] right-[.2vw] cursor-pointer"
                    onClick={() => deleteWishlist(item.ProductId)}
                  >
                    <DogPettingIcon name="remove-wishlist" />
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {showSuccessGif && (
        <ShopGif
          onClose={handleGifClose}
          message={gifDetails.message}
          gif={gifDetails.gif}
        />
      )}
      {selectPetForVaccine && (
        <VaccinePet
          isOpen={selectPetForVaccine}
          isClose={() => setSelectPetForVaccine(false)}
          product={selectedProduct}
        />
      )}
    </div>
  );
}