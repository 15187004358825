import React, { useEffect, useState } from "react";
import "./Companion.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { FaBirthdayCake } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import localImage from "../../../../../assets/animated-dog.avif";

const Companion = ({
  isOpen,
  onClose,
  onSubmit,
  content,
  redBubble,
  setcontent,
  userInfo,
  currentPetId,
  petInfo,
}) => {
  const loginToken = Cookies.get("loginToken");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [lastSeen, setLastSeen] = useState("");
  const [landmark, setLandmark] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setcontent(petInfo.loststatus === 0 ? "lost" : "found");
  }, [petInfo, setcontent]);

  useEffect(() => {
    let timer;
    if (isSubmitted) {
      timer = setTimeout(() => {
        setIsSubmitted(false);
        setcontent((prevContent) =>
          prevContent === "lost" ? "found" : "lost"
        );
        onClose();
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [isSubmitted, onClose, onSubmit, content, redBubble, setcontent]);

  if (!isOpen) return null;

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000);
  };

  const validateInputs = () => {
    if (!lastSeen || !landmark) {
      setError("Please fill in all fields.");
      setTimeout(() => setError(""), 3000);
      return false;
    }
    setError("");
    return true;
  };
  const handleInputChange = (e, setState) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    if (value.length > 30) {
      setError("Maximum length is 30 characters.");
      setTimeout(() => setError(""), 3000);
      return;
    }
    setState(sanitizedValue);
  };

  const handleSubmitLost = async () => {
    if (!validateInputs()) return;

    setIsSubmitted(true);
    const reportData = {
      petowner: userInfo.firstName,
      petId: currentPetId,
      lastseen: lastSeen,
      landmark: landmark,
      message: "Lost Pet",
    };

    try {
      const response = await axios.post("/nfc/addreport", reportData, {
        headers: { Authorization: loginToken },
      });

      if (response.data.status === 402) {
        setError("Report already exists.");
        setTimeout(() => setError(""), 3000);
        setIsSubmitted(false);
        return;
      }
      setLastSeen("");
      setLandmark("");
      setIsSubmitted(true);
    } catch (error) {
      setError(
        error.response
          ? error.response.data.message
          : "An unexpected error occurred."
      );
      setTimeout(() => setError(""), 3000);
      setIsSubmitted(false);
    }
  };
  const formatCurrentDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = now.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleSubmitFound = async () => {
    if (!validateInputs()) return;

    setIsSubmitted(true);
    const reportData = {
      petId: currentPetId,
      companionDetails: landmark,
      foundDate: formatCurrentDate(),
    };

    try {
      const response = await axios.patch("/nfc/updatefoundstatus", reportData, {
        headers: { Authorization: loginToken },
      });

      if (response.data.status === 402) {
        setError("Report already exists.");
        setTimeout(() => setError(""), 3000);
        setIsSubmitted(false);
        return;
      }
      setLastSeen("");
      setLandmark("");
      setIsSubmitted(true);
    } catch (error) {
      setError(
        error.response
          ? error.response.data.message
          : "An unexpected error occurred."
      );
      setTimeout(() => setError(""), 10000);
      setIsSubmitted(false);
    }
  };

  const getPetAge = (birthDate) => {
    const birth = dayjs(birthDate);
    const now = dayjs();
    const years = now.diff(birth, "year");
    const months = now.diff(birth.add(years, "year"), "month");
    return { years, months };
  };

  const trimBreed = (breed) => {
    return breed.length > 20 ? breed.slice(0, 20) + " .." : breed;
  };
  const trimName = (name) => {
    return name.length > 15 ? name.slice(0, 15) + " .." : name;
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-content">
          {isSubmitted ? (
            <div className="submitted-content">
              <h4>{content == "lost" ? "Submitted" : "Hooray!"}</h4>
              <h5>{content == "lost" ? "Look out for notifications!" : " "}</h5>
              <img
                src={
                  content == "lost"
                    ? "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                    : "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/horray.gif"
                }
                alt="Thank you"
              />
              <p>
                {content == "lost"
                  ? "When someone scans your companion tag, you’ll be notified"
                  : "We will notify the pet owner about their found companion"}
              </p>
            </div>
          ) : (
            <>
              <div key={petInfo.id}>
                <div className="lost-companion">
                  <img
                    src={petInfo.petProfileImage || localImage}
                    alt={petInfo.petName}
                    title={petInfo.petName}
                  />
                  <div className="modal-close" onClick={handleClose}>
                    <DogPettingIcon
                      name={"closemodal"}
                      className="modal-close-icon"
                    />
                  </div>
                </div>
                <div className="data-lostcompanion">
                  <div className="lost-companion-info">
                    <div className="lost-companion-pet-detail1">
                      <div className="lost-companion-name">
                        <h1>{trimName(petInfo.petName)}</h1>
                        <p>{trimBreed(petInfo.petBreed)}</p>
                      </div>
                      <div className="lost-companion-pet-detail2">
                        <div className="lost-companion-pet-detail2-genderpart">
                          <span>
                            <FaBirthdayCake className="cake-icon" />
                          </span>
                          <p>
                            {(() => {
                              const { years, months } = getPetAge(
                                petInfo.birthDate
                              );
                              return `${years} ${
                                years === 1 ? "yr" : "yrs"
                              } & ${months} ${months === 1 ? "mon" : "mon"}`;
                            })()}
                          </p>
                        </div>
                        <div className="lost-companion-pet-detail2-genderpart">
                          <span>
                            <BsGenderAmbiguous className="gender-icon" />
                          </span>
                          <p>{petInfo.sex}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="companion-info">
                    <h2>
                      {content == "lost"
                        ? "Lost Companion?"
                        : "Found Companion?"}
                    </h2>
                    <h3>
                      {content == "lost"
                        ? "Spread a word among the pet community"
                        : "Help reunite a lost companion"}
                    </h3>
                  </div>
                  <div className="companion-input-group">
                    <input
                      type="text"
                      placeholder={content == "lost" ? "Last Seen" : "Found At"}
                      value={lastSeen}
                      onChange={(e) => handleInputChange(e, setLastSeen)}
                    />
                    <input
                      type="text"
                      placeholder="Nearby Landmark"
                      value={landmark}
                      onChange={(e) => handleInputChange(e, setLandmark)}
                    />
                  </div>
                  <>
                    {content == "lost" ? (
                      <button onClick={handleSubmitLost}>
                        <DogPettingIcon name="Lost" className="submit-icon" />{" "}
                        Submit
                      </button>
                    ) : (
                      <button onClick={handleSubmitFound}>
                        <DogPettingIcon name="Found" className="submit-icon" />{" "}
                        Found
                      </button>
                    )}
                    {error && (
                      <p
                        className="error-message"
                        style={{ marginTop: "-.5vw" }}
                      >
                        {error}
                      </p>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Companion;
