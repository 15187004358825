import React from "react";
import Icons from "../../context/Icons";

export default function DownloadAppScreen() {
  const downloadApp = [
    {
      id: 1,
      icon: "download-app-apple-icon",
      title: "Download on the",
      storename: "App Store",
      link: "https://apps.apple.com/in/app/peteye/id6450916668",
    },
    {
      id: 2,
      icon: "download-app-google-icon",
      link: "https://play.google.com/store/apps/details?id=com.peteye.app&pli=1",
      title: "Get it on",
      storename: "Google Play ",
    },
  ];
  return (
    <div className="h-[100vh]  bg-white ">
      <div className=" flex flex-col items-center justify-center p-[1rem] -pt-[5vh] gap-[2vh]">
        <h1 className="text-[clamp(1.1rem,3.5vw,6rem)] text-center   font-[Prata] font-[450]">
          Enhance Your Experience Download Our App from the App Store or Play
          Store{" "}
        </h1>
        <img
          className="h-[50vh]"
          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landingpage-webp/blinking-eyephone.webp"
          alt="download peteye app"
        />
        <p className="text-[clamp(.8rem,2vw,4rem)] text-center   font-[Poppins] font-[400] text-[#464646]">
          PetEYE: Simplify pet care! Manage your pet's needs and access
          exclusive features for you and your furry friend. Download now on the
          App Store or Play Store!{" "}
        </p>
        <div className="flex flex-row items-center justify-center gap-[2vw]">
          {downloadApp.map((item) => {
            return (
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                className="flex flex-row items-center justify-start gap-[2vw] p-[2vw]  bg-[#FFF] shadow-[0px_.4vw_1.8vw_0px_rgba(0,0,0,0.25)] rounded-[10vw] w-[46vw] max-w-[230px] "
              >
                <Icons name={item.icon} />
                <div className="flex flex-col items-start">
                  <p className="text-[clamp(.6rem,2vw,4rem)] text-center   font-[Poppins] font-[400] text-[#464646]">
                    {item.title}
                  </p>
                  <p className="text-[clamp(.6rem,2vw,3.5rem)] text-center   font-[Poppins] font-[700] text-[#000]">
                    {item.storename}
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}
