import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import "./PetFamily.css";
import { InvitePetFamily } from "./InvitePetFamily";
import Delete from "../../../../../Components/Delete/Delete";
import axios from "../../../../../axios";
import Failedgif from "../../../../Failed/Failedgif";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

export default function PetFamily({ isOpen, onClose, pet }) {
  const [activeTab, setActiveTab] = useState("Family");
  const [inviteList, setInviteList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const loginToken = Cookies.get("loginToken");
  const [isClosing, setIsClosing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inviteResponse = await axios.get("/sharepet/invitesenduserlist", {
          headers: { Authorization: loginToken },
        });
        const communityResponse = await axios.get("/sharepet/communitylist", {
          headers: { Authorization: loginToken },
        });

        const filteredInviteList = inviteResponse.data.message.filter(
          (item) => item.PetId == pet.id
        );
        const filteredCommunityList = communityResponse.data.message.filter(
          (item) => item.PetId == pet.id
        );

        console.log("Filtered Invite List:", inviteResponse.data.message);
        console.log("Filtered Community List:", filteredCommunityList);
        console.log("Community List:", communityResponse.data.message);
        setInviteList(inviteResponse.data.message);
        setCommunityList(communityResponse.data.message);
        dispatch({ type: "refresh" });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [loginToken, pet.id, dispatch]);

  const refreshInviteList = async () => {
    try {
      const response = await axios.get("/sharepet/invitesenduserlist", {
        headers: { Authorization: loginToken },
      });
      const filteredInviteList = response.data.message.filter(
        (item) => item.PetId == pet.id
      );
      setInviteList(filteredInviteList);
      dispatch({ type: "refresh" });
    } catch (error) {
      console.error("Error refreshing invite list:", error);
    }
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleShareModal = () => {
    setOpenShareModal((prev) => !prev);
  };

  const data = activeTab === "Family" ? inviteList : communityList;

  const handleDelete = () => {
    setDeleteModal((prev) => !prev);
  };

  const handleCommunityDelete = async (petId) => {
    try {
      const response = await axios.delete(`/sharepet/deletepet/${petId}`, {
        headers: { Authorization: loginToken },
      });
      console.log("community delete: ", response.data);
      setCommunityList((prevList) =>
        prevList.filter((item) => item.PetId !== petId)
      );
    } catch (error) {
      console.error("Error deleting community:", error);
    }
  };

  const handleFamilyDelete = async (inviteId) => {
    console.log("inviteId: ", inviteId);
    try {
      const response = await axios.delete(`/sharepet/delete/${inviteId}`, {
        headers: { Authorization: loginToken },
      });
      console.log("family delete: ", response.data);
      setInviteList((prevList) =>
        prevList.filter((item) => item.Id !== inviteId)
      );
    } catch (error) {
      console.error("Error deleting invite:", error);
    }
  };

  return (
    <>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>

          <div className="pet-family-header">
            <div className="pet-family">
              <span>
                {" "}
                <DogPettingIcon name={"petfam"} />
              </span>
              <p>Pet Family</p>
              <p onClick={toggleShareModal} className="pet-fam-share">
                <DogPettingIcon name={"pet-fam-share"} />
              </p>
            </div>
          </div>

          <div className="pet-family-content">
            <div
              className={`pet-family-toggle ${
                activeTab === "Family"
                  ? "family-tab-active"
                  : "community-tab-active"
              }`}
            >
              <button
                className={activeTab === "Family" ? "active" : ""}
                onClick={() => handleTabClick("Family")}
              >
                Family
              </button>
              <button
                className={activeTab === "Community" ? "active" : ""}
                onClick={() => handleTabClick("Community")}
              >
                Community
              </button>
            </div>

            <div className="family-community-content">
              {data.length === 0 ? (
                <div className="no-data-message">
                  <Failedgif message={"No data available."} />
                </div>
              ) : (
                data.map((item) => (
                  <div className="content-card" key={item.id}>
                    <div className="content-left">
                      <div
                        style={{
                          background: "#EEEEEE",
                          height: "7vh",
                          width: "3.5vw",
                          borderRadius: ".5vw",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <DogPettingIcon name={"profile"} />
                      </div>
                      <div className="content-about">
                        <span>
                          {activeTab === "Family"
                            ? item.relationship
                            : item.Petowner}
                        </span>
                        <p>
                          {activeTab === "Family"
                            ? item.userName
                            : item.Petname}
                        </p>
                      </div>
                    </div>
                    <div className="content-controls">
                      {activeTab === "Family" ? (
                        item.Status === "Accepted" ? (
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => handleFamilyDelete(item.Id)}
                          >
                            <DogPettingIcon name={"deletee"} />
                          </p>
                        ) : (
                          <button>
                            Invited <DogPettingIcon name={"small-invite"} />
                          </button>
                        )
                      ) : (
                        <p
                          onClick={() => handleCommunityDelete(item.PetId)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <DogPettingIcon name={"delete"} />
                        </p>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      {openShareModal && (
        <InvitePetFamily
          isOpen={openShareModal}
          isClose={toggleShareModal}
          petId={pet}
          refreshInviteList={refreshInviteList}
        />
      )}
      {deleteModal && (
        <Delete
          rejectDelete={
            activeTab === "Family" ? handleFamilyDelete : handleCommunityDelete
          }
          message={`Do you really want to remove him?`}
        />
      )}
    </>
  );
}
