import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import { Switch } from "@mui/material";
import { Notification } from "./Notification";
import { GetCode } from "./GetCode";
import DeleteAccount from "./DeleteAccount";

export const PrivacySecurityModal = ({ isOpen, isClose }) => {
  const [openNotificationSetting, setOpenNotificationSetting] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [openChangePasswordSetting, setOpenChangePasswordSetting] =
    useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(
    localStorage.getItem("locationEnabled") === "true"
  );
  const [location, setLocation] = useState(null);
  const [isModalClosing, setIsModalClosing] = useState(false);

  const toggleNotificationSetting = () => {
    setOpenNotificationSetting((prev) => !prev);
  };

  const handleChangePassword = () => {
    setOpenChangePasswordSetting((prev) => !prev);
  };
  const handleDeleteAccount = () => {
    setOpenDeleteAccount((prev) => !prev);
  };
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  const handleLocationToggle = async () => {
    if (!isLocationEnabled) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setIsLocationEnabled(true);
            localStorage.setItem("locationEnabled", "true");
          },
          (error) => {
            console.error("Error getting location", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    } else {
      setIsLocationEnabled(false);
      setLocation(null);
      localStorage.setItem("locationEnabled", "false");
    }
  };

  useEffect(() => {
    if (isLocationEnabled && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location", error);
        }
      );
    }
  }, [isLocationEnabled]);

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <>
      <div
        className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"privacy"} /> Privacy & Security
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="privacy-settings-lists">
              <div className="privacy-settings-list">
                <p>Location</p>
                <span>
                  <Switch
                    checked={isLocationEnabled}
                    onChange={handleLocationToggle}
                  />
                </span>
              </div>
              <div
                className="privacy-settings-list"
                onClick={toggleNotificationSetting}
              >
                <p>Notification</p>
              </div>
              <div
                className="privacy-settings-list"
                onClick={handleChangePassword}
              >
                <p>Change Password</p>
              </div>
              <div
                className="privacy-settings-list"
                onClick={handleDeleteAccount}
              >
                <p>Delete Account</p>
              </div>
            </div>
            <div className="modal-control">
              <button>
                <DogPettingIcon name={"save-changes"} />
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {openNotificationSetting && (
        <Notification isClose={toggleNotificationSetting} />
      )}
      {openChangePasswordSetting && (
        <GetCode
          isClose={handleChangePassword}
          isOpen={openChangePasswordSetting}
          setOpenChangePasswordSetting={setOpenChangePasswordSetting}
        />
      )}
      {openDeleteAccount && <DeleteAccount isClose={handleDeleteAccount} />}
    </>
  );
};
