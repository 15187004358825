export const DataManagementCard = ({ detail }) => {
  return (
    <div className="flex md:gap-[5vw]  m-auto p-3 items-center justify-center w-full md:w-[85vw] h-auto ">
      <div className="hidden xl:block">
        <img
          src={detail.img}
          alt={detail.title}
          title={detail.title}
          className="w-[50vw] h-[50vh] xl:w-[50vw] xl:h-[65vh] object-contain"
        />
      </div>
      <div className="flex flex-col gap-[1vh] items-center justify-center md:items-start md:justify-start  ">
        <div className="flex flex-col  items-center justify-center xl:items-start xl:justify-start xl:w-[50vw] ">
          <span className="text-[clamp(1.2rem,1vw,5rem)] xl:text-[clamp(1.2rem,1.8vw,5rem)] font-[Prata] md:text-center">
            What we do
          </span>

          <h2 className="text-[clamp(1.8rem,1.8vw,5rem)] xl:text-[clamp(1.8rem,3vw,8rem)] font-[Prata] w-[95vw] text-center xl:text-left xl:w-full mb-4 xl:mb-[2vw]">
            {detail.title}
          </h2>

          <div className="block xl:hidden">
            <img
              src={detail.img}
              alt={detail.title}
              title={detail.title}
              className=" h-[40vh] md:h-[40vh] object-contain m-auto"
            />
          </div>

          <p className=" big-screen hidden xl:block text-[#999] text-[clamp(1rem,1.4vw,7rem)] font-[Poppins] w-[45vw]">
            {detail.description}
          </p>
        </div>
        <div className="hidden xl:flex flex-wrap gap-[2vw] w-full mt-[2vw]">
          {detail?.icons?.map((item) => {
            return (
              <div
                className="flex  gap-[.7vw] items-center w-[20vw]"
                key={item.id}
              >
                <span className=" flex justify-center items-center rounded-[50%] bg-white p-[2vw] xl:p-[1vw]  md:p-[1.5vw] text-black shadow-[0px_.2vw_.5vw_0px_rgba(0,0,0,0.15)] ">
                  {item.icon}
                </span>
                <p className=" text-[clamp(.8rem,1vw,3rem)] font-[Prata]">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-wrap w-[95vw]  items-center justify-center gap-[1vh] py-[2vh]  xl:hidden m-auto">
          {detail?.icons?.map((item) => {
            return (
              <div
                className="flex flex-col gap-[.5vh] justify-center items-center rounded-[50%] bg-white w-[70px] h-[70px]  md:w-[90px] md:h-[90px] text-black shadow-[0px_.2vw_.5vw_0px_rgba(0,0,0,0.15)] overflow-hidden"
                key={item.id}
              >
                <span className="  ">{item.icon}</span>
                <p className=" text-[clamp(.4rem,.8vw,1.5rem)] font-[Prata] w-[10vw] md:w-[50px] text-center">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
        <div className="mobile-screen w-[95vw] md:w-[60vw] text-center xl:hidden  m-auto ">
          <p className="mobile-screen text-center  xl:hidden text-gray-500 text-md ">
            {detail.description}
          </p>
        </div>
      </div>
    </div>
  );
};
