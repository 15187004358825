import React, { useRef, useState, useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { LuDot } from "react-icons/lu";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export const FeaturedTab = ({ articles }) => {
  const scrollContainerRef = useRef(null);
  const dividerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const getRecentArticles = (articles) => {
    if (!Array.isArray(articles)) return [];
    return articles
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .slice(0, 5);
  };

  const recentArticles = getRecentArticles(articles);

  const categoryMapping = {
    health_and_wellness: "Health",
    pet_care: "Pet Care",
    training_and_behaviour: "Training",
    all: "Featured",
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const divider = dividerRef.current;

    const handleScroll = () => {
      if (!divider || !scrollContainer) return;

      const scrollLeft = scrollContainer.scrollLeft;
      const maxScrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const scrollPercentage = (scrollLeft / maxScrollLeft) * 100;
      divider.style.width = `${scrollPercentage}%`;
    };

    const handleMouseDown = (event) => {
      setIsDragging(true);
      scrollContainer.isDown = true;
      scrollContainer.startX = event.pageX - scrollContainer.offsetLeft;
      scrollContainer.scrollLeft = scrollContainer.scrollLeft;
    };

    const handleMouseLeave = () => {
      setIsDragging(false);
      scrollContainer.isDown = false;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      scrollContainer.isDown = false;
    };

    const handleMouseMove = (event) => {
      if (!scrollContainer.isDown || !isDragging) return;
      event.preventDefault();
      const x = event.pageX - scrollContainer.offsetLeft;
      const walk = (x - scrollContainer.startX) * 3; // Scroll-fast
      scrollContainer.scrollLeft -= walk;
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      scrollContainer.addEventListener("mousedown", handleMouseDown);
      scrollContainer.addEventListener("mouseleave", handleMouseLeave);
      scrollContainer.addEventListener("mouseup", handleMouseUp);
      scrollContainer.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
        scrollContainer.removeEventListener("mousedown", handleMouseDown);
        scrollContainer.removeEventListener("mouseleave", handleMouseLeave);
        scrollContainer.removeEventListener("mouseup", handleMouseUp);
        scrollContainer.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [isDragging]);

  const trimDesc = (desc, charLimit = 40) => {
    if (typeof desc === "string") {
      return desc.length > charLimit ? desc.slice(0, charLimit) + " .." : desc;
    } else if (Array.isArray(desc)) {
      return desc
        .map((item) =>
          item.length > charLimit ? item.slice(0, charLimit) + " .." : item
        )
        .join(" ");
    } else {
      console.error(
        "Provided description is neither a string nor an array:",
        desc
      );
      return "";
    }
  };

  const formatDuration = (duration) => {
    const roundedDuration = parseFloat(duration).toFixed(1);
    return `${roundedDuration} min read`;
  };

  const getDisplayName = (category) => categoryMapping[category] || category;

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="home-left-center">
        <div className="center-name">
          <h1>Weekly Latest</h1>
        </div>
        {recentArticles.length > 0 && (
          <div className="center-article">
            <div className="center-article-left">
              <div className="left-article-content">
                <p className="left-article-para">
                  {getDisplayName(recentArticles[0].category)}{" "}
                  <LuDot style={{ width: "1.2vw", height: "3vh" }} />{" "}
                  <span>{formatDuration(recentArticles[0].duration)}</span>
                </p>
                <h2 className="left-article-title">
                  {recentArticles[0].title}
                </h2>
                <p className="left-article-description">
                  {trimDesc(recentArticles[0].summary[0], 110)}
                </p>
              </div>
              <div className="left-article-control">
                <Link
                  to={`/article/${recentArticles[0].id}`}
                  className="read-now-btn"
                >
                  Read Now
                </Link>
                <span>
                  <FaArrowRightLong className="left-article-icon" />
                </span>
              </div>
            </div>
            <div className="center-article-right">
              <img
                src={recentArticles[0].webImage}
                alt={recentArticles[0].title}
              />
            </div>
          </div>
        )}
      </div>
      <div className="home-left-bottom">
        <div className="bottom-more-articles">
          <button className="scroll-button left" onClick={scrollLeft}>
            <FaChevronLeft style={{ width: "1.2vw", height: "3vh" }} />
          </button>
          <div className="divider">
            <span ref={dividerRef}></span>
          </div>
          <div
            className="more-articles-cards"
            ref={scrollContainerRef}
            onScroll={() => setIsDragging(false)}
          >
            {recentArticles.slice(1).map((article) => (
              <div className="more-articles-card" key={article.id}>
                <Link to={`/article/${article.id}`}>
                  <div className="card-articles-top">
                    <p>
                      {getDisplayName(article.category)}{" "}
                      <LuDot style={{ width: "1.2vw", height: "3vh" }} />
                      <span>{formatDuration(article.duration)}</span>
                    </p>
                  </div>
                  <div className="card-articles-center">
                    <h3>{trimDesc(article.title, 30)}</h3>
                    <p>{trimDesc(article.summary[0], 80)}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <button className="scroll-button rightbtn" onClick={scrollRight}>
            <FaChevronRight style={{ width: "1.2vw", height: "3vh" }} />
          </button>
        </div>
      </div>
    </>
  );
};
