import React from "react";
import "../../Pages/Profile/Petprofile/Records/IdentificationMarks/IdentificationMarks.css";
import "./Delete.css";
import { RiDeleteBinLine } from "react-icons/ri";
export default function Delete({ handleDelete, rejectDelete, message }) {
  return (
    <div className="modal-overlay2">
      <div className="modal-container2">
        <div className="modal-content-reminder3">
          <RiDeleteBinLine className="delete-bin" />
          <p>{message}</p>
          <div className="delete-confirmation-buttons">
            <button className={"deletebutton"} onClick={handleDelete}>
              Yes
            </button>
            &ensp;
            <button className={"deletebutton"} onClick={rejectDelete}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
