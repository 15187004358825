import React from 'react';
import { Document, Page, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
const logo='https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-Footer/image+16407.png'
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: 200,
    height: 200,
    objectFit:'contain',
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
});

const Invoice = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
      {/* <Image src={logo} style={styles.image} /> */}
        <Text style={styles.title}>KR PET EYE LLP</Text>
        <Text style={styles.subtitle}>Employee Information:</Text>
        <Text style={styles.text}>Name: Jithun Madhav CT</Text>
        <Text style={styles.text}>Position: Back End Developer</Text>
        <Text style={styles.text}>Department: IT</Text>
        <Text style={styles.subtitle}>Report Date: 26-04-2024</Text>
        {/* Add more text elements for tasks, achievements, etc. */}
      </View>
    </Page>
  </Document>
);

export default Invoice;
