import React, { useState, useEffect } from "react";
import axios from "../../../../axios";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import Cookies from "js-cookie";
import CustomDropdown from "../../../../Components/CustomDropdown/CustomDropdown";

export const RabiesVaccination = ({
  isOpen,
  isClose,
  vaccinated,
  petType,
  onVaccinationDataChange,
}) => {
  const loginToken = Cookies.get("loginToken");
  const signupToken = Cookies.get("signupToken");

  const [isClosing, setIsClosing] = useState(false);
  const [vaccinationName, setVaccinationName] = useState(
    petType === "Dog or Canids" ? { Name: "Anti-Rabies" } : ""
  );
  const [vaccinationDate, setVaccinationDate] = useState("");
  const [vaccinationDose, setVaccinationDose] = useState("");
  const [nextVacciDate, setNextVacciDate] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [vaccineOptions, setVaccineOptions] = useState([]);
  const [vaccinationList, setVaccinationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextdoseType, setNextDoseType] = useState("");
  const [nextVaccinationName, setNextVaccinationName] = useState("");

  const getPetTypeForAPI = (type) => {
    if (type === "Dog or Canids") return "Dog";
    if (type === "Cat or Feline") return "Cat";
    return "Other";
  };

  useEffect(() => {
    if (petType === "Dog or Canids" && !vaccinated) {
      fetchVaccineOptions();
    } else if (petType !== "Dog or Canids") {
      fetchVaccinationList();
    }
  }, [petType, vaccinated, vaccinationDose]);

  useEffect(() => {
    if (vaccinationName && vaccinationDose) {
      fetchNextVaccination();
    }
  }, [vaccinationName, vaccinationDose]);

  const fetchVaccineOptions = async () => {
    if (vaccinationDose) {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `/auth/rabiesvaccinelist?dose=${vaccinationDose}`,
          {
            headers: {
              Authorization: signupToken,
            },
          }
        );
        if (response.data.status === 200) {
          const formattedOptions = response?.data?.message.map((vaccine) => ({
            name: vaccine.ProductName,
            price: vaccine.price,
            id: vaccine.Id,
          }));
          setVaccineOptions(formattedOptions);
        } else {
          throw new Error("Failed to fetch vaccine options");
        }
      } catch (err) {
        console.error("Error fetching vaccine options:", err);
        setError("Failed to fetch vaccine options. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchVaccinationList = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const selectedPet = getPetTypeForAPI(petType);
      const response = await axios.get(
        `/petinfo/vaccinationname/${selectedPet}`,
        {
          headers: {
            Authorization: signupToken,
          },
        }
      );
      setVaccinationList(response.data.message);
    } catch (err) {
      console.error("Error fetching vaccination list:", err);
      setError("Failed to fetch vaccination list. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextVaccination = async () => {
    if (vaccinationName) {
      const val = {
        currentvaccination: vaccinationName.Name,
        petType: getPetTypeForAPI(petType),
        currentdoseType: vaccinationDose,
      };
      try {
        const response = await axios.post("/admin/getmobilevaccination", val, {
          headers: {
            Authorization: signupToken,
          },
        });
        if (response.data.message) {
          setNextVaccinationName(response.data.message.nextvaccination);
          setNextDoseType(response.data.message.nextdoseType);
          const nextDate = new Date(vaccinationDate);
          nextDate.setDate(
            nextDate.getDate() + parseInt(response.data.message.timeDuration)
          );
          setNextVacciDate(nextDate);
        }
      } catch (error) {
        console.error("Error fetching next vaccination:", error);
      }
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleVaccinationDate = (e) => {
    setVaccinationDate(e.target.value);
  };

  const handleVaccinationDose = (e) => {
    setVaccinationDose(e.target.value);
    setSelectedVaccine(null);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post("/file/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: signupToken,
          },
        });

        setUploadedFile({
          url: response?.data?.Message?.getPeteye,
          fileType: file.type,
          docType: "Vaccination",
        });
      } catch (error) {
        console.error("Error uploading file:", error);
        setError("Failed to upload file. Please try again.");
      }
    }
  };

  const handleVaccineChange = (option) => {
    setSelectedVaccine(option);
  };

  const handleSave = () => {
    const apiPetType = getPetTypeForAPI(petType);
    let vaccinationData = {};

    const formatDate = (date) => {
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString().split("T")[0];
      }
      console.warn("Invalid date:", date);
      return "";
    };

    try {
      if (apiPetType === "Dog") {
        if (vaccinated) {
          vaccinationData = {
            Vaccination: [
              {
                currentvaccination: "Anti-Rabies",
                LastVaccinationDate: vaccinationDate,
                nextvaccinationname: nextVaccinationName,
                nextvaccinationDate: formatDate(nextVacciDate),
                lastdoseType: vaccinationDose,
                nextdoseType: nextdoseType,
                documentList: uploadedFile ? [uploadedFile] : [],
              },
            ],
            nonvaccination: "",
          };
        } else {
          vaccinationData = {
            Vaccination: [],
            nonvaccination: {
              selectedVaccination: "Anti-Rabies",
              selectedDoseType: vaccinationDose,
              selectedDate: vaccinationDate,
              selectedVaccineId: selectedVaccine ? selectedVaccine.id : "",
            },
          };
        }
      } else {
        if (vaccinated) {
          vaccinationData = {
            Vaccination: [
              {
                currentvaccination: vaccinationName.Name,
                LastVaccinationDate: vaccinationDate,
                nextvaccinationname: nextVaccinationName,
                nextvaccinationDate: formatDate(nextVacciDate),
                lastdoseType: vaccinationDose,
                nextdoseType: nextdoseType,
                documentList: uploadedFile ? [uploadedFile] : [],
              },
            ],
            nonvaccination: "",
          };
        } else {
          vaccinationData = {
            Vaccination: [],
            nonvaccination: vaccinationDate,
          };
        }
      }

      console.log("vaccinationData from rabies vaccination", vaccinationData);
      onVaccinationDataChange(vaccinationData);
      handleClose();
    } catch (error) {
      console.error("Error in handleSave:", error);
      // You might want to show an error message to the user here
    }
  };
  if (!isOpen) return null;

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={(e) => e.target === e.currentTarget && handleClose()}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              {petType === "Dog or Canids"
                ? "Rabies Vaccination Details"
                : "Vaccination Details"}
            </p>
          </div>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="setting-modal-description">
          <div className="rabbies-vaccination">
            {petType === "Dog or Canids" ? (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    value={vaccinationName.Name}
                    onChange={(e) => setVaccinationName(e.target.value)}
                    aria-label="Anti Rabies Vaccination"
                    placeholder="Anti Rabies Vaccination"
                    className="form-group-select"
                    readOnly
                  />
                </div>

                {vaccinated ? (
                  <>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Last Vaccination Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                        }
                        value={vaccinationDate}
                        onChange={handleVaccinationDate}
                        aria-label="Last Vaccination Date"
                      />
                    </div>

                    <div className="form-group">
                      <select
                        value={vaccinationDose}
                        onChange={handleVaccinationDose}
                        aria-label="Vaccination Dose"
                        className="form-group-select"
                      >
                        <option value="">Last Vaccination Dose</option>
                        <option value="Initial Dose">Initial Dose</option>
                        <option value="Booster Dose">Booster Dose</option>
                        <option value="Annual-Re-Vaccination">
                          Annual Re-Vaccination
                        </option>
                      </select>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group">
                      <select
                        value={vaccinationDose}
                        onChange={handleVaccinationDose}
                        aria-label="Vaccination Dose"
                        className="form-group-select"
                      >
                        <option value="">Vaccination Dose</option>
                        <option value="Initial">Initial</option>
                        <option value="Booster">Booster</option>
                        <option value="Annual">Annual</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Select Date for Vaccination"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                        }
                        value={vaccinationDate}
                        onChange={handleVaccinationDate}
                        aria-label="Select Date for Vaccination"
                      />
                    </div>

                    {isLoading ? (
                      <div className="form-group">
                        <span className="loading-text">
                          Fetching vaccine options
                        </span>
                      </div>
                    ) : error ? (
                      <div className="form-group">
                        <p className="error-text">{error}</p>
                      </div>
                    ) : (
                      <CustomDropdown
                        options={vaccineOptions}
                        value={selectedVaccine ? selectedVaccine.name : ""}
                        onChange={handleVaccineChange}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {vaccinated ? (
                  <>
                    <div className="form-group">
                      <select
                        value={vaccinationName}
                        onChange={(e) => setVaccinationName(e.target.value)}
                        aria-label="Last Vaccination Name"
                        className="form-group-select"
                      >
                        <option value="">Last Vaccination Name</option>
                        {vaccinationList.map((vaccine) => (
                          <option key={vaccine.Id} value={vaccine.Name}>
                            {vaccine.Name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Last Vaccination Date"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                        }
                        value={vaccinationDate}
                        onChange={handleVaccinationDate}
                        aria-label="Last Vaccination Date"
                      />
                    </div>

                    <div className="form-group">
                      <select
                        value={vaccinationDose}
                        onChange={handleVaccinationDose}
                        aria-label="Last Vaccination Dose"
                        className="form-group-select"
                      >
                        <option value="">Last Vaccination Dose</option>
                        <option value="Initial Dose">Initial Dose</option>
                        <option value="Booster Dose">Booster Dose</option>
                        <option value="Annual-Re-Vaccination">
                          Annual Re-Vaccination
                        </option>
                      </select>
                    </div>
                  </>
                ) : (
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Select Date for Vaccination"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) =>
                        (e.target.type = e.target.value ? "date" : "text")
                      }
                      value={vaccinationDate}
                      onChange={handleVaccinationDate}
                      aria-label="Select Date for Vaccination"
                    />
                  </div>
                )}
              </>
            )}
            {(petType === "Dog or Canids" || vaccinated) && (
              <div className="adddocument-pdf">
                {uploadedFile ? (
                  <>
                    <p>{uploadedFile.docType}</p>
                    <label htmlFor="file-input-form">
                      <span style={{ cursor: "pointer" }}>
                        <DogPettingIcon name="attachment" />
                      </span>
                    </label>
                  </>
                ) : (
                  <>
                    <p>Add Document</p>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                      id="file-input-form"
                    />
                    <label htmlFor="file-input-form">
                      <span style={{ cursor: "pointer" }}>
                        <DogPettingIcon name="attachment" />
                      </span>
                    </label>
                  </>
                )}
              </div>
            )}
            <div className="logout-container-control">
              <button
                className="logout-btn"
                onClick={handleSave}
                disabled={isLoading}
              >
                <DogPettingIcon name={"accept"} />
                {petType === "Dog or Canids" && !vaccinated ? "Book" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
