import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./IdentificationMarks.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

export default function IdentificationMarks({ isOpen, onClose, petId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [identity1, setIdentity1] = useState("");
  const [identity2, setIdentity2] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const [error, setError] = useState("");
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;

  const dispatch = useDispatch();

  useEffect(() => {
    if (petId) {
      setIdentity1(petId.identificationMark || "");
      setIdentity2(petId.identificationMark1 || "");
    }
  }, [petId]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleEditSaveClick = async () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      try {
        const response = await axios.patch(
          `/petinfo/updateidentificationmarks/${petId.id}`,
          {
            identificationMark: identity1,
            identificationMark1: identity2,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        dispatch({ type: "refresh" });
        console.log("Successfully updated", response);
      } catch (error) {
        console.error("Error updating fields:", error);
      }
    }
  };
  const validateInput = (value) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // Only allows alphanumeric characters and spaces
    return regex.test(value);
  };
  const handleIdentity1Change = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      setError("Maximum length is 50 characters.");
      setTimeout(() => setError(""), 3000);
      return false; // Stop further processing if length exceeds 50
    }
    if (validateInput(value)) {
      setIdentity1(value);
      setError(""); // Clear error if input is valid
    } else {
      setError("Special characters are not allowed."); // Show error for invalid input
      setTimeout(() => setError(""), 3000);
    }
  };

  const handleIdentity2Change = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      setError("Maximum length is 50 characters.");
      setTimeout(() => setError(""), 3000);
      return false; // Stop further processing if length exceeds 50
    }
    if (validateInput(value)) {
      setIdentity2(value);
      setError(""); // Clear error if input is valid
    } else {
      setError("Special characters are not allowed."); // Show error for invalid input
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="identification-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="identification-title">
            <DogPettingIcon name={"dog-paw"} />
            <h2>Identification Marks</h2>
          </div>
          <div className="identification-input-group">
            <input
              type="text"
              placeholder="Please add pet identity 1"
              value={identity1}
              onChange={handleIdentity1Change}
              disabled={!isEditing}
            />
            <input
              type="text"
              placeholder="Please add pet identity 2"
              value={identity2}
              onChange={handleIdentity2Change}
              disabled={!isEditing}
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button
            className="identification-button"
            onClick={handleEditSaveClick}
          >
            <DogPettingIcon
              name={isEditing ? "check" : "pencil-edit"}
              className="identification-edit-icon"
            />
            <p>{isEditing ? "Save" : "Edit"}</p>
          </button>
        </div>
      </div>
    </div>
  );
}
