import "../Homepage/HomePageModal/HomePageModal.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./ChoosePetModal.css";
import { FaCheckCircle } from "react-icons/fa";
import { BsLink } from "react-icons/bs";
import { useState, useEffect } from "react";
import axios from "../../axios";
import Cookies from "js-cookie";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import Success from "../Profile/Success/Success";

export const ChoosePetModal = ({
  isClose,
  petInfo,
  qrCode,
  selectPetModal,
  updateConnectedPets,
  toggleConnectorModal,
}) => {
  const loginToken = Cookies.get("loginToken");

  const [selectedPetId, setSelectedPetId] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [unconnectedPets, setUnconnectedPets] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const [isSmallClosing, setIsSmallClosing] = useState(false);

  const fetchConnectedPets = async () => {
    try {
      const response = await axios.get("/nfc/connectedpets", {
        headers: {
          Authorization: loginToken,
        },
      });
      const connectedPets = response.data.message;
      console.log("Connected pets:", connectedPets);

      if (!Array.isArray(connectedPets)) {
        console.error(
          "Expected connectedPets to be an array, but got:",
          connectedPets
        );
        return;
      }

      if (connectedPets.length === 0) {
        setUnconnectedPets(petInfo);
      } else {
        const filteredPets = petInfo.filter(
          (pet) =>
            !connectedPets.some((connectedPet) => connectedPet.petId == pet.id)
        );
        setUnconnectedPets(filteredPets);
      }
    } catch (error) {
      console.error("Error fetching connected pets:", error);
    }
  };

  useEffect(() => {
    fetchConnectedPets();
  }, [petInfo, loginToken]);

  const selectPet = (petId) => {
    setSelectedPetId(petId === selectedPetId ? null : petId);
  };

  const submit = async () => {
    if (selectedPetId) {
      try {
        const response = await axios.patch(
          "/nfc/userpetconnect",
          {
            petId: selectedPetId,
            QrId: qrCode,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );

        console.log("API response:", response);
        console.log("qrCode: " + qrCode);

        setSubmitted(true);
        // Update the list of unconnected pets
        setUnconnectedPets((prevUnconnectedPets) =>
          prevUnconnectedPets.filter((pet) => pet.id !== selectedPetId)
        );

        setTimeout(() => {
          setSubmitted(false);
          selectPetModal(false);
        }, 800);
        updateConnectedPets();
        toggleConnectorModal((prev) => !prev);
      } catch (error) {
        console.error("Error connecting pet:", error);
      }
    } else {
      console.warn("No pet selected");
    }
  };

  console.log("qrCode: " + qrCode);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 3000); // Match the duration of the closing animation
  };

  return submitted ? (
    <>
      <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          {/* <div className="modal-background">
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="modal-description">
            <img
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
              alt=""
            />
            <p>Bonded Successfully!!</p>
          </div> */}
          <Success
            onClose={handleClose}
            message="Bonded Successfully!!"
            gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={`modal-overlay ${isSmallClosing ? "fade-out" : ""}`}>
        <div className={`modal-container ${isSmallClosing ? "close" : ""}`}>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className={"choose-pet-modal-container"}>
            <div className="modal-bg relative">
              <div className="choose-pet">
                <h3 className="">Choose ECHO device to bond with your pet</h3>
                {unconnectedPets.length === 0 ? (
                  <div className="no-pets-message">
                    <p>All pets are connected!</p>
                  </div>
                ) : (
                  <div className="live-past-sessions-content ">
                    {unconnectedPets.map((pet) => (
                      <div key={pet.id} className="live-past-sessions-box">
                        <div className="live-past-sessions-img">
                          <img
                            src={
                              pet.petProfileImage ||
                              "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+3(4).png"
                            }
                            alt=""
                          />

                          <div className="about-pet-nfc">
                            <h3>{pet.petName}</h3>
                            <p className="not-connected ">Not Connected</p>
                          </div>
                        </div>

                        <div
                          className={
                            selectedPetId === pet.id
                              ? "live-past-sessions-control active"
                              : "live-past-sessions-control"
                          }
                          onClick={() => selectPet(pet.id)}
                        >
                          <FaCheckCircle
                            className="live-past-sessions-icon select"
                            style={{
                              color:
                                selectedPetId === pet.id ? "black" : "gray",
                            }}
                          />
                          <p
                            style={{
                              color:
                                selectedPetId === pet.id ? "black" : "gray",
                            }}
                          >
                            {selectedPetId === pet.id ? "Selected" : "Select"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {unconnectedPets.length !== 0 && (
                  <div className="choose-pet-button absolute bottom-0 flex flex-col items-center justify-center gap-[0.5vw]  w-full h-[12vh]  backdrop-blur-sm">
                    <button
                      onClick={submit}
                      className="flex items-center gap-[0.5vw] text-[clamp(1rem,1.2vw,3.5rem)]  bg-black text-white px-[1.5vw] py-[0.5vw] rounded-[1vw]"
                    >
                      <BsLink className="choose-pet-icon" /> Connect
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
