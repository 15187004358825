import { useEffect } from "react";
import "./Success.css";

export default function Success({ onClose, message, header, gif }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      // onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div>
      <div className="uploaded-success-gif">
        {header && <p>{header}</p>}
        <img src={gif} alt={message} title={message} />
        <p>{message}</p>
      </div>
    </div>
  );
}
