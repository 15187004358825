import React, { useState } from "react";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import LostFound from "./LostFound";

export default function LostCompanion({ isOpen, isClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [switchToFound, setSwitchToFound] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };
  return (
    <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-close" onClick={handleClose}>
          <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
        </div>
        <div>
          <LostFound
            switchToFound={switchToFound}
            setSwitchToFound={setSwitchToFound}
          />
        </div>
      </div>
    </div>
  );
}
