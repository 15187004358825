import { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaBirthdayCake } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import Cookies from "js-cookie";
import axios from "../../axios";
import dayjs from "dayjs";
import alternateImg from "../../assets/animated-dog.avif";
import "./AcceptInvite.css";
import DogPettingIcon from "../../Pages/Homepage/DogPettingIcon";
import { useNavigate } from "react-router-dom";

export const AcceptInvite = () => {
  const [isClosing, setIsClosing] = useState(false);
  const [inviteInfo, setInviteInfo] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const loginToken = Cookies.get("loginToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/sharepet/invitereceiveuserlist", {
          headers: {
            Authorization: loginToken,
          },
        });

        console.log("Invite data: ", response.data.message);
        if (response.data.message && response.data.message.length > 0) {
          setInviteInfo(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching invite data: ", error);
        setError("Failed to fetch invites. Please try again later.");
      }
    };

    fetchData();
  }, [loginToken]);

  const trimBreed = (breed) => {
    return breed.length > 12 ? breed.slice(0, 12) + " .." : breed;
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let years = today.getFullYear() - birth.getFullYear();
    let months = today.getMonth() - birth.getMonth();
    let days = today.getDate() - birth.getDate();

    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    if (years === 0 && months === 0) {
      days = Math.floor((today - birth) / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    return { years, months, days };
  };

  // Usage in the component
  const getPetAge = (birthDate) => {
    const age = calculateAge(birthDate);
    if (age.years > 0) {
      return `${age.years} Years ${
        age.months > 0 ? age.months + " Months" : ""
      }`;
    } else if (age.months > 0) {
      return `${age.months} Month ${age.days > 0 ? age.days + " Day" : ""}`;
    } else {
      return `${age.days} Days`;
    }
  };

  const handleInvite = async (inviteId, action) => {
    console.log(`Invite ID: ${inviteId}, Action: ${action}`);
    try {
      const endpoint =
        action === "accept"
          ? `/sharepet/acceptmail/${inviteId}`
          : `/sharepet/reject/${inviteId}`;
      const method = action === "accept" ? "get" : "post";
      const response = await axios({
        method: method,
        url: endpoint,
        headers: {
          Authorization: loginToken,
        },
      });

      if (response.status === 200) {
        setSuccess(
          action === "accept"
            ? "Invite accepted successfully."
            : "Invite rejected successfully."
        );
        setTimeout(() => {
          setSuccess("");
          window.location.href = "/"; // Redirect to homepage
        }, 500);
      } else {
        setError("Failed to process the invite. Please try again.");
        setTimeout(() => setError(""), 1500);
      }
    } catch (error) {
      console.error(`Error ${action} invite:`, error);
      setError("Failed to process the invite. Please try again later.");
      setTimeout(() => setError(""), 1500);
    }
  };

  return (
    <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        {inviteInfo.length > 0 ? (
          inviteInfo?.map((invite, index) => (
            <div key={index} className="modal-content">
              <div className="modal-background">
                <img
                  src={
                    invite.petProfileImage
                      ? invite.petProfileImage
                      : alternateImg
                  }
                  alt={invite.Petname}
                />
              </div>
              <div className="modal-description">
                <div className={`pet-description normal`}>
                  <div className="pet-name">
                    <p>{invite.Petname}</p>
                    <span style={{ textTransform: "capitalize" }}>
                      {trimBreed(invite.petBreed)}
                    </span>
                  </div>
                  <div className="pet-info">
                    <p className="birthday">
                      <span>
                        <FaBirthdayCake className="cake-icon" />
                      </span>
                      <span>{getPetAge(invite.birthDate)}</span>
                    </p>
                    <p className="gender">
                      <span>
                        <BsGenderAmbiguous className="gender-icon" />
                      </span>
                      <span>{invite.sex ? invite.sex : "Gender"}</span>
                    </p>
                  </div>
                </div>
                <div className="accepting-message">
                  <p>Hey {invite.userName},</p>
                  <p>
                    From {invite.Petowner} to become a member of his pet circle,
                    and his furry companion goes by the name of his{" "}
                    {invite.Petname}.
                  </p>
                  <p>
                    I would be more than happy to provide you with all the
                    necessary supplies, such as food, toys, and any other items
                    Jacky might need. Additionally, I will make sure you have
                    all the contact information for the pet.
                  </p>
                </div>
                <div className="accept-controls">
                  <button
                    id="reject"
                    onClick={() => handleInvite(invite.Id, "reject")}
                  >
                    <DogPettingIcon name={"reject"} />
                    Reject
                  </button>
                  <button
                    id="accept"
                    onClick={() => handleInvite(invite.Id, "accept")}
                  >
                    <DogPettingIcon name={"accept"} />
                    Accept
                  </button>
                </div>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="rule-valid">{success}</p>}
              </div>
            </div>
          ))
        ) : (
          <div className="no-invites">
            <p>No invites available.</p>
          </div>
        )}
      </div>
    </div>
  );
};
