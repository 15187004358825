import React, { useState, useEffect } from "react";
import "./ProductCard.css";
import DogPettingIcon from "../Pages/Homepage/DogPettingIcon";
import defaultImg from "../assets/product.png";
import Cookies from "js-cookie";
import axios from "../axios";
import ShopGif from "./components/ShopGif/ShopGifs";
import { useDispatch, useSelector } from "react-redux";
import { useShop } from "../shop/components/ShopContext";
export const ProductCard = ({
  product,
  onViewDetails,
  allProducts,
  addToWishlist,
  removeFromWishlist,
  fetchWishlistStatus,
  isInWishlist,
  wishMarkList,
}) => {
  const { ProductName, productImages, productvariants } = product;
  const defaultVariant = productvariants[0];
  const oldPrice = parseFloat(defaultVariant.price);
  const offerPercentage = parseFloat(defaultVariant.OfferPercentage);
  const newPrice = oldPrice - (oldPrice * offerPercentage) / 100;

  const formatPrice = (price) => {
    return Number.isInteger(price) ? price.toString() : price.toFixed(2);
  };
  const loginToken = Cookies.get("loginToken");

  useEffect(() => {
    fetchWishlistStatus(product.id);
  }, [product.id]);

  const handleWishlistToggle = () => {
    if (isInWishlist) {
      removeFromWishlist(product.id);
    } else {
      addToWishlist(product.id, product.Category);
    }
  };

  return (
    <div className="product-card">
      <div className="product-card-top">
        <div className="product-card-off">
          <p className="product-card-off-amount">{offerPercentage}%</p>
          <p className="product-card-off-text">Off</p>
        </div>
        <div className="product-card-wishlist" onClick={handleWishlistToggle}>
          <DogPettingIcon
            name={isInWishlist ? "shop-wishlistadded" : "shop-wishlist"}
          />
        </div>
      </div>

      <div className="product-image-title">
        <img
          src={
            productImages && productImages.length > 0
              ? productImages[0].url
              : defaultImg
          }
          alt={ProductName}
        />
        <h3>{ProductName}</h3>
      </div>

      <div className="product-footer">
        <p className="product-amount">
          <span className="new-price">₹{formatPrice(newPrice)}</span>
          <span className="old-price">₹{formatPrice(oldPrice)}</span>
        </p>
        <button className="product-btn" onClick={() => onViewDetails(product)}>
          View Details
        </button>
      </div>
    </div>
  );
};
