import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import { EnterOTP } from "./EnterOTP";
import Cookies from "js-cookie";
import axios from "../../../../axios";

export const GetCode = ({ isOpen, isClose, setOpenChangePasswordSetting }) => {
  const loginToken = Cookies.get("loginToken");

  const [openEnterOTP, setOpenEnterOTP] = useState(false);
  const [activeOption, setActiveOption] = useState("mobile");
  const [userData, setUserData] = useState({
    email: "",
    mobile: "",
  });
  const [isModalClosing, setIsModalClosing] = useState(false);

  const toggleEnterOTP = () => {
    setOpenEnterOTP((prev) => !prev);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get("/user/userInfo", {
          headers: {
            Authorization: loginToken,
          },
        });
        const { message } = response.data;

        console.log("message:", message);
        setUserData({
          email: message.email || "",
          mobile: message.mobile || "",
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [loginToken]);

  const handleGetCodeClick = async () => {
    try {
      let response;
      if (activeOption === "mobile") {
        response = await axios.get("/auth/sendOtpMobile", {
          headers: {
            Authorization: loginToken,
          },
        });
      } else if (activeOption === "email") {
        response = await axios.get("/auth/sendOtpEmail", {
          headers: {
            Authorization: loginToken,
          },
        });
      }
      if (response?.data?.status === 200) {
        toggleEnterOTP();
      } else {
        console.error("Failed to send OTP:", response);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <>
      <div
        className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>Change Password</p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="get-code-about">
              <p>
                To help keep your account safe, we want to make sure it’s really
                you trying to sign in.
              </p>
            </div>
            <div className="code-option-select">
              <div
                className={`mobile ${
                  activeOption === "mobile" ? "active" : ""
                }`}
                onClick={() => setActiveOption("mobile")}
              >
                <span>
                  <DogPettingIcon name={"mobile"} />
                </span>
                <p>+91-{userData.mobile}</p>
                {activeOption === "mobile" && (
                  <p className="active-icon">
                    <DogPettingIcon name={"tick"} />
                  </p>
                )}
              </div>
              <div className="or">Or</div>
              <div
                className={`email ${activeOption === "email" ? "active" : ""}`}
                onClick={() => setActiveOption("email")}
              >
                <span>
                  <DogPettingIcon name={"email"} />
                </span>
                <p>{userData.email}</p>
                {activeOption === "email" && (
                  <p className="active-icon">
                    <DogPettingIcon name={"tick"} />
                  </p>
                )}
              </div>
            </div>
            <div className="modal-control">
              <button onClick={handleGetCodeClick}>
                <DogPettingIcon name={"get-code"} />
                Get Code
              </button>
            </div>
          </div>
        </div>
      </div>
      {openEnterOTP && (
        <EnterOTP
          isClose={toggleEnterOTP}
          isOpen={openEnterOTP}
          type={activeOption}
          numberMessage="We have sent the verification code to your mobile number."
          emailMessage="We have sent the verification code to your email address."
          setOpenChangePasswordSetting={setOpenChangePasswordSetting}
          setOpenEnterOTP={setOpenEnterOTP}
          userData={userData} // Pass userData as a prop
        />
      )}
    </>
  );
};
