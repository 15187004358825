import React, { useState } from "react";
import NewHeader from "../../Components/Header/NewHeader";
import Icons from "../../context/Icons";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import DeleteAccount from "../../Pages/Profile/Petprofile/Settings/DeleteAccount";

export default function DeleteAcc() {
  const [isOpen, setIsOpen] = useState(false);
  const loginToken = Cookies.get("loginToken");
  const { login, refresh } = useSelector((state) => state);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const navigate = useNavigate();
  const handleuserclose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const handleDeleteAccount = () => {
    setOpenDeleteAccount((prev) => !prev);
  };
  const handleButtonClick = () => {
    if (!login?.login) {
      navigate("/login");
    }
  };

  return (
    <div onClick={handleuserclose} className="m-auto">
      <NewHeader />
      <div className="w-[80%] flex flex-col gap-[3vh] m-auto py-[5vh]">
        <div className="flex flex-col gap-[1.5vh]">
          <h1 className="text-[clamp(1.2rem,1.2vw,3.5rem)] md:text-[clamp(1.2rem,1.2vw,3.5rem)] xl:text-[clamp(1.5rem,1.5vw,4.5rem)] font-[Open Sans] font-[400] text-[#646464] ">
            Account Deletion
          </h1>
          <h2 className="text-[clamp(1.2rem,1.2vw,3.5rem)] md:text-[clamp(1.2rem,1.2vw,3.5rem)] xl:text-[clamp(1.5rem,2vw,5.5rem)] font-[Prata] font-[400]">
            How to Permanently Delete Your PetEYE Account
          </h2>
          <p className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[400] text-[#646464]">
            Follow the steps below to permanently delete your PetEYE account and
            use the same email ID or phone number to create a new one, with all
            data being permanently erased.{" "}
          </p>
        </div>
        <div className="flex flex-col gap-[1.5vh]">
          <h2 className="text-[clamp(1.2rem,1.2vw,3.5rem)] md:text-[clamp(1.2rem,1.2vw,3.5rem)] xl:text-[clamp(1.5rem,2vw,5.5rem)] font-[Prata] font-[400]">
            You can delete your account by following these instructions:
          </h2>
          <ol className="flex flex-col gap-[1.5vh] text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[400] text-[#646464]">
            <li className="flex flex-row gap-[.5vw]">
              1. In top navigation right side, Click on your profile icon &ensp;
              <Icons name="header-user-deleteacc" />
            </li>
            <li>2. Go to Privacy and Policy in the Pop -up.</li>
            <li>3. Click Delete Account in the list. </li>
            <li>4. Select the account you’d like to permanently delete.</li>
            <li>
              5. Verify your identity by entering the OTP sent to your
              registered email or phone number.
            </li>
          </ol>
          <p className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[400] text-[#646464]">
            Once the OTP is verified, your account deletion request will be
            processed.
          </p>
        </div>
        {!login.login && (
          <div>
            <button
              onClick={handleButtonClick}
              className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[600] bg-black text-white px-[2vw] py-[.5vw] rounded-[.8vw] border-none outline-none"
            >
              Login to delete
            </button>
          </div>
        )}

        {login.login && (
          <div>
            <button
              onClick={handleDeleteAccount}
              className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[600] bg-black text-white px-[2vw] py-[.5vw] rounded-[.8vw] border-none outline-none"
            >
              Delete Account
            </button>
          </div>
        )}

        <div className="flex flex-col gap-[1.5vh]">
          <h2 className="text-[clamp(1.2rem,1.2vw,3.5rem)] md:text-[clamp(1.2rem,1.2vw,3.5rem)] xl:text-[clamp(1.5rem,2vw,5.5rem)] font-[Prata] font-[400]">
            After Deleting Your Account
          </h2>
          <p className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[400] text-[#646464]">
            {" "}
            Once you delete your account, all data associated with it will be
            permanently removed from all platforms, and no data can be
            recovered.
          </p>
          <p className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[400] text-[#646464]">
            However, you can still use the same email ID or phone number to
            create a new account.{" "}
          </p>
        </div>

        <div className="flex flex-col gap-[1.5vh]">
          <h2 className="text-[clamp(1.2rem,1.2vw,3.5rem)] md:text-[clamp(1.2rem,1.2vw,3.5rem)] xl:text-[clamp(1.5rem,2vw,5.5rem)] font-[Prata] font-[400]">
            If you need assistance or have any questions, you can reach us at:{" "}
          </h2>
          <p className="text-[clamp(.8rem,1vw,3.5rem)] font-[Open Sans] font-[400] text-[#646464]">
            {" "}
            support@peteye.pet
          </p>
        </div>
      </div>
      {openDeleteAccount && <DeleteAccount isClose={handleDeleteAccount} />}
    </div>
  );
}
