import { useEffect } from "react";
import React from "react";
import "./ShopGifs.css";

export default function ShopGifs({ onClose, message, gif }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div className="shop-gif-overlay">
      <div className="shop-gif">
        <img
          src={gif}
          alt={message}
          title={message}
          className="shop-gif-image animate-bounce"
        />
        <p className="shop-gif-message animate-fade-in">{message}</p>
      </div>
    </div>
  );
}
