import React, { useState, useEffect } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import Cookies from "js-cookie";
import axios from "../../../axios";

export default function Coupons({ isClose, isOpen }) {
  const loginToken = Cookies.get("loginToken");
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [expandedCoupons, setExpandedCoupons] = useState({});
  const [coupons, setCoupons] = useState([]);

  const fetchCoupons = async () => {
    try {
      const response = await axios.get("/shop/activecoupons", {
        headers: {
          Authorization: loginToken,
        },
      });
      const couponsData = response.data.message || [];
      setCoupons(couponsData);

      // Initialize each coupon's expanded state to false
      const initialExpandedState = {};
      couponsData.forEach((coupon) => {
        initialExpandedState[coupon.id] = false;
      });
      setExpandedCoupons(initialExpandedState);
      console.log("Coupons:", response);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [loginToken]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 300);
  };
  const toggleDescription = (id) => {
    setExpandedCoupons((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  return (
    <div
      className={`fixed inset-0 modal-overlay z-[9999]
  ${isModalClosing ? "opacity-0 fade-out" : "opacity-100"}`}
      onClick={handleOverlayClick}
    >
      <div
        className={`modal-container
    ${isModalClosing ? "scale-95 opacity-0 close" : "scale-100 opacity-100"}`}
      >
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"shop-wishlistbutton-nonactiveicon"} />{" "}
              coupons
            </p>
          </div>

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="flex items-center justify-center p-[1vw] ">
          <div className="relative bg-white">
            <input
              type="text"
              placeholder="Enter coupon code"
              className=" w-[30vw] h-[7vh] border-none bg-transparent rounded-[1vw] py-[.5vw] px-[1vw] focus:outline-none  shadow-[0px_.3vw_1.3vw_0px_rgba(0,0,0,0.15)] text-[clamp(1rem,1.2vw,3.5rem)] font-[Manrope] text-[#9A9A9A]"
            />
            <button className="absolute top-1/2 right-3  transform -translate-y-1/2 px-[1.5vw] py-[.2vw] h-[4vh] bg-black text-white rounded-[.75vw]  text-[clamp(.8rem,.8vw,2.5rem)] font-[Manrope] text-center border-none outline-none">
              Apply
            </button>
          </div>
        </div>
        <div className="flex  flex-col items-center gap-[3vh]  p-[1vw] h-[54vh] overflow-auto ">
          {coupons.map((coupon) => (
            <div className=" w-[30vw] h-full rounded-[1vw]  flex items-center  shadow-[0px_.3vw_1.3vw_0px_rgba(0,0,0,0.15)]">
              <div className="bg-[#A9AAAF] rounded-tl-[1vw] rounded-bl-[1vw] text-white  h-full flex items-center justify-center">
                <h2 className="-rotate-[90deg] w-[5vw] font-bold text-[clamp(.9rem,1vw,3.5rem)]">
                  FLAT OFF
                </h2>
              </div>
              <div className="px-[1vw] py-[1.5vw] flex flex-col gap-[.5vw] w-full">
                <div className="flex items-center justify-between">
                  <h1 className="text-[clamp(1.2rem,1.5vw,4rem)] font-[Open Sans] font-bold">
                    {coupon.couponCode}
                  </h1>
                  <button className=" px-[1.5vw] py-[.2vw] h-[4vh] bg-black text-white rounded-[.75vw]  text-[clamp(.8rem,.8vw,2.5rem)] font-[Manrope] text-center border-none outline-none">
                    Apply
                  </button>
                </div>
                <h3 className="text-[clamp(1rem,1.2vw,3.5rem)] font-[Open Sans] pb-[.5vw] border-b-[1px] border-[#AFAFAF] ">
                  {coupon.couponName}
                </h3>
                <p className="text-[#8D8D8D] text-[clamp(1rem,1.2vw,3.5rem)] font-[Open Sans] ">
                  Use code {coupon.couponCode} & get {coupon.discountValue}% off
                  on orders above Rs.{coupon.minPurchaseAmount}
                </p>
                <div
                  className={`transition-all duration-300 ease-in-out overflow-hidden ${
                    expandedCoupons[coupon.id]
                      ? "h-auto scale-y-100"
                      : "h-0 scale-y-0"
                  }`}
                  style={{ transformOrigin: "top" }}
                >
                  <p className="text-[clamp(1rem,1.2vw,3.5rem)] font-[Open Sans]">
                    {coupon.description}
                  </p>
                </div>
                <div
                  onClick={() => toggleDescription(coupon.id)}
                  className="text-[clamp(1rem,1.2vw,3.5rem)] font-[Open Sans] font-[550]"
                >
                  {expandedCoupons[coupon.id] ? "Show less -" : "Show more +"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
