import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import Success from "../../Success/Success";
import axios from "../../../../axios";
import Cookies from "js-cookie";

export const ChangePassword = ({
  isOpen,
  isClose,
  setOpenEnterOTP,
  setOpenChangePasswordSetting,
  userData,
}) => {
  const loginToken = Cookies.get("loginToken");

  const [success, setSuccess] = useState(false);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState("");
  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    specialChar: false,
    number: false,
  });
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [startedTyping, setStartedTyping] = useState(false);

  useEffect(() => {
    const { newPassword } = passwords;

    setPasswordRules({
      minLength: newPassword.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
      number: /\d/.test(newPassword),
    });
  }, [passwords.newPassword]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
    setStartedTyping(true);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  const handlePasswordChange = async () => {
    const { newPassword, confirmNewPassword } = passwords;
    const allRulesPassed = Object.values(passwordRules).every(Boolean);

    if (!allRulesPassed) {
      setError("Please follow all password rules");
      setTimeout(() => setError(""), 3000); // Clear error after 3 seconds
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      setTimeout(() => setError(""), 3000); // Clear error after 3 seconds
      return;
    }

    try {
      const response = await axios.post(
        "/auth/changepassword",
        {
          Email: userData.email,
          password: newPassword,
          confirmpassword: confirmNewPassword,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      // console.log("email", userData.email);

      if (response.data.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setOpenChangePasswordSetting(false);
          setOpenEnterOTP(false);
        }, 3000);
      } else {
        setError(response.data.message || "Failed to change password");
        setTimeout(() => setError(""), 3000); // Clear error after 3 seconds
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setError("Failed to change password");
      setTimeout(() => setError(""), 3000); // Clear error after 3 seconds
    }
  };

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };

  return (
    <>
      <div
        className={`modal-overlay2 ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container2 ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>Change Password</p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            {success ? (
              <Success
                message={"Password Changed"}
                gif={
                  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                }
                onClose={handlePasswordChange}
              />
            ) : (
              <div className="change-password">
                <div className="get-code-about">
                  <p>
                    Enter your new password below to update your account
                    security.
                  </p>
                </div>
                <div className="modal-input-group">
                  <input
                    type="password"
                    name="newPassword"
                    placeholder="Enter New Password"
                    value={passwords.newPassword}
                    onChange={handleChange}
                    className={
                      startedTyping &&
                      (!passwordRules.minLength ||
                        !passwordRules.specialChar ||
                        !passwordRules.number)
                        ? "input-invalid"
                        : ""
                    }
                  />
                  <input
                    type="password"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    value={passwords.confirmNewPassword}
                    onChange={handleChange}
                    className={
                      startedTyping &&
                      passwords.newPassword !== passwords.confirmNewPassword
                        ? "input-invalid"
                        : ""
                    }
                  />
                  <div className="password-rules">
                    <span
                      className={
                        startedTyping && !passwordRules.minLength
                          ? "rule-invalid"
                          : startedTyping
                          ? "rule-valid"
                          : ""
                      }
                    >
                      <DogPettingIcon name={"gray-tick"} /> 8 Characters Minimum
                    </span>
                    <span
                      className={
                        startedTyping && !passwordRules.specialChar
                          ? "rule-invalid"
                          : startedTyping
                          ? "rule-valid"
                          : ""
                      }
                    >
                      <DogPettingIcon name={"gray-tick"} /> 1 Special Character
                    </span>
                    <span
                      className={
                        startedTyping && !passwordRules.number
                          ? "rule-invalid"
                          : startedTyping
                          ? "rule-valid"
                          : ""
                      }
                    >
                      <DogPettingIcon name={"gray-tick"} /> 1 number
                    </span>
                  </div>
                </div>
                {error && (
                  <div className="error-invalid">
                    <p>{error}</p>
                  </div>
                )}
                <div className="modal-control">
                  <button onClick={handlePasswordChange}>
                    <DogPettingIcon name={"accept"} />
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
