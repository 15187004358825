import React, { useEffect, useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon"; // Assuming this is how you import the icon
import "./ProductActions.css";
import axios from "../../../axios";
import Prescription from "./Prescription";
import Checkout from "./Checkout/Checkout";
import Coupons from "./Coupons";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useShop } from "../../components/ShopContext";
import Failedgif from "../../../Pages/Failed/Failedgif";

export default function CartItems() {
  const {
    cartData,
    cartItemIds,
    totalDiscount,
    handleQuantityCartChange,
    calculateCartTotal,
    fetchCartItems,
    calculateTotalDiscount,
    groupItemsByCategory,
  } = useShop();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isClosing1, setIsClosing1] = useState(false);
  const [prescriptionOpen, setPrescriptionOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [openCoupons, setOpenCoupons] = useState(false);

  const dispatch = useDispatch();
  const loginToken = Cookies.get("loginToken");
  console.log(cartData, "cartDataasss");

  useEffect(() => {
    fetchCartItems();
  }, []);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsClosing(true);
      handleUploadModalClose();
    }
  };

  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      const url = response.data.Message ? response.data.Message.getPeteye : "";
      console.log("File uploaded successfully:", url);
      return url;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      try {
        const file = selectedFiles[0];
        const url = await uploadFileToS3(file);
        if (url) {
          const response = await axios.post(
            "/petinfo/addpetdocuments",
            {
              documentList: [
                {
                  docType: "AdoptionPaperFile",
                  fileType: "pdf",
                  url: url,
                  fileName: file.name,
                },
              ],
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          );
          console.log(" addpetdocuments added:", response);
          setUploadStatus("success");
          setUploadedFile(selectedFiles[0]);
          dispatch({ type: "refresh" });
        } else {
          setUploadStatus("failed");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setUploadStatus("failed");
      }
    }
    setTimeout(() => {
      setUploadStatus("");
      setIsUploadModalOpen(false);
    }, 3000);
  };
  const categories = Object.keys(cartData).filter(
    (category) => cartData[category]?.length > 0
  );

  // Check if cart is empty (no categories with items)
  const isCartEmpty = categories.length === 0;

  if (isCartEmpty) {
    return <Failedgif message="No items in the cart" />;
  }

  const handleUploadModalClose = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setIsUploadModalOpen(false);
      setUploadStatus("");
      setSelectedFiles([]);
    }, 1000);
  };

  const handlePrescription = () => {
    setPrescriptionOpen((prev) => !prev);
  };

  const handleCheckout = () => {
    setCheckoutOpen((prev) => !prev);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };
  const handleCoupon = () => {
    setOpenCoupons((prev) => !prev);
  };
  return (
    <>
      <div>
        <div className="flex flex-col gap-[1vw] p-[1vw] mt-[.3vw]">
          <div className="shop-cart-items">
            {categories.map((category) => (
              <div key={category} className="shop-cart-item-category">
                <h1 className="category-title">{category}</h1>
                {cartData[category].map((item) => (
                  <div className="shop-cart-item" key={item.id}>
                    <div className="shop-cart-item-image">
                      <img
                        src={item.Products.productImages[0]?.url}
                        alt={item.Products.ProductName}
                      />
                    </div>
                    <div className="shop-cartitemdetails">
                      <div>
                        <h3>{item.Products.ProductName}</h3>
                        <p>{item.Products.Description}</p>
                      </div>
                      <div className="shop-cart-item-price-quantity">
                        <p className="shop-cart-item-weight">
                          {item.productvariant.unit}
                        </p>
                      </div>
                    </div>
                    <div className="shop-cart-item-details">
                      <div className="shop-cart-item-quantity">
                        <button
                          className="quantity-btn"
                          onClick={() => handleQuantityCartChange(item.id, -1)}
                        >
                          -
                        </button>
                        <span className="quantity-display">
                          {item.quantity}
                        </span>
                        <button
                          className="quantity-btn"
                          onClick={() => handleQuantityCartChange(item.id, 1)}
                        >
                          +
                        </button>
                      </div>
                      <div className="shop-cart-item-price">
                        <h2>₹</h2>
                        <h6>
                          {(
                            (item.productvariant.price /
                              (1 -
                                (item.productvariant.OfferPercentage || 0) /
                                  100)) *
                            item.quantity
                          ).toFixed(0)}
                        </h6>
                        <p>
                          {parseFloat(
                            item.productvariant.price * item.quantity
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/* <div
            className="bg-[#f5f5f5] p-[1vw] rounded-[1vw] flex items-center justify-between cursor-pointer "
            onClick={handleCoupon}
          >
            <p className="text-[clamp(1rem,1.2vw,3.5rem)] font-[Open Sans]">
              Apply Coupon
            </p>
            <p>
              <MdOutlineKeyboardArrowRight className="text-[clamp(1rem,1.2vw,3.5rem)]" />
            </p>
          </div> */}
        </div>
      </div>

      {prescriptionOpen && (
        <Prescription
          isClose={handlePrescription}
          isOpen={handlePrescription}
        />
      )}
      {checkoutOpen && (
        <Checkout isClose={handleCheckout} isOpen={handleCheckout} />
      )}
      {openCoupons && <Coupons isClose={handleCoupon} isOpen={handleCoupon} />}
    </>
  );
}
