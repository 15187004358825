// Records.js
import { useState } from "react";
import React from "react";
import "./Records.css";
import MedicalRecords from "./MedicalRecords/MedicalRecords";
import BillofSale from "./BillofSale/BillofSale";
import IdentificationMarks from "./IdentificationMarks/IdentificationMarks";
import AdoptionPaper from "./AdoptionPaper/AdoptionPaper";
import PetDocInfo from "./PetDocInfo/PetDocInfo";
import PetImage from "./PetImage/PetImage";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";

const Records = ({ petId }) => {
  const recordsData = [
    {
      id: 1,
      img: <DogPettingIcon name={"Medicalrecords"} />,
      data: "Medical Records",
    },
    {
      id: 2,
      img: <DogPettingIcon name={"bill-off-sale"} />,
      data: "Bill of Sale",
    },
    {
      id: 3,
      img: <DogPettingIcon name="identificationmarks" />,
      data: "Identification Marks",
    },
    {
      id: 4,
      img: <DogPettingIcon name="Adoptionpaper" />,
      data: "Adoption Paper",
    },
    {
      id: 5,
      img: <DogPettingIcon name="petdoc-info" />,
      data: "Pet Doctor Information",
    },
    {
      id: 6,
      img: <DogPettingIcon name="camera" />,
      data: "PetImage",
    },
  ];

  const [isMedicalOpen, setIsMedicalOpen] = useState(false);
  const [isBillOpen, setIsBillOpen] = useState(false);
  const [isIdentificationMarksOpen, setIsIdentificationMarksOpen] =
    useState(false);
  const [isAdoptionOpen, setIsAdoptionOpen] = useState(false);
  const [isPetDocInfoOpen, setIsPetDocInfoOpen] = useState(false);
  const [isRecordPetImageOpen, setIsRecordPetImageOpen] = useState(false);

  const handleOpenMedical = () => {
    setIsMedicalOpen(true);
  };
  const handleCloseMedical = () => {
    setIsMedicalOpen(false);
  };
  const handleOpenBill = () => {
    setIsBillOpen(true);
  };
  const handleCloseBill = () => {
    setIsBillOpen(false);
  };
  const handleOpenIdentificationMark = () => {
    setIsIdentificationMarksOpen(true);
  };
  const handleCloseIdentificationMark = () => {
    setIsIdentificationMarksOpen(false);
  };
  const handleOpenAdoption = () => {
    setIsAdoptionOpen(true);
  };
  const handlecloseAdoption = () => {
    setIsAdoptionOpen(false);
  };
  const handleOpenPetDocInfo = () => {
    setIsPetDocInfoOpen(true);
  };
  const handleClosePetDocInfo = () => {
    setIsPetDocInfoOpen(false);
  };
  const handleOpenRecordPetImage = () => {
    setIsRecordPetImageOpen(true);
  };
  const handleCloseRecordPetImage = () => {
    setIsRecordPetImageOpen(false);
  };

  return (
    <div className="record-grid-container">
      {recordsData.map((item, index) => (
        <div
          key={item.id}
          className="record-grid-item"
          onClick={
            index === 0
              ? handleOpenMedical
              : index === 1
              ? handleOpenBill
              : index === 2
              ? handleOpenIdentificationMark
              : index === 3
              ? handleOpenAdoption
              : index === 4
              ? handleOpenPetDocInfo
              : index === 5
              ? handleOpenRecordPetImage
              : null
          }
        >
          <div className="record-svgicon">{item.img}</div>
          <h3>{item.data}</h3>
        </div>
      ))}
      <MedicalRecords
        isOpen={isMedicalOpen}
        onClose={handleCloseMedical}
        petId={petId}
      />
      <BillofSale isOpen={isBillOpen} onClose={handleCloseBill} petId={petId} />
      <IdentificationMarks
        isOpen={isIdentificationMarksOpen}
        onClose={handleCloseIdentificationMark}
        petId={petId}
      />
      <AdoptionPaper
        isOpen={isAdoptionOpen}
        onClose={handlecloseAdoption}
        petId={petId}
      />
      <PetDocInfo
        isOpen={isPetDocInfoOpen}
        onClose={handleClosePetDocInfo}
        petId={petId}
      />
      <PetImage
        isOpen={isRecordPetImageOpen}
        onClose={handleCloseRecordPetImage}
        petId={petId}
      />
    </div>
  );
};

export default Records;
