import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import "./Faqs.css";
import Cookies from "js-cookie";

export default function Faqs({ isOpen, isClose }) {
  const loginToken = Cookies.get("loginToken");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [openAnswers, setOpenAnswers] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get("/user/allfaq", {
        headers: {
          Authorization: loginToken,
        },
      });
      console.log("faq", response.data.message);
      if (response.data.status === 200) {
        setSearchResults(response.data.message);
        setOriginalData(response.data.message);
      } else {
        setSearchResults([]);
        setOriginalData([]);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setSearchResults([]);
      setOriginalData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    searchData(query);
  };

  const searchData = (txt) => {
    if (txt) {
      const filteredData = originalData.filter((item) => {
        const question = item?.Question || "";
        return question.toLowerCase().includes(txt.toLowerCase());
      });
      setSearchResults(filteredData);
    } else {
      setSearchResults(originalData);
    }
  };

  const toggleAnswer = (index) => {
    setOpenAnswers((prev) => {
      const newOpenAnswers = [...prev];
      newOpenAnswers[index] = !newOpenAnswers[index];
      return newOpenAnswers;
    });
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className={isOpen ? "modal-container" : "modal-container close"}>
        <div className="faqs-picker">
          <div className="faqs-picker-title">
            <DogPettingIcon name="faqicon" />
            <h2>FAQs</h2>
            <div className="modal-close" onClick={isClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="support-main-sec">
            <div className="support-main-sec-search-box" id="supportsection">
              <div className="search-box-container">
                <DogPettingIcon name="faq-searchbar" />
                <input
                  type="text"
                  placeholder="Have a Question?"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="search-result-main-div">
              {searchResults.length > 0 &&
                searchResults.map((item, index) => (
                  <div key={index} className="res-question-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p onClick={() => toggleAnswer(index)}>{item.Question}</p>
                      <IoMdArrowDropdown
                        onClick={() => toggleAnswer(index)}
                        style={{
                          transform: openAnswers[index]
                            ? "rotate(180deg)"
                            : "rotate(0deg",
                          cursor: "pointer",
                          fontSize: "clamp(0.9rem, 1vw, 4rem) ",
                        }}
                      />
                    </div>
                    <div
                      style={{ display: openAnswers[index] ? "block" : "none" }}
                    >
                      <div className="faq-line"></div>
                      <p>{item.Answer}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
