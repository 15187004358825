import React from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { BsCurrencyRupee } from "react-icons/bs";
import './Home.css'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const products=[
  {img:'/Images/dogfood.png',name:' Pedigree-Adult',rating:'4', price:'500',orgPrice:'750'},
  {img:'/Images/catfood.png',name:' Pedigree-Adult',rating:'4', price:'400',orgPrice:'750'},
  {img:'/Images/petmed.png',name:' Pedigree-Adult',rating:'4', price:'500',orgPrice:'750'},
  {img:'/Images/dogfood.png',name:' Pedigree-Adult',rating:'4', price:'500',orgPrice:'750'},

 
  ]

  const sub_banner=[
    {img:'/Images/subbanner1.png',name:' Treet & Toys', description:'The Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},
    {img:'/Images/subbanner2.png',name:' Accessories', description:'The Lorem Ipsum is simply dummy text of the printing and typesetting industry.'},
    ]


function Home() {
  return (
    <div className='store-home'>
      <div className='store-home-sub1'>
        <div className='main-banner'>
          {/* <div className='banner-sub1'>
            <h5>Dog Foods</h5>
            <div style={{ width:'100%', display:'flex', justifyContent:'center' }} >

            <img src="/Images/Offimg.png" alt="" />
            </div>
            <p>The Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            <button>View All <MdKeyboardDoubleArrowRight  /></button>
          </div>
          <div className='banner-sub2'>
            <img src="/Images/bannerImg.png" alt="" />
          </div> */}
        </div>

        <div className='home-category-card'>
        {
            sub_banner.map((item)=>{
              return(
                <div>
                  <div className='home-cate'>
                    
                  {/* <div className='home-cate-sub1'>
                  <img  src={item.img} alt="" />
                  </div>
                  <div className='home-cate-sub2'>
                  <span>{item.name}</span>
                  <p>{item.description}</p>
              <div className='viewAll'>
                <button>View All <MdKeyboardDoubleArrowRight  /></button>
              </div>
                  </div> */}
                
                </div>
                </div>
              )
            })
          }
        </div>

      </div>
      <div className='store-home-sub2'>
<div className='home-pdt-card'>
          {
            products.map((item)=>{
              return(
                <div>
                  {/* <img  src={item.img} alt="" />
                  <span>{item.name}</span>
                  <Stack style={{ marginBottom:'7px',marginTop:'-15px' }} spacing={1}>
                  <Rating name="size-small" readOnly defaultValue={item.rating} size="small" />
              </Stack>
              
              <div className='addtocart'>
                <p><BsCurrencyRupee /> {item.price} &emsp;<del>{item.orgPrice}</del></p>
                <button>Add cart</button>
              </div> */}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Home
