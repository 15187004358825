import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import '../Login/Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function NewPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setshowConPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickshowConPassword= () => setshowConPassword(!showConPassword);

  return (
    <div>
      <form>
        <div className='login-form'>
          <h3 style={{ textAlign: 'center', marginTop: '5em', fontWeight: '500' }}>
            Create new password
          </h3>
          <TextField
          className='textfield-size'
            id='password'
            label='Password'
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                 <>
                  {!showPassword ? 
                  <FaEye onClick={handleClickShowPassword} style={{cursor:'pointer'}}>
                   <Visibility />
                  </FaEye>    :
              <FaEyeSlash onClick={handleClickShowPassword} style={{cursor:'pointer'}}>
                <VisibilityOff />
              </FaEyeSlash> 
                 }
                 </>
              ),
            }}
          />
          <TextField
          className='textfield-size'
            id='confirmPassword'
            label='Confirm password'
            variant='outlined'
            type={showConPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <>
                {setshowConPassword ? 
                <FaEye onClick={handleClickshowConPassword} style={{cursor:'pointer'}}>
                 <Visibility />
                </FaEye>    :
            <FaEyeSlash onClick={handleClickshowConPassword} style={{cursor:'pointer'}}>
              <VisibilityOff />
            </FaEyeSlash> 
               }
               </>
              ),
            }}
          />
          <button>Login</button>
        </div>
      </form>
    </div>
  );
}

export default NewPassword;
